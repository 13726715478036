import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["feedbackForm", "response", "submitButton"]
    static values = {
        articleId: String,
        categoryId: String,
        knowledgeBaseId: String
    }

    connect() {
        console.log("Article Feedback controller connected")
    }

    submitFeedback(event) {
        event.preventDefault()
        this.submitButtonTarget.disabled = true

        const formData = new FormData(this.feedbackFormTarget)
        const url = this.feedbackFormTarget.action

        fetch(url, {
            method: 'POST',
            body: formData,
            credentials: 'same-origin',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.showSuccess(data.message)
                    this.updateFeedbackCounts(data.helpful_count, data.not_helpful_count)
                } else {
                    this.showError(data.message)
                }
            })
            .catch(error => {
                console.error('Error:', error)
                this.showError("There was an error submitting your feedback")
            })
            .finally(() => {
                this.submitButtonTarget.disabled = false
            })
    }

    showSuccess(message) {
        this.responseTarget.innerHTML = `
      <div class="alert alert-success alert-dismissible fade show" role="alert">
        ${message}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `
    }

    showError(message) {
        this.responseTarget.innerHTML = `
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        ${message}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `
    }

    updateFeedbackCounts(helpfulCount, notHelpfulCount) {
        document.querySelector('.helpful-count').textContent = helpfulCount
        document.querySelector('.not-helpful-count').textContent = notHelpfulCount
    }
}