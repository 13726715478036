import React from 'react';

const SuggestedActions = ({ actions, onActionClick }) => {
    if (!actions || actions.length === 0) return null;

    const getButtonStyle = (style) => {
        return `action-button ${style || 'primary'}`;
    };

    return (
        <div className="suggested-actions">
            {actions.map((action, index) => (
                <button
                    key={`${action.action}-${index}`}
                    onClick={() => onActionClick(action)}
                    className={getButtonStyle(action.style)}
                    disabled={action.disabled}
                    title={action.description}
                >
                    {action.icon && <span className="action-icon">{action.icon}</span>}
                    <span className="action-label">{action.label}</span>
                </button>
            ))}
        </div>
    );
};

export default SuggestedActions;