// Game2048.jsx
import React from 'react';
import Board from './Board';
import './styles/game2048.scss';

const Game2048 = ({ userId, gameId, companyId }) => {
    return (
        <div className="game-2048">
            <h1>2048</h1>
            <Board userId={userId} gameId={gameId} companyId={companyId} />
        </div>
    );
};

export default Game2048;