export const saveScore = async ({ score, userId, gameId, companyId }) => {
    console.log('Sending score:', { score, userId, gameId, companyId }); // Log the data being sent

    const response = await fetch('/scores', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ score, user_id: userId, game_id: gameId, company_id: companyId }),
    });

    if (!response.ok) {
        console.error('Error saving score:', response.statusText); // Log error if saving fails
        throw new Error('Error saving score');
    }

    const result = await response.json();
    console.log('Score saved successfully:', result); // Log success response
    return result;
};

export const getHighScore = async (userId, gameId, companyId) => {
    console.log('Fetching high score for:', { userId, gameId, companyId }); // Log the params being sent

    const response = await fetch(`/scores/highscore?user_id=${userId}&game_id=${gameId}&company_id=${companyId}`);

    if (!response.ok) {
        console.error('Error fetching high score:', response.statusText); // Log error if fetching fails
        throw new Error('Error fetching high score');
    }

    const highScore = await response.json();
    console.log('High score fetched successfully:', highScore); // Log the fetched high score
    return highScore;
};
