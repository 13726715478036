import React, { useState, useEffect, useRef } from "react";
import Tile from "./Tile";
import Cell from "./Cell";
import { Board } from "./helper";
import useEvent from "../../../hooks/useEvent";
import GameOverlay from "./GameOverlay";
import ScoreBoard from "../../common/ScoreBoard/ScoreBoard";
import { useScoreData } from "../../../hooks/useScoreData";

const BoardView = ({ userId, gameId, companyId }) => {
    const [board, setBoard] = useState(new Board());
    const [gameTime, setGameTime] = useState(0);
    const timerRef = useRef(null); // Use a ref to track the timer interval
    const {
        score,
        highScore,
        incrementScore,
        resetScore,
        saveCurrentScore,
        fetchHighScore
    } = useScoreData();

    useEffect(() => {
        console.log("User ID:", userId); // Log the userId
        console.log("Game ID:", gameId); // Log the gameId
        console.log("Company ID:", companyId); // Log the companyId

        if (userId && gameId && companyId) {
            fetchHighScore(userId, gameId, companyId); // Fetch high score if all values are defined
        }

        const timer = setInterval(() => {
            setGameTime((prevTime) => prevTime + 1);
        }, 1000);

        return () => clearInterval(timer); // Clean up the interval when the component unmounts
    }, [userId, gameId, companyId]);

    const handleKeyDown = (event) => {
        if (board.hasWon()) {
            return;
        }

        if (event.keyCode >= 37 && event.keyCode <= 40) {
            let direction = event.keyCode - 37;
            let boardClone = Object.assign(
                Object.create(Object.getPrototypeOf(board)),
                board
            );
            let newBoard = boardClone.move(direction);
            setBoard(newBoard);

            if (newBoard.score !== board.score) {
                incrementScore(newBoard.score - board.score);
            }
        }
    };

    useEvent("keydown", handleKeyDown);


    const cells = board.cells.map((row, rowIndex) => {
        return (
            <div key={rowIndex}>
                {row.map((col, colIndex) => {
                    return <Cell key={rowIndex * board.size + colIndex} />;
                })}
            </div>
        );
    });

    const tiles = board.tiles
        .filter((tile) => tile.value !== 0)
        .map((tile, index) => {
            return <Tile tile={tile} key={index} />;
        });

    const resetGame = () => {
        setBoard(new Board());
        resetScore();
        setGameTime(0);

        // Restart the timer when a new game starts
        clearInterval(timerRef.current); // Clear previous timer
        timerRef.current = setInterval(() => {
            setGameTime((prevTime) => prevTime + 1);
        }, 1000);
    };

    // Game over function
    const gameOver = async () => {
        console.log("Game over. Saving score:", score); // Log the score
        clearInterval(timerRef.current); // Stop the timer when the game is over
        await saveCurrentScore(userId, gameId, companyId);
        await fetchHighScore(userId, gameId, companyId);
    };

    // Check if the game is lost and call gameOver
    useEffect(() => {
        if (board.hasLost()) {
            gameOver();
        }
    }, [board]);

    return (
        <div>
            <div className="details-box">
                <div className="resetButton" onClick={resetGame}>
                    New Game
                </div>
                <ScoreBoard
                    userId={userId}
                    gameId={gameId}
                    companyId={companyId}
                    score={score}
                    highScore={highScore}
                    time={gameTime}
                />
            </div>
            <div className="board">
                {cells}
                {tiles}
                <GameOverlay onRestart={resetGame} board={board} />
            </div>
        </div>
    );
};

export default BoardView;
