export const menuActions = {
    LEAVE_MENU: {
        message: "İzin işlemleri için aşağıdaki seçeneklerden birini seçebilirsiniz:",
        actions: [
            {
                label: "İzin Bakiyemi Göster",
                action: "show_leave_balance",
                style: "secondary"
            },
            {
                label: "İzin Talep Et",
                action: "create_leave_request",
                style: "secondary"
            },
            {
                label: "İzin Durumumu Sorgula",
                action: "check_leave_status",
                style: "secondary"
            },
            {
                label: "Bekleyen İzin Taleplerim",
                action: "pending_leave_requests",
                style: "secondary"
            }
        ]
    },
    SHIFT_MENU: {
        message: "Vardiya işlemleri için aşağıdaki seçeneklerden birini seçebilirsiniz:",
        actions: [
            {
                label: "Bugünkü Vardiyam",
                action: "show_today_shift",
                style: "secondary"
            },
            {
                label: "Haftalık Vardiyalarım",
                action: "show_weekly_shifts",
                style: "secondary"
            },
            {
                label: "Vardiya Değişikliği Talep Et",
                action: "request_shift_change",
                style: "secondary"
            }
        ]
    },
    HELP_MENU: {
        message: "Size nasıl yardımcı olabilirim?",
        actions: [
            {
                label: "İzin İşlemleri Hakkında",
                action: "help_leave",
                style: "secondary"
            },
            {
                label: "Vardiya İşlemleri Hakkında",
                action: "help_shift",
                style: "secondary"
            },
            {
                label: "Sık Sorulan Sorular",
                action: "show_faq",
                style: "secondary"
            }
        ]
    },
    MAIN_MENU: {
        message: "Ana menü seçenekleri:",
        actions: [
            {
                label: "İzin İşlemleri",
                action: "show_leave_menu",
                style: "primary"
            },
            {
                label: "Vardiya İşlemleri",
                action: "show_shift_menu",
                style: "primary"
            },
            {
                label: "Yardım",
                action: "show_help",
                style: "secondary"
            }
        ]
    }
};