// src/hooks/useUserPreferences.js
import { useState, useEffect } from 'react';
import { getUserPreferences, updateUserPreferences } from '../services/userPreferencesService';

export const useUserPreferences = () => {
    const [preferences, setPreferences] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchPreferences();
    }, []);

    const fetchPreferences = async () => {
        try {
            setLoading(true);
            const data = await getUserPreferences();
            setPreferences(data.preferences);
            setError(null);
        } catch (err) {
            setError('Failed to load user preferences');
        } finally {
            setLoading(false);
        }
    };

    const updatePreferences = async (newPreferences) => {
        try {
            setLoading(true);
            const updatedData = await updateUserPreferences(newPreferences);
            setPreferences(updatedData.preferences);
            setError(null);
        } catch (err) {
            setError('Failed to update user preferences');
        } finally {
            setLoading(false);
        }
    };

    return { preferences, loading, error, updatePreferences };
};