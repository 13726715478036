// KnowledgeArticleDetail.jsx
import React from 'react';
import styled from 'styled-components';
import { useArticle } from '../../hooks/useKnowledgeBase';
import ArticleFeedback from './ArticleFeedback';

// Styled Components
const Article = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background: #f7fafc;
  border-radius: 4px;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-right: 1rem;

  &:hover {
    background: #edf2f7;
    color: #2d3748;
  }
`;

const BreadcrumbPath = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #718096;
  font-size: 0.875rem;
  flex-wrap: wrap;
`;

const BreadcrumbItem = styled.span`
  white-space: nowrap;
  color: ${props => props.current ? '#2d3748' : props.active ? '#4a5568' : '#718096'};
  font-weight: ${props => props.current ? '500' : 'normal'};
`;

const Separator = styled.span`
  color: #cbd5e0;
`;

const Header = styled.header`
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #1a202c;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  font-size: 0.875rem;
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  font-size: 1.125rem;
  line-height: 1.7;
  color: #2d3748;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 1.5rem 0;
  }
`;

const Attachments = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
`;

const AttachmentsTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #2d3748;
`;

const AttachmentsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const AttachmentItem = styled.li`
  margin-bottom: 0.75rem;
`;

const AttachmentLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background: #f7fafc;
  border-radius: 4px;
  color: #2d3748;
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
    background: #edf2f7;
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const LoadingSpinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorContainer = styled(LoadingContainer)`
  color: #e53e3e;
`;

const KnowledgeArticleDetail = ({ knowledgeBaseId, categoryId, articleId }) => {
    const { article, loading, error } = useArticle(knowledgeBaseId, categoryId, articleId);
    const breadcrumbData = JSON.parse(sessionStorage.getItem('breadcrumb') || '{}');

    const handleBack = () => {
        window.history.back();
    };

    if (loading) {
        return (
            <LoadingContainer>
                <LoadingSpinner />
                <p>Loading article...</p>
            </LoadingContainer>
        );
    }

    if (error) {
        return (
            <ErrorContainer>
                <h2>Error Loading Article</h2>
                <p>{error}</p>
                <button onClick={() => window.location.reload()}>Try Again</button>
            </ErrorContainer>
        );
    }

    if (!article) return <ErrorContainer>Article not found</ErrorContainer>;

    return (
        <Article>
            <Navigation>
                <BackButton onClick={handleBack}>
                    <i className="fas fa-arrow-left"></i>
                </BackButton>
                <BreadcrumbPath>
                    <BreadcrumbItem>Home</BreadcrumbItem>
                    {breadcrumbData.path?.map((title, index) => (
                        <React.Fragment key={index}>
                            <Separator>/</Separator>
                            <BreadcrumbItem active={index === breadcrumbData.path.length - 1}>
                                {title}
                            </BreadcrumbItem>
                        </React.Fragment>
                    ))}
                    <Separator>/</Separator>
                    <BreadcrumbItem current>{article.title}</BreadcrumbItem>
                </BreadcrumbPath>
            </Navigation>

            <Header>
                <Title>{article.title}</Title>
                <Meta>
                    {article.reading_time && (
                        <>
                            <MetaItem>
                                {article.reading_time} min read
                            </MetaItem>
                            <Separator>•</Separator>
                        </>
                    )}

                    {article.updated_at && (
                        <>
                            <MetaItem>
                                last update: {new Date(article.updated_at).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}
                            </MetaItem>
                            <Separator>•</Separator>
                        </>
                    )}

                    {typeof article.views_count === 'number' && (
                        <MetaItem>
                            {article.views_count.toLocaleString()} views
                        </MetaItem>
                    )}
                </Meta>
            </Header>

            <Content dangerouslySetInnerHTML={{__html: article.content}} />

            {article.attachments?.length > 0 && (
                <Attachments>
                    <AttachmentsTitle>Attachments</AttachmentsTitle>
                    <AttachmentsList>
                        {article.attachments.map(attachment => (
                            <AttachmentItem key={attachment.id}>
                                <AttachmentLink
                                    href={attachment.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fas fa-paperclip"></i>
                                    <span>{attachment.filename}</span>
                                </AttachmentLink>
                            </AttachmentItem>
                        ))}
                    </AttachmentsList>
                </Attachments>
            )}

            <ArticleFeedback articleId={article.id} />
        </Article>
    );
};

export default KnowledgeArticleDetail;