import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "leaveType", "balanceDisplay" ]

    connect() {
        this.updateBalance()
    }

    leaveTypeChanged() {
        this.updateBalance()
    }

    updateBalance() {
        const leaveTypeSelect = this.leaveTypeTarget
        const leaveTypeId = leaveTypeSelect.value
        const selectedOption = leaveTypeSelect.options[leaveTypeSelect.selectedIndex]
        const balanceCheck = selectedOption && selectedOption.dataset.balanceCheck === 'true'

        const isOnlyDisplayMyLeaves = this.element.dataset.isOnlyDisplayMyLeaves === 'true'

        if (leaveTypeId) {
            fetch(`/leaves/available_balance?leave_type_id=${leaveTypeId}&is_only_display_my_leaves=${isOnlyDisplayMyLeaves}`)
                .then(response => response.json())
                .then(data => {
                    let balanceHtml = `
                    <div class="alert alert-info" role="alert">
                        <h4 class="alert-heading">İzin Bilgileri</h4>
                        <div class="d-flex justify-content-between">
                            <p><strong>İzin Türü:</strong> ${selectedOption.text}</p>
                        </div>
                    `;

                    if (balanceCheck) {
                        balanceHtml += `
                        <div class="d-flex justify-content-between">
                            <p><strong>Kullanılabilir İzin Bakiyesi:</strong> ${data.available_balance}</p>
                            <p><strong>Minimum Süre:</strong> ${data.min_duration}</p>
                            <p><strong>Maksimum Süre:</strong> ${data.max_duration}</p>
                        </div>
                        `;
                    } else {
                        balanceHtml += `
                        <p>Bu izin türü için bakiye kontrolü yapılmamaktadır.</p>
                        `;
                    }

                    balanceHtml += `</div>`;
                    this.balanceDisplayTarget.innerHTML = balanceHtml
                    this.balanceDisplayTarget.style.display = 'block'
                })
        } else {
            this.balanceDisplayTarget.innerHTML = ''
            this.balanceDisplayTarget.style.display = 'none'
        }
    }
}