// AddShiftDrawer.jsx
import React, { useState, useEffect } from "react";
import * as shiftService from '../../../../services/shiftService';
import ErrorModal from '../../ErrorModal';
import AssignSection from './AssignSection';
import PeriodSection from './PeriodSection';
import CreateShiftSection from './CreateShiftSection';
import "./AddShiftDrawer.css";

const AddShiftDrawer = ({ onClose, users, selectedCells = [], onShiftsAdded, currentWeekDate }) => {
    const [shifts, setShifts] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedShiftOption, setSelectedShiftOption] = useState("savedShift");
    const [selectedSavedShift, setSelectedSavedShift] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);

    useEffect(() => {
        fetchShifts();
    }, []);

    const fetchShifts = async () => {
        try {
            const response = await shiftService.fetchCompanyShifts();
            setShifts(response.shifts);
        } catch (error) {
            console.error("Error fetching shifts:", error);
            setErrorMessage("Vardiyalar yüklenirken bir hata oluştu.");
            setShowError(true);
        }
    };

    const validateForm = () => {
        if (selectedCells.length === 0) {
            if (selectedEmployees.length === 0) {
                setErrorMessage("En az bir çalışan seçmelisiniz.");
                setShowError(true);
                return false;
            }
            if (!startDate || !endDate) {
                setErrorMessage("Başlangıç ve bitiş tarihlerini seçmelisiniz.");
                setShowError(true);
                return false;
            }
        }
        if (!selectedSavedShift) {
            setErrorMessage("Bir vardiya seçmelisiniz.");
            setShowError(true);
            return false;
        }
        return true;
    };

    const handleFormSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        setLoadingMessage("Vardiyalar ekleniyor...");

        let shiftAssignments = [];

        if (selectedCells.length > 0) {
            // Specific cell assignments
            shiftAssignments = selectedCells.map(cell => ({
                user_id: cell.employeeId,
                date_of_shift: cell.date,
                shift_id: selectedSavedShift
            }));
        } else {
            // General assignments
            const start = new Date(startDate);
            const end = new Date(endDate);

            for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
                const dayOfWeek = date.getDay();
                const weekDays = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cts'];

                if (selectedDays.length === 0 || selectedDays.includes(weekDays[dayOfWeek])) {
                    selectedEmployees.forEach(employeeId => {
                        shiftAssignments.push({
                            user_id: employeeId,
                            date_of_shift: date.toISOString().split('T')[0],
                            shift_id: selectedSavedShift
                        });
                    });
                }
            }
        }

        try {
            const response = await shiftService.addShifts(shiftAssignments);
            if (response && response.shifts) {
                onShiftsAdded(response.shifts);
                setLoadingMessage("Vardiyalar başarıyla eklendi!");
                setTimeout(() => {
                    setIsLoading(false);
                    onClose();
                }, 1500);
            } else {
                throw new Error("No shifts data in the response");
            }
        } catch (error) {
            console.error("Error creating user shifts:", error);
            setLoadingMessage("Hata oluştu. Lütfen tekrar deneyin.");
            setErrorMessage(error.response?.data?.errors[0] || 'An unexpected error occurred.');
            setShowError(true);
            setIsLoading(false);
        }
    };

    return (
        <div className='drawer-content'>
            <div className='drawer-header'>
                <h2>Vardiya Ekle</h2>
            </div>
            <div className='drawer-body'>
                <AssignSection
                    users={users}
                    selectedEmployees={selectedEmployees}
                    setSelectedEmployees={setSelectedEmployees}
                    selectedCells={selectedCells}
                />
                {selectedCells.length === 0 && (
                    <PeriodSection
                        selectedDays={selectedDays}
                        setSelectedDays={setSelectedDays}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        isDateRangeSelected={isDateRangeSelected}
                        setIsDateRangeSelected={setIsDateRangeSelected}
                        isRestDayDrawer={false}
                        currentWeekDate={currentWeekDate}
                    />
                )}
                <CreateShiftSection
                    selectedShiftOption={selectedShiftOption}
                    setSelectedShiftOption={setSelectedShiftOption}
                    selectedSavedShift={selectedSavedShift}
                    setSelectedSavedShift={setSelectedSavedShift}
                    shifts={shifts}
                />
            </div>
            <div className='drawer-footer'>
                <button className='shift-cancel-button' type='button' onClick={onClose}>
                    Vazgeç
                </button>
                <button
                    className='shift-add-button'
                    type='button'
                    onClick={handleFormSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? 'Ekleniyor...' : 'Ekle'}
                </button>
            </div>
            {isLoading && (
                <div className='loading-overlay'>
                    <div className='loading-spinner'></div>
                    <p>{loadingMessage}</p>
                </div>
            )}
            <ErrorModal showError={showError} errorMessage={errorMessage} onClose={() => setShowError(false)}/>
        </div>
    );
};

export default AddShiftDrawer;