import { useState, useEffect } from 'react';
import { fetchBirthdayData, fetchUpcomingBirthdayData } from '../services/birthdayService';

export const useBirthdayData = () => {
    const [birthdayData, setBirthdayData] = useState([]);
    const [upcomingBirthdayData, setUpcomingBirthdayData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadBirthdayData = async () => {
            try {
                setLoading(true);
                const todayBirthdays = await fetchBirthdayData();
                const upcomingBirthdays = await fetchUpcomingBirthdayData();
                setBirthdayData(todayBirthdays);
                setUpcomingBirthdayData(upcomingBirthdays);
                setError(null);
            } catch (err) {
                setError('Failed to load birthday data');
                setBirthdayData([]);
                setUpcomingBirthdayData([]);
            } finally {
                setLoading(false);
            }
        };

        loadBirthdayData();
    }, []);

    return { birthdayData, upcomingBirthdayData, loading, error };
};
