import { useState, useEffect } from 'react';
import { getRandomPosition } from '../services/gameService';

export const useGameData = () => {
    const [waldoPosition, setWaldoPosition] = useState(getRandomPosition());
    const [score, setScore] = useState(0);
    const [time, setTime] = useState(0);
    const [gameOver, setGameOver] = useState(false);

    // Timer Effect
    useEffect(() => {
        let timer;
        if (!gameOver) {
            // Zamanı her saniye artırmak için interval kurulur
            timer = setInterval(() => setTime(prev => prev + 1), 1000);
        }
        return () => clearInterval(timer); // Bileşen kapatıldığında veya oyun bittiğinde interval temizlenir
    }, [gameOver]);

    // Oyunu sıfırlayan fonksiyon
    const resetGame = () => {
        setWaldoPosition(getRandomPosition());
        setScore(0);
        setTime(0);
        setGameOver(false);
    };

    // Waldo'yu bulduğunda skoru güncelleyen fonksiyon
    const updateGame = () => {
        setScore(prevScore => prevScore + 1);
        setWaldoPosition(getRandomPosition());
    };

    // Oyunu bitiren fonksiyon
    const endGame = () => {
        setGameOver(true);
    };

    return {
        waldoPosition, // Waldo'nun pozisyonu
        score,         // Oyuncunun mevcut skoru
        time,          // Geçen zaman
        gameOver,      // Oyun durumu
        resetGame,     // Oyunu sıfırlama fonksiyonu
        updateGame,    // Skoru güncelleme ve yeni Waldo pozisyonu ayarlama fonksiyonu
        endGame        // Oyunu bitirme fonksiyonu
    };
};
