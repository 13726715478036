import React from 'react';
import './styles.css';

const TypingIndicator = () => (
    <div className="typing-indicator">
        <img
            src="/assets/ai-avatar.png"
            alt="AI"
            className="typing-avatar"
        />
        <span>Asistan yazıyor<span className="typing-dots"></span></span>
    </div>
);

export default TypingIndicator;