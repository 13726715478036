import React from 'react';
import SuggestedActions from './SuggestedActions';

const ErrorContent = ({ message, metadata, suggestedActions }) => {
    return (
        <div className="error-content">
            <div className="error-icon">⚠️</div>
            <div className="error-message">
                <div className="error-message-text">{message}</div>
                {metadata?.details && (
                    <div className="error-details">
                        <small>{metadata.details}</small>
                    </div>
                )}
            </div>
            {suggestedActions && (
                <div className="error-actions">
                    <SuggestedActions
                        actions={[
                            ...(metadata?.suggested_actions || []),
                            {
                                label: "Ana Menüye Dön",
                                action: "show_main_menu",
                                style: "secondary"
                            },
                            {
                                label: "Tekrar Dene",
                                action: "retry_last_message",
                                style: "primary"
                            }
                        ]}
                    />
                </div>
            )}
        </div>
    );
};

export default ErrorContent;