import api from './api';

export const fetchSocialFeed = async (page = 1, perPage = 5) => {
    try {
        const response = await api.get('/posts/social_feeds', {
            params: { page, per_page: perPage }
        });
        return {
            posts: response.data,
            has_more: response.data.length === perPage
        };
    } catch (error) {
        console.error("Sosyal medya gönderileri alınırken hata oluştu:", error);
        throw error;
    }
};

export const likePost = async (postId) => {
    try {
        const response = await api.post(`/posts/${postId}/like`);
        return response.data;
    } catch (error) {
        console.error("Error liking post:", error);
        throw error;
    }
};

export const unlikePost = async (postId) => {
    try {
        const response = await api.delete(`/posts/${postId}/unlike`);
        return response.data;
    } catch (error) {
        console.error("Error unliking post:", error);
        throw error;
    }
};

export const fetchComments = async (postId) => {
    try {
        const response = await api.get(`/posts/${postId}/comments`);
        return response.data;
    } catch (error) {
        console.error("Error fetching comments:", error);
        throw error;
    }
};



export const addComment = async (postId, content) => {
    try {
        const response = await api.post(`/comments`, { comment: { post_id: postId, content } });
        return response.data;
    } catch (error) {
        console.error("Error adding comment:", error);
        throw error;
    }
};

export const updateComment = async (commentId, content) => {
    try {
        const response = await api.put(`/comments/${commentId}`, { comment: { content } });
        return response.data;
    } catch (error) {
        console.error("Error updating comment:", error);
        throw error;
    }
};

export const deleteComment = async (commentId) => {
    try {
        await api.delete(`/comments/${commentId}`);
    } catch (error) {
        console.error("Error deleting comment:", error);
        throw error;
    }
};