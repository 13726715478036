import React from 'react';
import Skeleton from '../common/Skeleton/Skeleton';
import { useWeatherData } from '../../hooks/useWeatherData';
import { useUserData } from '../../hooks/useUserData';
import './Header.css';

const Header = () => {
    const { weatherData, isLoading: weatherLoading, error: weatherError } = useWeatherData();
    const { userData, loading: userLoading, error: userError } = useUserData();

    const getGreetingMessage = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            return 'Günaydın';
        } else if (currentHour < 18) {
            return 'İyi Günler';
        } else {
            return 'İyi Akşamlar';
        }
    };

    const getCurrentDayAndDate = () => {
        const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
        const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
        const today = new Date();
        const day = days[today.getDay()];
        const date = today.getDate();
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        return `Bugün ${day}, ${date} ${month} ${year}`;
    };

    const getPersonalizedMessage = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            return 'Yeni bir güne enerjik bir başlangıç yapmanızı dileriz!';
        } else if (currentHour < 15) {
            return 'Gününüz verimli geçiyor umarız!';
        } else if (currentHour < 18) {
            return 'Günün son saatlerini iyi değerlendirmenizi dileriz!';
        } else {
            return 'Yorucu bir günün ardından keyifli bir akşam geçirmenizi dileriz!';
        }
    };

    const renderWeatherInfo = () => {
        if (weatherLoading) {
            return <Skeleton width={150} height={20} />;
        }

        if (weatherError) {
            return <p className="text-muted">Hava durumu verisi mevcut değil</p>;
        }

        if (weatherData && weatherData.current && weatherData.location) {
            return (
                <div className="weather-info">
                    <p className="text-muted">
                        {weatherData.location.name}, {weatherData.current.temp_c}°C
                        <br />
                        {weatherData.current.condition.text}
                        <br />
                        Hava kalitesi (PM2.5): {weatherData.current.air_quality?.pm2_5 ?? 'Mevcut değil'}
                    </p>
                    <img
                        src={weatherData.current.condition.icon}
                        alt="Hava Durumu İkonu"
                        className="weather-icon"
                    />
                </div>
            );
        }

        return <p className="text-muted">Hava durumu verisi mevcut değil</p>;
    };

    return (
        <div className="dashboard-header">
            <div className="greeting">
                {userLoading ? (
                    <Skeleton width={200} height={30} />
                ) : userError ? (
                    <h1>Hoş Geldiniz, Misafir</h1>
                ) : (
                    <>
                        <h1>{`${getGreetingMessage()}, ${userData?.full_name || 'Değerli Çalışanımız'}`}</h1>
                        <p className="personalized-message">{`${getCurrentDayAndDate()} - ${getPersonalizedMessage()}`}</p>
                    </>
                )}
            </div>
            {renderWeatherInfo()}
        </div>
    );
};

export default Header;