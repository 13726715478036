import React from 'react';
import { useInternalServicesData } from '../../../hooks/useInternalServicesData';
import Skeleton from '../../common/Skeleton/Skeleton';
import styles from './InternalServices.module.css';

const InternalServices = () => {
    const { internalServices, loading, error } = useInternalServicesData();

    if (loading) {
        return (
            <div className={styles.internalServicesGrid}>
                {[...Array(6)].map((_, i) => (
                    <div key={i} className={styles.internalServicesCard}>
                        <Skeleton width={50} height={50} />
                        <Skeleton width={100} height={20} />
                        <Skeleton width={150} height={15} />
                    </div>
                ))}
            </div>
        );
    }

    if (error) {
        return <p className={styles.internalServicesError}>Internal servis verileri yüklenirken hata oluştu: {error}</p>;
    }

    return (
        <div className={styles.internalServicesGrid}>
            {internalServices.map((service, index) => (
                <a
                    key={index}
                    href={service.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.internalServicesLink}
                >
                    <div className={styles.internalServicesCard}>
                        <div className={styles.internalServicesIcon}>
                            <i className={service.icon}></i>
                        </div>
                        <h5 className={styles.internalServicesTitle}>{service.name}</h5>
                        <p className={styles.internalServicesDescription}>{service.description}</p>
                    </div>
                </a>
            ))}
        </div>
    );
};

export default InternalServices;
