export const MAX_CHARACTERS = 200;

export const MESSAGE_TYPES = {
    LEAVE_REQUEST: 'leave_request',
    LEAVE_QUERY: 'leave_query',
    ERROR: 'error'
};

export const ROLES = {
    USER: 'user',
    ASSISTANT: 'assistant'
};

export const DATE_FORMAT_OPTIONS = {
    hour: '2-digit',
    minute: '2-digit'
};

export const ERROR_MESSAGES = {
    GENERAL: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    NETWORK: 'Bağlantı hatası. İnternet bağlantınızı kontrol edin.',
    INVALID_INPUT: 'Geçersiz giriş. Lütfen tekrar deneyin.'
};