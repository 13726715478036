import React, { useState, useEffect } from 'react';
import Skeleton from '../../common/Skeleton/Skeleton';
import { useNewEmployeesData } from '../../../hooks/useNewEmployeesData';
import styles from './NewEmployees.module.css';
import startConfetti from './confetti';

const TWO_PI = Math.PI * 2;
const HALF_PI = Math.PI * 0.5;
let confettiAnimationTriggered = false; // To ensure the confetti plays only once

const NewEmployees = () => {
    const { newEmployees, loading, error } = useNewEmployeesData();
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    useEffect(() => {
        if (selectedEmployee && !confettiAnimationTriggered) {
            confettiAnimationTriggered = true; // Ensure this only runs once per employee selection
            startConfetti();
        }
    }, [selectedEmployee]);

    if (loading) {
        return (
            <div className={styles.newEmployeesCard}>
                <div className={styles.newEmployeesBody}>
                    <h5>Yeni Katılanlar</h5>
                    <Skeleton width={150} height={20} />
                    <Skeleton width={200} height={15} />
                    <Skeleton width={100} height={20} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.newEmployeesCard}>
                <div className={styles.newEmployeesBody}>
                    <h5>Yeni Katılanlar</h5>
                    <p>Yeni çalışan verileri yüklenirken hata oluştu: {error}</p>
                </div>
            </div>
        );
    }

    if (newEmployees.length === 0) {
        return (
            <div className={styles.newEmployeesCard}>
                <div className={styles.newEmployeesBody}>
                    <h5>Yeni Katılanlar</h5>
                    <div className={styles.emptyState}>
                        <div className={styles.emptyStateIcon}>
                            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <h3 className={styles.emptyStateTitle}>Her şey yolunda</h3>
                        <p className={styles.emptyStateDescription}>Yeni katılan çalışanlar burada görüntülenecektir.</p>
                    </div>
                </div>
            </div>
        );
    }

    // Get the featured employee, default to the first if none selected
    const featuredEmployee = selectedEmployee || newEmployees[0];

    return (
        <div className={styles.newEmployeesCard}>
            <div className={styles.newEmployeesBody}>
                <h5>Yeni Katılanlar</h5>

                {/* Featured Employee Card */}
                <div className={styles.featuredEmployeeCard}>
                    <div className={styles.backgroundDecor}>
                        <h3 className={styles.welcomeTitle}>Aramıza Hoş Geldiniz!</h3>
                        <div className={styles.featuredAvatarWrapper}>
                            <img src={featuredEmployee.image_url} alt={featuredEmployee.first_name}
                                 className={styles.featuredAvatar}/>
                        </div>
                        <div className={styles.featuredDetails}>

                            <span
                                className={styles.featuredName}>{featuredEmployee.first_name} {featuredEmployee.last_name}</span>
                            <span className={styles.featuredJobTitle}>{featuredEmployee.job_title}</span>
                            <span className={styles.featuredDate}>
                                {new Date(featuredEmployee.start_date).toLocaleDateString('tr-TR', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric'
                                })}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Employee List */}
                <div className={styles.employeeList}>
                    {newEmployees.slice(0, 2).map((employee, index) => (
                        <div key={index} className={styles.employeeItem} onClick={() => {
                            setSelectedEmployee(employee);
                            confettiAnimationTriggered = false; // Reset confetti trigger for a new employee
                        }}>
                            <img src={employee.image_url} alt={employee.first_name} className={styles.avatar}/>
                            <div className={styles.employeeInfo}>
                                <span className={styles.name}>{employee.first_name} {employee.last_name}</span>
                                <span className={styles.jobTitle}>{employee.job_title}</span>
                            </div>
                            <span className={styles.date}>{new Date(employee.start_date).toLocaleDateString('tr-TR', {
                                day: 'numeric',
                                month: 'long'
                            })}</span>
                        </div>
                    ))}
                </div>
            </div>
            <canvas id="drawing_canvas" className={styles.confettiCanvas}></canvas> {/* Confetti Canvas */}
        </div>
    );
};

export default NewEmployees;
