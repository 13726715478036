import React, { useState, useMemo } from 'react';
import styles from './PendingActions.module.css';
import { usePendingActions } from '../../../hooks/usePendingActions';

const PendingActions = () => {
    const { actions, isLoading, error } = usePendingActions();
    const [activeTab, setActiveTab] = useState('ALL');

    const groupedActions = useMemo(() => {
        const groups = {
            ALL: actions,
            'Rotasyon Talebi': actions.filter(action => action.type === 'Rotasyon Talebi'),
            'İzin': actions.filter(action => action.type === 'İzin'),
            'Değişiklik Talebi': actions.filter(action => action.type === 'Değişiklik Talebi'),
            'İçerik Paylaşımı': actions.filter(action => action.type === 'İçerik Paylaşımı'),
            'Masraf': actions.filter(action => action.type === 'Masraf'),
            'Diğer': actions.filter(action => action.type === 'other'),
        };
        return groups;
    }, [actions]);

    if (isLoading) {
        return <LoadingState />;
    }

    if (error) {
        return <ErrorState />;
    }

    if (!actions || actions.length === 0) {
        return <EmptyState />;
    }

    const tabs = Object.keys(groupedActions).filter(tab => groupedActions[tab].length > 0);
    const currentActions = groupedActions[activeTab];

    return (
        <div className={styles.pendingActions_card}>
            <h2 className={styles.pendingActions_title}>
                Bekleyen Onaylar
                <span className={styles.pendingActions_count}>{actions.length}</span>
            </h2>
            <div className={styles.pendingActions_tabs}>
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`${styles.pendingActions_tab} ${activeTab === tab ? styles.pendingActions_tabActive : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab} ({groupedActions[tab].length})
                    </button>
                ))}
            </div>
            <div className={styles.pendingActions_actionList}>
                {currentActions.map((action, index) => (
                    <div key={index} className={styles.pendingActions_action}>
                        <div className={styles.pendingActions_actionIcon}>
                            <i className={`fas fa-${getIconForType(action.type)}`}></i>
                        </div>
                        <div className={styles.pendingActions_actionContent}>
                            <h3>{action.title}</h3>
                            <p>{action.subtitle}</p>
                            <small>{action.time} - {action.status}</small>
                        </div>
                        <button className={styles.pendingActions_moreButton}>•••</button>
                    </div>
                ))}
            </div>
            {currentActions.length > 5 && (
                <button className={styles.pendingActions_viewAllButton}>VIEW ALL</button>
            )}
        </div>
    );
};

const getIconForType = (type) => {
    switch (type) {
        case 'İzin': return 'calendar-alt';
        case 'Değişiklik Talebi': return 'edit';
        case 'Masraf': return 'receipt';
        case 'Rotasyon Talebi': return 'exchange-alt';
        case 'İçerik Paylaşımı': return 'clipboard';
        default: return 'check-circle';
    }
};

const LoadingState = () => (
    <div className={styles.pendingActions_card}>
        <h2 className={styles.pendingActions_title}>Bekleyen İşlemler</h2>
        <p>Yükleniyor...</p>
    </div>
);

const ErrorState = () => (
    <div className={styles.pendingActions_card}>
        <h2 className={styles.pendingActions_title}>Bekleyen İşlemler</h2>
        <p className={styles.pendingActions_error}>Veriler yüklenirken bir hata oluştu.</p>
    </div>
);

const EmptyState = () => (
    <div className={styles.pendingActions_card}>
        <div className={styles.pendingActions_emptyStateContent}>
            <div className={styles.pendingActions_checkIcon}>
                <i className="fas fa-check-circle"></i>
            </div>
            <h2>You're in the clear</h2>
            <p>New time off requests, shift requests, and requests to join the app will show up right here</p>
        </div>
    </div>
);

export default PendingActions;