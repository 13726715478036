import React, { useState } from 'react';
import styles from './UpcomingBirthdays.module.css';
import { useBirthdayData } from '../../../hooks/useBirthdayData';

const UpcomingBirthdays = () => {
    const { birthdayData, upcomingBirthdayData, loading, error } = useBirthdayData();
    const [isExpanded, setIsExpanded] = useState(false);

    if (loading) {
        return <LoadingState />;
    }

    if (error) {
        return <ErrorState />;
    }

    const allBirthdays = [...birthdayData, ...upcomingBirthdayData];

    if (allBirthdays.length === 0) {
        return <EmptyState />;
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={styles.upcomingBirthdaysCard}>
            <h2 className={styles.upcomingBirthdaysTitle}>BIRTHDAYS</h2>
            <div className={`${styles.upcomingBirthdaysList} ${isExpanded ? styles.upcomingBirthdaysExpanded : ''}`}>
                {allBirthdays.map((user, index) => (
                    <div key={index} className={styles.upcomingBirthdaysItem}>
                        <img src={user.image_url} alt={user.first_name} className={styles.upcomingBirthdaysAvatar} />
                        <div className={styles.upcomingBirthdaysUserInfo}>
                            <h3>{user.first_name} {user.last_name}</h3>
                            <p>{user.birth_date === new Date().toISOString().split('T')[0]
                                ? 'Birthday today'
                                : `Birthday on ${new Date(user.birth_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <button className={styles.upcomingBirthdaysExpandButton} onClick={toggleExpand}>
                {isExpanded ? '▲' : '▼'}
            </button>
        </div>
    );
};

const LoadingState = () => (
    <div className={styles.upcomingBirthdaysCard}>
        <h2 className={styles.upcomingBirthdaysTitle}>BIRTHDAYS</h2>
        <p className={styles.upcomingBirthdaysLoading}>Loading...</p>
    </div>
);

const ErrorState = () => (
    <div className={styles.upcomingBirthdaysCard}>
        <h2 className={styles.upcomingBirthdaysTitle}>BIRTHDAYS</h2>
        <p className={styles.upcomingBirthdaysError}>Error loading birthday data.</p>
    </div>
);

const EmptyState = () => (
    <div className={styles.upcomingBirthdaysCard}>
        <h2 className={styles.upcomingBirthdaysTitle}>BIRTHDAYS</h2>
        <div className={styles.upcomingBirthdaysEmptyState}>
            <div className={styles.upcomingBirthdaysCakeIcon}>
                <i className="fas fa-birthday-cake"></i>
            </div>
            <h3>No upcoming birthdays</h3>
            <p>There are no birthdays to celebrate in the near future.</p>
        </div>
    </div>
);

export default UpcomingBirthdays;