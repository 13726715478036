import React from 'react';
import LeaveRequestForm from './LeaveRequestForm';
import LeaveStatus from './LeaveStatus';
import ErrorContent from './ErrorContent';
import SuggestedActions from './SuggestedActions';
import { useMessageHandlers } from '../../../../hooks/chat/useMessageHandlers';
import { MESSAGE_TYPES, ROLES } from '../constants/config';

const Message = ({ message }) => {
    if (!message || !message.role || !message.content) {
        return null;
    }

    const renderContent = () => {
        // Eğer mesaj bir hata mesajıysa
        if (message.is_error) {
            return (
                <div className="error-content">
                    <div className="error-icon">⚠️</div>
                    <div className="error-message-text">{message.content}</div>
                </div>
            );
        }

        // İzin talebi formu mesajı
        if (message.intent === MESSAGE_TYPES.LEAVE_REQUEST && message.metadata?.form) {
            return (
                <div className="leave-request-form">
                    <h4>İzin Talebi Formu</h4>
                    {Object.entries(message.metadata.form).map(([key, value]) => (
                        <div key={key} className="form-field">
                            <label>{key}:</label>
                            <span>{value}</span>
                        </div>
                    ))}
                </div>
            );
        }

        // İzin durumu sorgusu için özel görünüm
        if (message.intent === MESSAGE_TYPES.LEAVE_QUERY && message.metadata?.leaveStatus) {
            return (
                <div className="leave-status">
                    <h4>İzin Durumu</h4>
                    <div className="leave-balance">
                        {message.metadata.leaveStatus.balances.map((balance, index) => (
                            <div key={index} className="balance-item">
                                <span className="leave-type">{balance.type}:</span>
                                <span className="leave-days">{balance.days} gün</span>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        // Normal mesaj içeriği
        return (
            <div className="message-text">
                {message.content}
                {message.metadata?.suggested_actions && (
                    <div className="suggested-actions">
                        {message.metadata.suggested_actions.map((action, index) => (
                            <button
                                key={index}
                                className={`action-button ${action.style || 'primary'}`}
                                onClick={() => action.onClick?.()}
                            >
                                {action.label}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={`message ${message.role} ${message.is_error ? 'error' : ''}`}>
            {message.role === ROLES.ASSISTANT && (
                <div className="message-avatar">
                    <img
                        src={message.is_error ? "/assets/error-icon.png" : "/assets/ai-avatar.png"}
                        alt={message.is_error ? "Error" : "AI"}
                        className="avatar-image"
                    />
                </div>
            )}

            <div className={`message-content ${message.is_error ? 'error' : ''}`}>
                {renderContent()}
                <div className="message-time">
                    {new Intl.DateTimeFormat('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }).format(new Date(message.created_at))}
                </div>
            </div>
        </div>
    );
};

export default Message;