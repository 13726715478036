import React from 'react';
import SuggestedActions from './SuggestedActions';

const LeaveRequestForm = ({ form, onConfirm, onEdit }) => {
    const formatFieldName = (key) => {
        const fieldMappings = {
            startDate: 'Başlangıç Tarihi',
            endDate: 'Bitiş Tarihi',
            leaveType: 'İzin Tipi',
            reason: 'İzin Nedeni',
            duration: 'Süre',
            status: 'Durum'
        };
        return fieldMappings[key] || key;
    };

    const formatFieldValue = (key, value) => {
        if (key === 'startDate' || key === 'endDate') {
            return new Date(value).toLocaleDateString('tr-TR');
        }
        return value;
    };

    return (
        <div className="leave-request-form">
            <h4>İzin Talebi Formu</h4>
            <div className="form-fields">
                {Object.entries(form).map(([key, value]) => (
                    <div key={key} className="form-field">
                        <label>{formatFieldName(key)}:</label>
                        <span>{formatFieldValue(key, value)}</span>
                    </div>
                ))}
            </div>
            <div className="form-actions">
                <button
                    onClick={onConfirm}
                    className="action-button primary"
                >
                    Onayla
                </button>
                <button
                    onClick={onEdit}
                    className="action-button secondary"
                >
                    Düzenle
                </button>
            </div>
        </div>
    );
};

export default LeaveRequestForm;