import { useState, useEffect } from 'react';
import { fetchPendingActions } from '../services/actionService';

export const usePendingActions = () => {
    const [actions, setActions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadPendingActions = async () => {
            try {
                setIsLoading(true);
                const data = await fetchPendingActions();
                const transformedActions = data.map(action => ({
                    id: action.id,
                    type: typeof action.flow_type === 'string' ? action.flow_type : 'other',
                    title: action.approval_flow_title || 'Bekleyen Onay',
                    time: new Date(action.created_at).toLocaleTimeString(),
                    subtitle: action.demander,
                    status: action.status
                }));

                setActions(transformedActions);
                setError(null);
            } catch (err) {
                console.error('Error loading pending actions:', err);
                setError('Failed to load pending actions');
            } finally {
                setIsLoading(false);
            }
        };

        loadPendingActions();
    }, []);

    return { actions, isLoading, error };
};