import { MAX_CHARACTERS } from '../../components/Chat/Messages/constants/config';

export const validateMessage = (content) => {
    if (!content || !content.trim()) {
        return {
            isValid: false,
            error: 'Mesaj boş olamaz'
        };
    }

    if (content.length > MAX_CHARACTERS) {
        return {
            isValid: false,
            error: `Mesaj ${MAX_CHARACTERS} karakterden uzun olamaz`
        };
    }

    return { isValid: true };
};

export const validateLeaveRequest = (data) => {
    const { startDate, endDate, leaveType } = data;

    if (!startDate || !endDate || !leaveType) {
        return {
            isValid: false,
            error: 'Tüm alanları doldurunuz'
        };
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
        return {
            isValid: false,
            error: 'Başlangıç tarihi bitiş tarihinden sonra olamaz'
        };
    }

    if (start < new Date()) {
        return {
            isValid: false,
            error: 'Geçmiş tarihli izin talebi oluşturamazsınız'
        };
    }

    return { isValid: true };
};