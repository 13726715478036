// ArticleFeedback.jsx
import React, { useState } from 'react';
import styled from 'styled-components';

const FeedbackContainer = styled.div`
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #e2e8f0;
`;

const Title = styled.h4`
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #2d3748;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

const Button = styled.button`
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    background: white;
    color: #4a5568;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s;

    &:hover {
        background: #f7fafc;
    }

    &.helpful:hover {
        color: #48bb78;
        border-color: #48bb78;
    }

    &.not-helpful:hover {
        color: #f56565;
        border-color: #f56565;
    }

    &.primary {
        background: #4299e1;
        color: white;
        border-color: #4299e1;
        
        &:hover {
            background: #3182ce;
        }
    }

    &.secondary {
        background: white;
        color: #4a5568;
    }
`;

const FeedbackForm = styled.div`
    margin-top: 1rem;
`;

const TextArea = styled.textarea`
    width: 100%;
    min-height: 100px;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    margin-bottom: 1rem;
    resize: vertical;
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;

const SuccessMessage = styled.div`
    padding: 1rem;
    background: #f0fff4;
    color: #2f855a;
    border: 1px solid #c6f6d5;
    border-radius: 0.375rem;
    text-align: center;
`;

const ErrorMessage = styled.div`
    padding: 1rem;
    background: #fff5f5;
    color: #c53030;
    border: 1px solid #fed7d7;
    border-radius: 0.375rem;
    text-align: center;
`;

const Icon = styled.i`
    margin-right: 0.5rem;
`;

const ArticleFeedback = ({ articleId }) => {
    const [submitted, setSubmitted] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [comment, setComment] = useState('');
    const [error, setError] = useState(null);

    const handleFeedback = async (isHelpful) => {
        try {
            setFeedback(isHelpful);
            const response = await fetch(`/knowledge_article_feedbacks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    knowledge_article_feedback: {
                        knowledge_article_id: articleId,
                        rating: isHelpful ? 1 : -1,
                        comment: comment
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Feedback submission failed');
            }

            setSubmitted(true);
            setError(null);
        } catch (err) {
            setError('Failed to submit feedback. Please try again.');
        }
    };

    if (error) {
        return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (submitted) {
        return (
            <SuccessMessage>
                Thank you for your feedback!
            </SuccessMessage>
        );
    }

    return (
        <FeedbackContainer>
            <Title>Was this article helpful?</Title>
            {!feedback ? (
                <ButtonContainer>
                    <Button
                        onClick={() => handleFeedback(true)}
                        className="helpful"
                    >
                        <Icon className="fas fa-thumbs-up" /> Yes
                    </Button>
                    <Button
                        onClick={() => handleFeedback(false)}
                        className="not-helpful"
                    >
                        <Icon className="fas fa-thumbs-down" /> No
                    </Button>
                </ButtonContainer>
            ) : (
                <FeedbackForm>
                    <TextArea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Would you like to add any comments? (optional)"
                    />
                    <ActionButtons>
                        <Button
                            onClick={() => setFeedback(null)}
                            className="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleFeedback(feedback)}
                            className="primary"
                        >
                            Submit Feedback
                        </Button>
                    </ActionButtons>
                </FeedbackForm>
            )}
        </FeedbackContainer>
    );
};

export default ArticleFeedback;