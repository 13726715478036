// services/knowledgeBaseService.js
import api from './api';

const handleError = (error, message) => {
    console.error(message, error);
    throw new Error(message);
};

// Knowledge Base'leri getiren metod
export const getKnowledgeBases = async () => {
    try {
        const response = await api.get('/knowledge_bases', {
            params: {
                format: 'json',
                per_page: 25
            },
            timeout: 5000
        });
        return response.data;
    } catch (error) {
        handleError(error, 'Knowledge base listesi alınırken bir hata oluştu.');
    }
};

// Kategorileri getiren metod
export const getCategories = async (knowledgeBaseId) => {
    try {
        const response = await api.get(`/knowledge_bases/${knowledgeBaseId}/knowledge_categories`, {
            params: {
                format: 'json',
                include_articles: true
            },
            timeout: 5000
        });
        return response.data;
    } catch (error) {
        handleError(error, `Kategoriler alınırken bir hata oluştu (knowledge base id: ${knowledgeBaseId}).`);
    }
};

// Knowledge Base detayını getiren metod
export const getKnowledgeBaseDetails = async (knowledgeBaseId) => {
    try {
        const response = await api.get(`/knowledge_bases/${knowledgeBaseId}`, {
            params: {
                format: 'json'
            },
            timeout: 5000
        });

        return {
            ...response.data,
            knowledge_categories: response.data.map(category => ({
                ...category,
                knowledge_articles: category.knowledge_articles?.slice(0, 5) || [],
                total_articles_count: category.total_articles_count || 0
            }))
        };
    } catch (error) {
        console.error(`Knowledge base detayları alınırken hata (id: ${knowledgeBaseId}):`, error);
        handleError(error, `Knowledge base detayları alınırken bir hata oluştu (id: ${knowledgeBaseId}).`);
    }
};


// Kategori detayını getiren metod
export const getCategory = async (knowledgeBaseId, categoryId) => {
    try {
        const response = await api.get(
            `/knowledge_bases/${knowledgeBaseId}/knowledge_categories/${categoryId}`,
            { timeout: 5000 }
        );
        return response.data;
    } catch (error) {
        handleError(error, `Kategori detayı alınırken bir hata oluştu (id: ${categoryId}).`);
    }
};

// Makale detayını getiren metod
export const getArticle = async (knowledgeBaseId, categoryId, articleId) => {
    try {
        const response = await api.get(
            `/knowledge_bases/${knowledgeBaseId}/knowledge_categories/${categoryId}/knowledge_articles/${articleId}/show_react`,
            {
                params: { format: 'json' },
                timeout: 5000
            }
        );
        return response.data;
    } catch (error) {
        console.error('Article fetch error:', error); // Daha detaylı hata logu
        handleError(error, `Makale detayı alınırken bir hata oluştu (id: ${articleId}).`);
    }
};
// Makale arama metodu
export const searchArticles = async (query, knowledgeBaseId = null) => {
    try {
        const params = {
            q: query,
            format: 'json'
        };

        if (knowledgeBaseId) {
            params.knowledge_base_id = knowledgeBaseId;
        }

        const response = await api.get('/knowledge_bases/articles/search', {
            params,
            timeout: 5000
        });
        return response.data;
    } catch (error) {
        handleError(error, `Makale arama işlemi başarısız oldu (query: ${query}).`);
    }
};

// Makale geri bildirimi gönderme metodu
export const submitFeedback = async (knowledgeBaseId, categoryId, articleId, feedback) => {
    try {
        const response = await api.post(
            `/knowledge_bases/${knowledgeBaseId}/knowledge_categories/${categoryId}/knowledge_articles/${articleId}/feedbacks`,
            feedback,
            { timeout: 5000 }
        );
        return response.data;
    } catch (error) {
        handleError(error, `Geri bildirim gönderirken bir hata oluştu (articleId: ${articleId}).`);
    }
};

// Analytics verilerini getiren metod
export const getAnalytics = async (knowledgeBaseId) => {
    try {
        const response = await api.get(`/knowledge_bases/${knowledgeBaseId}/analytics`, {
            params: {
                format: 'json'
            },
            timeout: 5000
        });
        return response.data;
    } catch (error) {
        handleError(error, `Analytics verileri alınırken bir hata oluştu (knowledge base id: ${knowledgeBaseId}).`);
    }
};

// Makale analytics verilerini getiren metod
export const getArticleAnalytics = async (knowledgeBaseId, categoryId, articleId) => {
    try {
        const response = await api.get(
            `/knowledge_bases/${knowledgeBaseId}/knowledge_categories/${categoryId}/knowledge_articles/${articleId}/analytics`,
            {
                params: {
                    format: 'json'
                },
                timeout: 5000
            }
        );
        return response.data;
    } catch (error) {
        handleError(error, `Makale analytics verileri alınırken bir hata oluştu (article id: ${articleId}).`);
    }
};

// Popular, new, and updated articles methods - now properly exported
export const getPopularArticles = async () => {
    try {
        const response = await api.get('/knowledge_articles/popular', {
            params: {
                format: 'json',
                limit: 5
            },
            timeout: 5000
        });
        return response.data;
    } catch (error) {
        handleError(error, 'Popular makaleler alınırken bir hata oluştu.');
    }
};

export const getNewArticles = async () => {
    try {
        const response = await api.get('/knowledge_articles/new_articles', {
            params: {
                format: 'json',
                limit: 5
            },
            timeout: 5000
        });
        return response.data;
    } catch (error) {
        handleError(error, 'Yeni makaleler alınırken bir hata oluştu.');
    }
};

export const getUpdatedArticles = async () => {
    try {
        const response = await api.get('/knowledge_articles/updated', {
            params: {
                format: 'json',
                limit: 5
            },
            timeout: 5000
        });
        return response.data;
    } catch (error) {
        handleError(error, 'Güncellenmiş makaleler alınırken bir hata oluştu.');
    }
};

export const askQuestion = async (question) => {
    try {
        const response = await api.post('/knowledge_bases/ask_question', {
            // question anahtarı içinde göndermek yerine direkt gönderiyoruz
            question: question
        }, {
            timeout: 15000
        });
        return response.data;
    } catch (error) {
        console.error('Ask question error:', error.response?.data || error);
        throw new Error(error.response?.data?.error || 'Soru yanıtlanırken bir hata oluştu.');
    }
};