import React from 'react';

const Conversations = ({ conversations, selectedId, onSelect }) => (
    <div className="conversations-list">
        {conversations?.map(conversation => (
            <div
                key={conversation.id}
                className={`conversation-item ${conversation.id === selectedId ? 'active' : ''}`}
                onClick={() => onSelect(conversation.id)}
            >
                <div className="conversation-header">
                    <span className="conversation-date">
                        {new Intl.DateTimeFormat('tr-TR', {
                            dateStyle: 'short',
                            timeStyle: 'short'
                        }).format(new Date(conversation.created_at))}
                    </span>
                </div>

                <div className="conversation-preview">
                    {conversation.preview || 'Yeni konuşma'}
                </div>
            </div>
        ))}
    </div>
);

export default Conversations;