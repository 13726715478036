import React, { useState } from 'react';
import { useFeedbackData } from '../../../hooks/useFeedbackData';
import styles from './Feedback.module.css';

const Feedback = () => {
    const { feedbackCategories, loading, error, submitFeedback } = useFeedbackData();
    const [feedback, setFeedback] = useState('');
    const [title, setTitle] = useState(''); // Yeni state for title
    const [selectedCategory, setSelectedCategory] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (feedback.trim() === '' || !selectedCategory || title.trim() === '') return;

        try {
            await submitFeedback({ title, content: feedback, feedback_category_id: selectedCategory });
            setFeedback('');
            setTitle('');
            setSelectedCategory('');
            setSubmitSuccess(true);
            setSubmitError(null);
        } catch (err) {
            setSubmitError(err.message);
        }
    };

    const handleNewFeedback = () => {
        setSubmitSuccess(false);
    };

    if (submitSuccess) {
        return (
            <div className={styles.feedbackCard}>
                <div className={styles.feedbackSuccessMessage}>
                    <div className={styles.feedbackSuccessIcon}>✓</div>
                    <h3>Teşekkürler!</h3>
                    <p>Geri bildiriminiz başarıyla alındı.</p>
                    <button onClick={handleNewFeedback} className={styles.feedbackNewButton}>
                        Yeni Geri Bildirim
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.feedbackCard}>
            <h2 className={styles.feedbackHeader}>Geri Bildirim</h2>
            <div className={styles.feedbackBody}>
                <form onSubmit={handleSubmit} className={styles.feedbackForm}>
                    <select
                        className={styles.feedbackSelect}
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        required
                    >
                        <option value="">Kategori seçin</option>
                        {feedbackCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.title}
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        className={styles.feedbackInput}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Geri bildirim başlığı"
                        required
                    />
                    <textarea
                        className={styles.feedbackTextarea}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Çalışma ortamınızı geliştirmek için önerilerinizi paylaşın..."
                        rows="4"
                        required
                    />
                    <button
                        type="submit"
                        className={styles.feedbackSubmitButton}
                        disabled={loading || feedback.trim() === '' || !selectedCategory || title.trim() === ''}
                    >
                        {loading ? 'Gönderiliyor...' : 'Gönder'}
                    </button>
                </form>
                {error && <p className={styles.feedbackError}>{error}</p>}
                {submitError && <p className={styles.feedbackError}>{submitError}</p>}
            </div>
        </div>
    );
};

export default Feedback;