// app/javascript/components/Calendar/CalendarConnections.jsx
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import './CalendarConnections.css';

const CalendarConnections = ({ onConnectionChange }) => {
    const [connections, setConnections] = useState({
        google: { connected: false, lastSync: null, email: null },
        outlook: { connected: false, lastSync: null, email: null }
    });
    useEffect(() => {
        fetchConnections();
    }, []);

    const fetchConnections = async () => {
        try {
            const response = await fetch('/calendar_connections/status');
            const data = await response.json();
            setConnections(data);
        } catch (error) {
            console.error('Takvim bağlantıları kontrol edilirken hata:', error);
        }
    };

    const handleDisconnect = async (provider) => {
        if (!window.confirm(`${provider} takvim bağlantısını kaldırmak istediğinizden emin misiniz?`)) {
            return;
        }

        try {
            const response = await fetch(`/calendar_connections/${provider}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.content
                }
            });

            if (response.ok) {
                await fetchConnections();
                onConnectionChange?.(); // takvim eventlerini yenile
            } else {
                throw new Error('Bağlantı kaldırılamadı');
            }
        } catch (error) {
            console.error(`${provider} bağlantısı kaldırılırken hata:`, error);
            alert('Bağlantı kaldırılırken bir hata oluştu');
        }
    };

    const handleConnect = (provider) => {
        // CSRF token ile form gönderimi
        const form = document.createElement('form');
        form.method = 'post';
        form.action = provider === 'google' ? '/auth/google_oauth2' : '/auth/azure_activedirectory_v2';

        const csrfToken = document.querySelector('[name="csrf-token"]')?.content;
        if (csrfToken) {
            const csrfInput = document.createElement('input');
            csrfInput.type = 'hidden';
            csrfInput.name = 'authenticity_token';
            csrfInput.value = csrfToken;
            form.appendChild(csrfInput);
        }

        document.body.appendChild(form);
        form.submit();
    };

    const formatLastSync = (date) => {
        if (!date) return 'Henüz senkronize edilmedi';
        return new Date(date).toLocaleString('tr-TR');
    };

    return (
        <div className="calendar-connections-container">
            <h2>Harici Takvim Bağlantıları</h2>

            <div className="connections-grid">
                {/* Google Calendar Card */}
                <div className="connection-card">
                    <div className="connection-header">
                        <FontAwesomeIcon icon={faGoogle} className="connection-icon" />
                        <h3>Google Calendar</h3>
                    </div>

                    <div className="connection-status">
                        <span className={`status-indicator ${connections.google.connected ? 'connected' : ''}`}>
                            {connections.google.connected ? 'Bağlı' : 'Bağlı Değil'}
                        </span>
                    </div>

                    {connections.google.connected ? (
                        <>
                            <div className="sync-info">
                                <p>Bağlı Hesap: {connections.google.email}</p>
                                <p>Son Senkronizasyon: {formatLastSync(connections.google.lastSync)}</p>
                            </div>
                            <button
                                className="disconnect-button"
                                onClick={() => handleDisconnect('google')}
                            >
                                Bağlantıyı Kes
                            </button>
                        </>
                    ) : (
                        <button
                            className="connect-button google"
                            onClick={() => handleConnect('google')}
                        >
                            <FontAwesomeIcon icon={faGoogle} /> Google Calendar'a Bağlan
                        </button>
                    )}
                </div>

                {/* Outlook Calendar Card */}
                <div className="connection-card">
                    <div className="connection-header">
                        <FontAwesomeIcon icon={faMicrosoft} className="connection-icon" />
                        <h3>Outlook Calendar</h3>
                    </div>

                    <div className="connection-status">
                        <span className={`status-indicator ${connections.outlook.connected ? 'connected' : ''}`}>
                            {connections.outlook.connected ? 'Bağlı' : 'Bağlı Değil'}
                        </span>
                    </div>

                    {connections.outlook.connected ? (
                        <>
                            <div className="sync-info">
                                <p>Bağlı Hesap: {connections.outlook.email}</p>
                                <p>Son Senkronizasyon: {formatLastSync(connections.outlook.lastSync)}</p>
                            </div>
                            <button
                                className="disconnect-button"
                                onClick={() => handleDisconnect('outlook')}
                            >
                                Bağlantıyı Kes
                            </button>
                        </>
                    ) : (
                        <button
                            className="connect-button outlook"
                            onClick={() => handleConnect('outlook')}
                        >
                            <FontAwesomeIcon icon={faMicrosoft} /> Outlook Calendar'a Bağlan
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CalendarConnections;