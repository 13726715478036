import React from 'react';
import Conversations from './Conversations';
import Messages from './Messages/Messages';
import { useChat } from '../../hooks/chat/useChat';
import './ChatScreen.css';

const ChatScreen = () => {
    const {
        conversations,
        currentConversation,
        messages,
        loading,
        error,
        sendMessage,
        createNewConversation,
        selectConversation,
        loadMoreMessages
    } = useChat();
    return (
        <div className="chat-container">
            <div className="chat-sidebar">
                <button
                    className="new-chat-btn"
                    onClick={async () => {
                        try {
                            await createNewConversation();
                        } catch (err) {
                            console.error('Error creating conversation:', err);
                        }
                    }}
                    disabled={loading}
                >
                    Yeni Sohbet
                </button>

                <Conversations
                    conversations={conversations}
                    selectedId={currentConversation?.id}
                    onSelect={selectConversation}
                />
            </div>

            <div className="chat-main">
                {currentConversation ? (
                    <Messages
                        messages={messages}
                        onSendMessage={sendMessage}
                        onLoadMore={loadMoreMessages}
                        loading={loading}
                        error={error}
                        currentConversation={currentConversation}
                    />
                ) : (
                    <div className="chat-placeholder">
                        Bir sohbet seçin veya yeni bir sohbet başlatın
                    </div>
                )}
            </div>

        </div>
    );
};

export default ChatScreen;
