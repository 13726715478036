import api from './api';

export const fetchBirthdayData = async () => {
    const cachedData = localStorage.getItem('birthdayData');
    const cacheExpiry = localStorage.getItem('birthdayDataExpiry');

    // Cache varsa ve süresi dolmamışsa cache'teki veriyi döndür
    if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
        return JSON.parse(cachedData);
    }

    try {
        const response = await api.get('/birthday');
        const data = response.data;

        // Veriyi localStorage'a kaydet, cache süresini 1 saat olarak ayarla
        localStorage.setItem('birthdayData', JSON.stringify(data));
        localStorage.setItem('birthdayDataExpiry', new Date().getTime() + 60 * 60 * 1000); // 1 saat

        return data;
    } catch (error) {
        console.error("Error fetching birthday data:", error);
        throw error;
    }
};
export const fetchUpcomingBirthdayData = async () => {
    const cachedData = localStorage.getItem('upcomingBirthdayData');
    const cacheExpiry = localStorage.getItem('upcomingBirthdayDataExpiry');

    // Eğer cache varsa ve cache süresi geçmemişse, cache'teki veriyi döndür
    if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
        return JSON.parse(cachedData);
    }

    try {
        const response = await api.get('/upcoming_birthday');
        const data = response.data;

        // Veriyi localStorage'a kaydet, cache süresini 1 saat olarak belirle
        localStorage.setItem('upcomingBirthdayData', JSON.stringify(data));
        localStorage.setItem('upcomingBirthdayDataExpiry', new Date().getTime() + 60 * 60 * 1000); // 1 saat

        return data;
    } catch (error) {
        console.error("Error fetching upcoming birthday data:", error);
        throw error;
    }
};