// app/javascript/components/Dashboard/CalendarWidget/CalendarWidget.jsx
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useCalendarEvents } from '../../../hooks/useCalendarEvents';
import './CalendarWidget.css';

const CalendarWidget = () => {
    const { events, isLoading } = useCalendarEvents();

    if (isLoading) {
        return (
            <div className="card calendar-widget-loading">
                <div className="loading-spinner"></div>
            </div>
        );
    }

    return (
        <div className="card calendar-widget">
            <div className="card-header">
                <h5 className="card-title mb-0">Takvim</h5>
            </div>
            <div className="card-body">
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: ''
                    }}
                    events={events}
                    dayMaxEvents={1} // Maksimum 1 event göster
                    eventDisplay="block"
                    displayEventTime={false}
                    locale="tr"
                    height="auto" // Height'ı auto yaparak içeriğe göre ayarlama
                    contentHeight="auto" // İçerik yüksekliğini auto yapma
                    aspectRatio={1.35} // En-boy oranını ayarlama
                    buttonText={{
                        today: 'Bugün'
                    }}
                    moreLinkText="daha fazla"
                    noEventsText="Etkinlik yok"
                    views={{
                        dayGridMonth: {
                            titleFormat: {year: 'numeric', month: 'long'}
                        }
                    }}
                />

                <div className="calendar-widget-legend">
                    <span className="legend-item legend-personal">
                        Kişisel
                    </span>
                    <span className="legend-item legend-leave">
                        İzin
                    </span>
                    <span className="legend-item legend-shift">
                        Vardiya
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CalendarWidget;