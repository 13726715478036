import { useState, useEffect } from 'react';
import { fetchUserData } from '../services/userService';

export const useUserData = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadUserData = async () => {
            try {
                setLoading(true);
                const data = await fetchUserData();
                setUserData(data);
                setError(null);
            } catch (err) {
                setError('Failed to load user data');
                setUserData(null);
            } finally {
                setLoading(false);
            }
        };

        loadUserData();
    }, []);

    return { userData, loading, error };
};