import { MESSAGE_TYPES } from '../../components/Chat/Messages/constants/config';
import { formatDuration, formatDate } from '../../utilities/chat/messageFormatters';

export const useMessageFormatter = () => {
    // JSON içeriği parse etme
    const parseMessageContent = (message) => {
        if (!message || typeof message.content !== 'string') {
            return message;
        }

        try {
            // Ruby hash syntax'ını temizle
            let cleanContent = message.content
                .replace(/=>/g, ':')
                .replace(/\\"/g, '"')
                .replace(/\\n/g, '\n')
                .trim();

            // Ruby hash formatını JSON'a çevir
            if (cleanContent.match(/^{.*}$/)) {
                // Ruby sembollerini string'e çevir
                cleanContent = cleanContent.replace(/:([a-zA-Z_][a-zA-Z0-9_]*)=>/g, '"$1":');

                try {
                    const parsedContent = JSON.parse(cleanContent);
                    return {
                        ...message,
                        content: parsedContent.content || message.content,
                        metadata: parsedContent.metadata || message.metadata,
                        suggested_actions: parsedContent.suggested_actions || message.suggested_actions
                    };
                } catch {
                    // JSON parse başarısız olursa orijinal içeriği kullan
                    return {
                        ...message,
                        content: cleanContent
                    };
                }
            }

            // Normal string içerik
            return {
                ...message,
                content: cleanContent
            };
        } catch (e) {
            console.warn('Message parsing error:', e);
            return message;
        }
    };

    // İzin bakiyesi mesajını formatlama
    const formatLeaveBalanceMessage = (content) => {
        if (!content || typeof content !== 'string' || !content.includes('Kullanılabilir Bakiye')) {
            return null;
        }

        const lines = content.split('\n').filter(line => line.includes('Kullanılabilir Bakiye'));
        const balances = lines.map(line => {
            const match = line.match(/Kullanılabilir Bakiye:\s*(\d+)\s*gün/);
            return {
                type: "Yıllık İzin",
                days: match ? parseInt(match[1]) : 0,
                hours: 0
            };
        });

        return {
            intent: MESSAGE_TYPES.LEAVE_QUERY,
            metadata: {
                leaveStatus: {
                    balances,
                    pendingRequests: []
                },
                suggested_actions: [
                    {
                        label: "İzin Talep Et",
                        action: "create_leave_request",
                        style: "primary"
                    },
                    {
                        label: "Tüm İzinlerimi Göster",
                        action: "show_all_leaves",
                        style: "secondary"
                    }
                ]
            }
        };
    };

    // Vardiya mesajını formatlama
    const formatShiftMessage = (content) => {
        if (!content || typeof content !== 'string' || !content.includes('Vardiya')) {
            return null;
        }

        const dateMatch = content.match(/(\d{2}\.\d{2}\.\d{4})/);
        const timeMatch = content.match(/Saat: ([\d:]+) - ([\d:]+)/);

        return {
            intent: 'shift_info',
            metadata: {
                shiftInfo: {
                    date: dateMatch ? dateMatch[1] : null,
                    time: timeMatch ? `${timeMatch[1]} - ${timeMatch[2]}` : null
                },
                suggested_actions: [
                    {
                        label: "Haftalık Vardiyalarım",
                        action: "show_weekly_shifts",
                        style: "secondary"
                    },
                    {
                        label: "Vardiya Değişikliği",
                        action: "request_shift_change",
                        style: "primary"
                    }
                ]
            }
        };
    };

    // Tek bir mesajı formatlama
    const formatMessage = (message) => {
        if (!message) return message;

        try {
            // Önce içeriği parse et
            const parsedMessage = parseMessageContent(message);

            // İzin bakiyesi kontrolü
            if (parsedMessage.content?.includes('Kullanılabilir Bakiye')) {
                const leaveFormat = formatLeaveBalanceMessage(parsedMessage.content);
                if (leaveFormat) {
                    return {
                        ...parsedMessage,
                        ...leaveFormat
                    };
                }
            }

            // Vardiya bilgisi kontrolü
            if (parsedMessage.content?.includes('Vardiya')) {
                const shiftFormat = formatShiftMessage(parsedMessage.content);
                if (shiftFormat) {
                    return {
                        ...parsedMessage,
                        ...shiftFormat
                    };
                }
            }

            return parsedMessage;
        } catch (err) {
            console.warn('Message format error:', err);
            return message;
        }
    };

    // Mesaj listesini formatlama
    const formatMessages = (messages) => {
        if (!Array.isArray(messages)) return [];

        return messages.map(message => formatMessage(message));
    };

    return {
        formatMessage,
        formatMessages,
        parseMessageContent
    };
};