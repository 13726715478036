// useUserSearch.js
import { useState, useCallback } from 'react';
import { searchUsers } from '../services/userService';

export const useUserSearch = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const performSearch = useCallback(async (query, active = false) => {
        if (!query) {
            setSearchResults([]);
            return;
        }

        try {
            setLoading(true);
            const results = await searchUsers(query, active);
            setSearchResults(results);
            setError(null);
        } catch (err) {
            setError('Failed to search users');
            setSearchResults([]);
        } finally {
            setLoading(false);
        }
    }, []);

    return { searchResults, loading, error, performSearch };
};