// components/Dashboard/CompanyBanner.jsx
import React from 'react';
import Skeleton from '../common/Skeleton/Skeleton';
import './CompanyBanner.css';

const CompanyBanner = ({ bannerUrl, isLoading, error, weatherData, userData }) => {
    const getTimeBasedBackground = () => {
        const hour = new Date().getHours();

        if (hour >= 5 && hour < 12) {
            return '/images/morning.png';
        } else if (hour >= 12 && hour < 17) {
            return '/images/day_time.png';
        } else if (hour >= 17 && hour < 20) {
            return '/images/sunset.png';
        } else {
            return '/images/night.png';
        }
    };
    const getGreetingMessage = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            return 'Günaydın';
        } else if (currentHour < 18) {
            return 'İyi Günler';
        } else {
            return 'İyi Akşamlar';
        }
    };

    const getCurrentDayAndDate = () => {
        const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
        const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
        const today = new Date();
        return `${days[today.getDay()]}, ${today.getDate()} ${months[today.getMonth()]} ${today.getFullYear()}`;
    };

    const getPersonalizedMessage = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            return 'Yeni bir güne enerjik bir başlangıç yapmanızı dileriz!';
        } else if (currentHour < 15) {
            return 'Gününüz verimli geçiyor umarız!';
        } else if (currentHour < 18) {
            return 'Günün son saatlerini iyi değerlendirmenizi dileriz!';
        } else {
            return 'Yorucu bir günün ardından keyifli bir akşam geçirmenizi dileriz!';
        }
    };

    if (isLoading) {
        return <Skeleton width="100%" height={200} />;
    }

    if (error) {
        return <div className="alert alert-danger">{error}</div>;
    }

    const defaultBackground = getTimeBasedBackground();

    return (
        <div className="company-banner"
             style={{backgroundImage: `url(${defaultBackground})`}}>
            <div className="banner-content">
                <div className="banner-left">
                    <div className="greeting-section">
                        <h1>{`${getGreetingMessage()}, ${userData?.full_name || 'Değerli Çalışanımız'}`}</h1>
                        <div className="weather-info">
                            <div className="temperature">
                                <span className="current-temp">{weatherData?.current?.temp_c || '--'}°C</span>
                                <span className="feels-like">{weatherData?.current?.feelslike_c || '--'}°</span>
                            </div>
                            <div className="condition">
                                <span>{weatherData?.current?.condition?.text || 'Parçalı Bulutlu'}</span>
                                <div className="air-quality">
                                    <span>Hava Kalitesi</span>
                                    <span>{weatherData?.current?.air_quality?.pm2_5?.toFixed(3) || '--'}</span>
                                </div>
                            </div>
                        </div>
                        <p className="personalized-message">{getPersonalizedMessage()}</p>
                    </div>
                </div>
                <div className="banner-right">
                    <div className="current-date">{getCurrentDayAndDate()}</div>
                </div>
            </div>
        </div>
    );
};

export default CompanyBanner;