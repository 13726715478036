import React from 'react';
import Skeleton from '../../common/Skeleton/Skeleton';
import { useTimelySuggestions } from '../../../hooks/useTimelySuggestions';

const TimelySuggestions = () => {
    const { suggestions, isLoading, error } = useTimelySuggestions();

    if (isLoading) {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Timely Suggestions</h5>
                    <Skeleton width={200} height={20} />
                    <Skeleton width={200} height={20} />
                    <Skeleton width={200} height={20} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Timely Suggestions</h5>
                    <p>Error loading suggestions: {error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Timely Suggestions</h5>
                {suggestions.length > 0 ? (
                    suggestions.map((suggestion, index) => (
                        <div key={index} className="d-flex align-items-center justify-content-between">
                            <div>
                                <h6 className="mb-0">{suggestion.title}</h6>
                                <p className="mb-0"><small>{suggestion.description}</small></p>
                            </div>
                            <a href="#" className="btn btn-primary btn-sm">{suggestion.action}</a>
                        </div>
                    ))
                ) : (
                    <p>No suggestions available</p>
                )}
            </div>
        </div>
    );
};

export default TimelySuggestions;