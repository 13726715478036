// leave_type_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["leaveDayFields", "durationFields", "negativeBalanceLimit"]

    connect() {
        this.toggleLeaveDayFields()
        this.toggleDurationFields()
        this.toggleNegativeBalanceLimit()
    }

    toggleLeaveDayFields() {
        const leaveDayUnit = this.element.querySelector('#leave_type_leave_day_unit').value
        const daysField = this.leaveDayFieldsTarget.querySelector('.days-field')

        if (leaveDayUnit === 'days') {
            // Gün seçildiğinde hem gün hem saat alanları görünür
            daysField.style.display = 'block'
            this.leaveDayFieldsTarget.querySelector('.time-fields').style.display = 'block'
        } else {
            // Saat seçildiğinde sadece saat alanı görünür
            daysField.style.display = 'none'
            this.leaveDayFieldsTarget.querySelector('.time-fields').style.display = 'block'
        }
    }

    calculateTotalMinutes() {
        const days = parseInt(this.element.querySelector('[name*="leave_day_days"]')?.value) || 0
        const hours = parseInt(this.element.querySelector('[name*="leave_day_hours"]')?.value) || 0
        const minutes = parseInt(this.element.querySelector('[name*="leave_day_minutes"]')?.value) || 0

        // Convert everything to minutes
        const totalMinutes = (days * 24 * 60) + (hours * 60) + minutes

        // Update hidden field if it exists
        const hiddenField = this.element.querySelector('[name*="leave_day"]')
        if (hiddenField) {
            hiddenField.value = totalMinutes
        }
    }

    toggleDurationFields() {
        const durationUnit = this.element.querySelector('#leave_type_duration_unit')?.value
        if (this.hasDurationFieldsTarget) {
            this.toggleFields(this.durationFieldsTarget, durationUnit)
        }
    }

    toggleNegativeBalanceLimit() {
        const negativeBalanceType = this.element.querySelector('#leave_type_negative_balance_type').value
        if (negativeBalanceType === "limited_negative_balance") {
            this.negativeBalanceLimitTarget.style.display = "block"
        } else {
            this.negativeBalanceLimitTarget.style.display = "none"
        }
    }

    toggleFields(target, unit) {
        const daysFields = target.querySelectorAll('.days-field')
        const hoursFields = target.querySelectorAll('.hours-field')

        if (unit === 'days' || unit === 'in_days') {
            daysFields.forEach(el => el.style.display = 'block')
            hoursFields.forEach(el => el.style.display = 'none')
        } else {
            daysFields.forEach(el => el.style.display = 'none')
            hoursFields.forEach(el => el.style.display = 'block')
        }
    }
}