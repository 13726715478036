// services/companyService.js
import api from './api';

export const fetchCompanyBanner = async () => {
    try {
        const response = await api.get('/my_companies/banner');
        return response.data.banner_url;
    } catch (error) {
        console.error("Error fetching company banner:", error);
        throw error;
    }
};