import React from 'react';
import './styles.css';

const LoadingIndicator = () => (
    <div className="loading-indicator">
        <div className="spinner"></div>
        <span>Eski mesajlar yükleniyor...</span>
    </div>
);

export default LoadingIndicator;