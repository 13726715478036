import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faMapMarkerAlt, faPhone, faEnvelope, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import styles from './KeyContacts.module.css';
import { useUserSearch } from '../../../hooks/useUserSearch';

const KeyContacts = () => {
    const [isListView, setIsListView] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const { searchResults, loading, error, performSearch } = useUserSearch();

    useEffect(() => {
        if (searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                performSearch(searchTerm);
            }, 300);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerm, performSearch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const getInitials = (name) => {
        return name.split(' ').map(n => n[0]).join('').toUpperCase();
    };

    return (
        <div className={styles.keyContacts}>
            <h1 className={styles.keyContacts__title}>KEY CONTACTS</h1>
            <div className={styles.keyContacts__searchBar}>
                <input
                    className={styles.keyContacts__searchInput}
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <FontAwesomeIcon icon={faSearch} className={styles.keyContacts__searchIcon} />
            </div>
            <div className={styles.keyContacts__filters}>
                <div className={styles.keyContacts__dropdown}>
                    <span>Department</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
                <button
                    className={styles.keyContacts__viewButton}
                    onClick={() => setIsListView(!isListView)}
                >
                    {isListView ? 'Details View' : 'List View'}
                </button>
            </div>
            {loading ? (
                <div className={styles.keyContacts__loading}>Loading...</div>
            ) : error ? (
                <div className={styles.keyContacts__error}>{error}</div>
            ) : searchResults.length > 0 ? (
                <>
                    <div className={styles.keyContacts__resultCount}>
                        {searchResults.length} contact{searchResults.length !== 1 ? 's' : ''} found
                    </div>
                    <div className={styles.keyContacts__list}>
                        {searchResults.map(contact => (
                            <div key={contact.id} className={`${styles.keyContacts__card} ${isListView ? styles.listView : styles.detailsView}`}>
                                <div className={styles.keyContacts__avatar}>
                                    {contact.image_url ?
                                        <img src={contact.image_url} alt={`${contact.full_name}'s avatar`} /> :
                                        <div className={styles.keyContacts__initials}>{getInitials(contact.full_name)}</div>
                                    }
                                </div>
                                <div className={styles.keyContacts__info}>
                                    <h2 className={styles.keyContacts__name}>{contact.full_name}</h2>
                                    <p className={styles.keyContacts__position}>{contact.job_title || 'Position not available'}</p>
                                    {isListView && (
                                        <>
                                            {contact.branch && (
                                                <p className={styles.keyContacts__detail}>
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.keyContacts__detailIcon} />
                                                    {contact.branch}
                                                </p>
                                            )}
                                            {contact.phone && (
                                                <p className={styles.keyContacts__detail}>
                                                    <FontAwesomeIcon icon={faPhone} className={styles.keyContacts__detailIcon} />
                                                    {contact.phone}
                                                </p>
                                            )}
                                            <p className={styles.keyContacts__detail}>
                                                <FontAwesomeIcon icon={faEnvelope} className={styles.keyContacts__detailIcon} />
                                                {contact.email}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : searchTerm ? (
                <div className={styles.keyContacts__noResults}>
                    No contacts found. Try a different search term.
                </div>
            ) : null}
        </div>
    );
};

export default KeyContacts;