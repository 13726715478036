import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export default function CustomGanttView({
                                            date,
                                            localizer,
                                            events,
                                            ...props
                                        }) {
    const currentWeek = useMemo(
        () => CustomGanttView.range(date, { localizer }),
        [date, localizer]
    );

    return (
        <div className="gantt-grid">
            <div className="gantt-header">
                {currentWeek.map(day => (
                    <div key={day} className="gantt-header-day">
                        {localizer.format(day, 'dayFormat')}
                    </div>
                ))}
            </div>
            <div className="gantt-body">
                {events.map(user => (
                    <div key={user.user_id} className="gantt-row">
                        <div className="gantt-name">
                            {user.user_name}
                        </div>
                        <div className="gantt-leaves">
                            {user.leaves.map(leave => (
                                <div
                                    key={leave.id}
                                    className="gantt-leave"
                                    style={{
                                        gridColumnStart: localizer.diffDays(leave.start_date, currentWeek[0]) + 1,
                                        gridColumnEnd: localizer.diffDays(leave.end_date, currentWeek[0]) + 2,
                                        backgroundColor: leave.color,
                                    }}
                                >
                                    {leave.leave_type}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

CustomGanttView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    events: PropTypes.arrayOf(PropTypes.object),
};

CustomGanttView.range = (date, { localizer }) => {
    const start = localizer.startOf(date, 'week');
    const end = localizer.endOf(date, 'week');

    let current = start;
    const range = [];

    while (localizer.lte(current, end, 'day')) {
        range.push(current);
        current = localizer.add(current, 1, 'day');
    }

    return range;
};
