import React from 'react';
import Slider from 'react-slick';
import { useAnnouncements } from '../../../hooks/useAnnouncements';
import './Announcements.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Announcements = () => {
    const { announcements, isLoading, error } = useAnnouncements();

    if (isLoading || error || !announcements || announcements.length === 0) {
        return null;
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        appendDots: dots => (
            <div style={{ position: 'absolute', bottom: '10px', width: '100%' }}>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
    };

    return (
        <div className="announcements-container">
            <Slider {...settings}>
                {announcements.map((announcement) => (
                    <div key={announcement.id} className="announcement-slide">
                        <div className="announcement-card" style={{
                            backgroundImage: announcement.banner && announcement.banner.url
                                ? `url(${announcement.banner.url})`
                                : 'linear-gradient(to right, #36d1dc, #5b86e5)'
                        }}>
                            <div className="announcement-overlay">
                                <span className="announcement-tag">DIGITAL</span>
                                <h2 className="announcement-title">{announcement.name}</h2>
                                <p className="announcement-description">
                                    {announcement.body.body.replace(/<[^>]+>/g, '').slice(0, 100)}...
                                </p>
                                <div className="announcement-footer">
                                    <span className="announcement-date">
                                        {new Date(announcement.published_at).toLocaleDateString()}
                                    </span>
                                    <span className="announcement-stats">
                                        <span className="announcement-likes">31</span>
                                        <span className="announcement-comments">0</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Announcements;