import React, { useMemo } from 'react';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { startOfWeek, endOfWeek, addDays, format } from 'date-fns'; // date-fns fonksiyonları

const CustomWeekView = ({ date, localizer, ...props }) => {
    const currRange = useMemo(
        () => CustomWeekView.range(date),
        [date]
    );

    return (
        <TimeGrid
            date={date}
            localizer={localizer}
            range={currRange}
            eventOffset={15}
            {...props}
        />
    );
};

// Haftanın başlangıç ve bitiş günlerini hesaplıyoruz
CustomWeekView.range = (date) => {
    const start = startOfWeek(date, { weekStartsOn: 1 }); // Haftanın ilk günü Pazartesi
    const end = endOfWeek(date, { weekStartsOn: 1 });
    let current = start;
    const range = [];

    while (current <= end) {
        range.push(current);
        current = addDays(current, 1);
    }

    return range;
};

// Haftalar arası gezinti (önceki/sonraki haftaya git)
CustomWeekView.navigate = (date, action) => {
    switch (action) {
        case 'PREV':
            return addDays(date, -7); // Bir hafta geri
        case 'NEXT':
            return addDays(date, 7);  // Bir hafta ileri
        default:
            return date;
    }
};

// Görünümün başlığını oluşturma
CustomWeekView.title = (date) => {
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(date, { weekStartsOn: 1 });
    return `${format(start, 'MMM dd')} - ${format(end, 'MMM dd')}`; // Başlık formatı
};

export default CustomWeekView;
