import React, { useState, useRef, useEffect, useCallback } from 'react';
import debounce from '../../../utilities/debounce';
import { useMessageActions } from '../../../hooks/chat/useMessageActions';
import { useMessageFormatter } from '../../../hooks/chat/useMessageFormatter';
import LoadingIndicator from './LoadingIndicator';
import TypingIndicator from './TypingIndicator';
import MessageForm from './MessageForm/MessageForm';
import Message from './Message/Message';
import { validateMessage } from '../../../utilities/chat/validators';

const Messages = ({ messages = [], onSendMessage, onLoadMore, loading, error, currentConversation }) => {
    // State tanımlamaları
    const [input, setInput] = useState('');
    const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
    const [localError, setLocalError] = useState(null);

    // Ref tanımlamaları
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);

    // Custom hook'lar
    const { handleAction } = useMessageActions(onSendMessage);
    const { formatMessages, parseMessageContent } = useMessageFormatter();

    // Scroll işlemleri
    const scrollToBottom = useCallback(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });
        }
    }, []);

    // Scroll effect
    useEffect(() => {
        if (messagesContainerRef.current) {
            const container = messagesContainerRef.current;
            const shouldScroll = container.scrollHeight - container.scrollTop <= container.clientHeight + 100;

            if (shouldScroll) {
                scrollToBottom();
            }
        }
    }, [messages, scrollToBottom]);

    // Scroll handler
    const handleScroll = useCallback(
        debounce(async (e) => {
            const { scrollTop } = e.target;
            if (scrollTop === 0 && !loading && !loadingMoreMessages) {
                setLoadingMoreMessages(true);
                try {
                    await onLoadMore?.();
                } catch (err) {
                    setLocalError(err.message);
                } finally {
                    setLoadingMoreMessages(false);
                }
            }
        }, 200),
        [loading, loadingMoreMessages, onLoadMore]
    );

    // Form submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        const trimmedInput = input.trim();

        if (!trimmedInput || loading) return;

        try {
            const validationResult = validateMessage(trimmedInput);
            if (!validationResult.isValid) {
                throw new Error(validationResult.error);
            }

            setInput('');
            await onSendMessage(trimmedInput);
            setLocalError(null);
        } catch (err) {
            setInput(trimmedInput);
            setLocalError(err.message);
            console.error('Message submit error:', err);
        }
    };

    // Error message renderer
    const renderErrorMessage = useCallback((errorMessage) => (
        <div className="error-message system-error">
            <div className="error-icon">⚠️</div>
            <div className="error-text">{errorMessage}</div>
        </div>
    ), []);

    // Format messages with proper error handling
    const processedMessages = useCallback(() => {
        try {
            return formatMessages(messages);
        } catch (err) {
            console.error('Message formatting error:', err);
            return messages;
        }
    }, [messages, formatMessages]);

    return (
        <div className="messages-container">
            <div
                className="messages-list"
                ref={messagesContainerRef}
                onScroll={handleScroll}
            >
                {loadingMoreMessages && <LoadingIndicator />}

                {processedMessages().map(message => (
                    <Message
                        key={message?.id || `msg-${Date.now()}-${Math.random()}`}
                        message={message}
                        onAction={handleAction}
                    />
                ))}

                {(error || localError) && renderErrorMessage(error || localError)}

                <div ref={messagesEndRef} />
            </div>

            {loading && <TypingIndicator />}

            <MessageForm
                input={input}
                setInput={setInput}
                loading={loading}
                currentConversation={currentConversation}
                onSubmit={handleSubmit}
                disabled={!currentConversation || loading}
            />
        </div>
    );
};

export default Messages;