import React from 'react';
import Skeleton from '../../common/Skeleton/Skeleton';
import { useQuickTasks } from '../../../hooks/useQuickTasks';

const QuickTasks = () => {
    const { tasks, isLoading, error } = useQuickTasks();

    if (isLoading) {
        return (
            <div className="card mb-4">
                <div className="card-body">
                    <h5 className="card-title">Quick Tasks</h5>
                    <Skeleton width={150} height={20} />
                    <Skeleton width={150} height={20} />
                    <Skeleton width={150} height={20} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="card mb-4">
                <div className="card-body">
                    <h5 className="card-title">Quick Tasks</h5>
                    <p>Error loading tasks: {error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="card mb-4">
            <div className="card-body">
                <h5 className="card-title">Quick Tasks</h5>
                {tasks.length > 0 ? (
                    tasks.map((task, index) => (
                        <a key={index} href="#" className="btn btn-outline-primary btn-block mb-2">{task}</a>
                    ))
                ) : (
                    <p>No tasks available</p>
                )}
            </div>
        </div>
    );
};

export default QuickTasks;