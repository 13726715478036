// hooks/useCompanyBanner.js
import { useState, useEffect } from 'react';
import { fetchCompanyBanner } from '../services/companyService';

export const useCompanyBanner = () => {
    const [bannerUrl, setBannerUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadBannerUrl = async () => {
            // Cache kontrolü (localStorage)
            const cachedBannerUrl = localStorage.getItem('companyBannerUrl');
            const cacheExpiry = localStorage.getItem('companyBannerExpiry');

            if (cachedBannerUrl && cacheExpiry && new Date().getTime() < cacheExpiry) {
                setBannerUrl(cachedBannerUrl);
                setLoading(false);
                return;
            }

            // Cache yoksa veya süresi dolmuşsa API çağrısı yap
            try {
                setLoading(true);
                const url = await fetchCompanyBanner();

                // API'den gelen veriyi cache'e kaydet, cache süresini 1 saat olarak ayarla
                localStorage.setItem('companyBannerUrl', url);
                localStorage.setItem('companyBannerExpiry', new Date().getTime() + 60 * 60 * 1000); // 1 saat

                setBannerUrl(url);
                setError(null);
            } catch (err) {
                console.error('Error loading company banner:', err);
                setError('Failed to load company banner');
                setBannerUrl(null);
            } finally {
                setLoading(false);
            }
        };

        loadBannerUrl();
    }, []);

    return { bannerUrl, loading, error };
};
