// hooks/useOrganizationData.js
import { useState, useEffect } from 'react';
import { fetchOrganizationData, fetchOrganizationFilters } from '../services/organizationService';

const CACHE_DURATION = 6 * 60 * 60 * 1000; // 6 saat (millisaniye cinsinden)

export const useOrganizationData = (filters, showFullOrg) => {
    const [organizationData, setOrganizationData] = useState([]);
    const [filtersData, setFiltersData] = useState({
        job_positions: [],
        departments: [],
        branches: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Cache kontrol fonksiyonu
    const checkCache = (cacheKey) => {
        const cached = localStorage.getItem(cacheKey);
        if (cached) {
            const { data, timestamp } = JSON.parse(cached);
            const isExpired = Date.now() - timestamp > CACHE_DURATION;
            if (!isExpired) {
                return data;
            }
            localStorage.removeItem(cacheKey); // Süresi dolmuş cache'i temizle
        }
        return null;
    };

    // Cache'e yazma fonksiyonu
    const setCache = (cacheKey, data) => {
        const cacheData = {
            data,
            timestamp: Date.now()
        };
        localStorage.setItem(cacheKey, JSON.stringify(cacheData));
    };

    // Filtreleri yükle
    useEffect(() => {
        const loadFilters = async () => {
            const cacheKey = 'org_filters';
            const cachedFilters = checkCache(cacheKey);

            if (cachedFilters) {
                setFiltersData(cachedFilters);
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const filterData = await fetchOrganizationFilters();
                setFiltersData(filterData);
                setCache(cacheKey, filterData);
            } catch (err) {
                setError('Error loading filter data');
            } finally {
                setLoading(false);
            }
        };
        loadFilters();
    }, []);

    // Organizasyon verilerini yükle
    useEffect(() => {
        const loadOrganizationData = async () => {
            if (!showFullOrg && Object.values(filters).every(value => value === '')) {
                setOrganizationData([]);
                return;
            }

            // Cache key oluştur
            const cacheKey = showFullOrg
                ? 'org_data_full'
                : `org_data_${JSON.stringify(filters)}`;

            // Cache kontrolü
            const cachedData = checkCache(cacheKey);
            if (cachedData) {
                setOrganizationData(cachedData);
                return;
            }

            try {
                setLoading(true);
                const orgData = await fetchOrganizationData(filters, showFullOrg);
                setOrganizationData(orgData);
                setCache(cacheKey, orgData);
            } catch (err) {
                setError('Error loading organization data');
            } finally {
                setLoading(false);
            }
        };
        loadOrganizationData();
    }, [filters, showFullOrg]);

    return { organizationData, filtersData, loading, error };
};