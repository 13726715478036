// useLeaveOverlapData.js
import { useState, useEffect } from 'react';
import { fetchLeaveOverlaps } from '../services/leaveService';

export const useLeaveOverlapData = (branchId, start, end, viewMode) => {
    const [overlapData, setOverlapData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!branchId || !start || !end) {
                setOverlapData([]);
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            try {
                const data = await fetchLeaveOverlaps(branchId, start, end, viewMode);
                console.log('Fetched overlap data:', data);

                // Ensure the data has the correct structure
                const processedData = Array.isArray(data) ? data.map(employee => ({
                    ...employee,
                    leaves: Array.isArray(employee.leaves) ? employee.leaves : []
                })) : [];

                setOverlapData(processedData);
                setError(null);
            } catch (err) {
                console.error("Error fetching leave overlap data:", err);
                setError("İzin çakışma verileri yüklenirken bir hata oluştu.");
                setOverlapData([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [branchId, start, end, viewMode]);

    return { overlapData, isLoading, error };
};