import React from 'react';
import './Dashboard.css';
import Header from './Header';
import CompanyBanner from './CompanyBanner';
import PendingActions from './PendingActions/PendingActions';
import Announcements from './Announcements/Announcements';
import UpcomingBirthdays from './UpcomingBirthdays/UpcomingBirthdays';
import NewEmployees from './NewEmployees/NewEmployees';
import SocialFeed from './SocialFeed/SocialFeed';
import InternalServices from './InternalServices/InternalServices';
import KeyContacts from "./KeyContacts/KeyContacts";
import Feedback from './Feedback/Feedback';
import CalendarWidget from './CalendarWidget/CalendarWidget';


import QuickActions from './QuickActions/QuickActions';
import { useUserData } from '../../hooks/useUserData';
import { useWeatherData } from '../../hooks/useWeatherData';
import { useCompanyBanner } from '../../hooks/useCompanyBanner';


const Dashboard = () => {
    const { userData, userLoading, userError } = useUserData();
    const { weatherData, weatherLoading, weatherError } = useWeatherData();
    const { bannerUrl, bannerLoading, bannerError } = useCompanyBanner();

    return (
        <section className="dashboard">
            <div className="container-fluid mt-4">
                {/* Company Banner Section */}
                <div className="row mb-4">
                    <div className="col-12">

                        <CompanyBanner
                            bannerUrl={bannerUrl}
                            isLoading={bannerLoading}
                            error={bannerError}
                            weatherData={weatherData}
                            userData={userData}
                        />


                    </div>
                </div>

                {/* Header Section */}
                {/*<div className="row mb-4">*/}
                {/*    <div className="col-12">*/}
                {/*        <Header*/}
                {/*            user={userData}*/}
                {/*            weather={weatherData}*/}
                {/*            isLoading={userLoading || weatherLoading}*/}
                {/*            error={userError || weatherError}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Main Content */}
                <div className="row">
                    {/* Left Column (Main Content) */}
                    <div className="col-md-8">
                        <QuickActions />
                        <Announcements />
                        <SocialFeed />
                    </div>

                    {/* Right Column (Widgets) */}
                    <div className="col-md-4">
                        <CalendarWidget />
                        <NewEmployees />
                        <PendingActions />
                        <UpcomingBirthdays />
                        <InternalServices />
                        <KeyContacts />
                        <Feedback />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;