import React from 'react';
import { RadioGroup } from '@headlessui/react';
import InfiniteScroll from "react-infinite-scroll-component";

const CreateShiftSection = ({
                                selectedShiftOption,
                                setSelectedShiftOption,
                                selectedSavedShift,
                                setSelectedSavedShift,
                                shifts,
                                searchQuery,
                                setSearchQuery,
                                fetchMoreShifts,
                                hasMore
                            }) => {
    const CheckIcon = () => (
        <svg className="check-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6L9 17L4 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

    return (
        <div className="section">

        <div className='shift-options'>
            <div className={`shift-option ${selectedShiftOption === 'savedShift' ? 'selected' : ''}`}>
                <input
                    type='radio'
                    name='shiftType'
                    id='savedShift'
                    value='savedShift'
                    checked={selectedShiftOption === 'savedShift'}
                    onChange={() => setSelectedShiftOption('savedShift')}
                />
                <label htmlFor='savedShift'>Tanımlı Vardiyalar</label>
            </div>
            {selectedShiftOption === 'savedShift' && (
                <div className='saved-shift-container'>
                    <input
                        type='text'
                        placeholder='Vardiya ara...'
                        className='saved-shift-search'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <InfiniteScroll
                        dataLength={shifts.length}
                        next={fetchMoreShifts}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        height={200}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Tüm vardiyalar gösteriliyor!</b>
                            </p>
                        }
                    >
                        <RadioGroup value={selectedSavedShift} onChange={setSelectedSavedShift} className='saved-shift-list'>
                            {shifts.map((shift) => (
                                <RadioGroup.Option key={shift.id} value={shift.id} className={({ active, checked }) =>
                                    `saved-shift-item ${checked ? 'selected' : ''} ${active ? 'active' : ''}`
                                }>
                                    {({ checked }) => (
                                        <>
                                            <span className="saved-shift-label">{shift.name}</span>
                                            {checked && <CheckIcon />}
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </RadioGroup>
                    </InfiniteScroll>
                </div>
            )}
        </div>
        </div>
    );
};

export default CreateShiftSection;