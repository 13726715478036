import React from 'react';
import { usePopularArticles, useNewArticles, useUpdatedArticles } from '../../hooks/useKnowledgeBase';
import Skeleton from '../common/Skeleton/Skeleton';

export const KnowledgeBaseTopics = () => {
    const { articles: popularArticles, loading: popularLoading, error: popularError } = usePopularArticles();
    const { articles: newArticles, loading: newLoading, error: newError } = useNewArticles();
    const { articles: updatedArticles, loading: updatedLoading, error: updatedError } = useUpdatedArticles();

    const renderArticleList = (articles, loading, error, type) => {
        if (loading) {
            return (
                <div className="kb-article-list">
                    {[...Array(5)].map((_, i) => (
                        <div key={i} className="kb-article-link">
                            {type !== 'Popular' && (
                                <Skeleton width="60px" height="20px" />
                            )}
                            <Skeleton width="80%" height="24px" />
                            <Skeleton width="20px" height="20px" />
                        </div>
                    ))}
                </div>
            );
        }

        if (error) {
            return <div className="error">Error loading {type.toLowerCase()} articles: {error}</div>;
        }

        if (!articles?.length) {
            return <div className="no-articles">No {type.toLowerCase()} articles available</div>;
        }

        return (
            <div className="kb-article-list">
                {articles.map(article => (
                    <a
                        key={article.id}
                        href={`/knowledge_bases/${article.knowledge_base_id}/knowledge_categories/${article.knowledge_category_id}/knowledge_articles/${article.id}/show_react`}
                        className="kb-article-link"
                        onClick={() => {
                            sessionStorage.setItem('breadcrumb', JSON.stringify({
                                knowledgeBaseName: article.knowledge_base_name,
                                categoryName: article.category_name,
                                path: [article.knowledge_base_name, article.category_name]
                            }));
                        }}
                    >
                        {type !== 'Popular' && (
                            <span className={`badge ${type.toLowerCase()}`}>{type}</span>
                        )}
                        <span>{article.title}</span>
                        <i className="fas fa-chevron-right"></i>
                    </a>
                ))}
            </div>
        );
    };

    return (
        <div className="topics-container">
            <div className="topics-grid">
                <div className="topic-section">
                    <h4>Popüler Makaleler</h4>
                    {renderArticleList(popularArticles, popularLoading, popularError, 'Popular')}
                </div>

                <div className="topic-section">
                    <h4>Yeni Makaleler</h4>
                    {renderArticleList(newArticles, newLoading, newError, 'New')}
                </div>

                <div className="topic-section">
                    <h4>Güncellenen Makaleler</h4>
                    {renderArticleList(updatedArticles, updatedLoading, updatedError, 'Updated')}
                </div>
            </div>
        </div>
    );
};
