import api from './api';

export const fetchFeedbackCategories = async () => {
    try {
        const response = await api.get('/feedback_categories');
        return response.data;
    } catch (error) {
        console.error("Geri bildirim kategorileri alınırken hata oluştu:", error);
        throw error;
    }
};

export const createFeedback = async (feedbackData) => {
    try {
        const response = await api.post('/feedbacks', feedbackData);
        return response.data;
    } catch (error) {
        console.error("Geri bildirim oluşturulurken hata oluştu:", error);
        throw error;
    }
};