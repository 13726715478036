// knowledgeBaseService.js - Yeni eklenen servis methodu
export const askQuestion = async (question) => {
    try {
        const response = await api.post('/knowledge_bases/ask_question', {
            question
        }, {
            timeout: 15000 // AI yanıtı için daha uzun timeout
        });
        return response.data;
    } catch (error) {
        handleError(error, `Soru yanıtlanırken bir hata oluştu.`);
    }
};

// KnowledgeBaseSearch.jsx
import React, { useState, useCallback, useEffect } from 'react';
import * as knowledgeBaseService from '../../services/knowledgeBaseService';
import './KnowledgeBaseSearch.css';

export const KnowledgeBaseSearch = ({ value, onChange }) => {
    const [question, setQuestion] = useState(value);
    const [showResults, setShowResults] = useState(false);
    const [answer, setAnswer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Debounce fonksiyonu
    const debounce = useCallback((func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    }, []);

    // AI'dan yanıt alma işlemi
    const getAnswer = async (question) => {
        if (!question || question.length < 3) {
            setAnswer({
                error: 'Lütfen en az 3 karakterlik bir soru giriniz.'
            });
            return;
        }

        setIsLoading(true);
        try {
            const result = await knowledgeBaseService.askQuestion(question);
            setAnswer(result);
            setShowResults(true);
        } catch (error) {
            console.error('Error getting answer:', error);
            setAnswer({
                error: error.message || 'Üzgünüm, şu anda yanıt veremiyorum. Lütfen daha sonra tekrar deneyin.'
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Enter tuşu ile soru sorma
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && question.trim()) {
            e.preventDefault();
            getAnswer(question);
        }
    };

    // Input değişikliği
    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setQuestion(newValue);
        onChange(newValue);
        setShowResults(false); // Yeni soru yazarken sonuçları gizle
    };

    // Dışarı tıklama kontrolü
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.knowledge-base-search')) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="knowledge-base-search">
            <div className="search-input-wrapper">
                <input
                    type="text"
                    value={question}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Herhangi bir soru sorun..."
                    className="search-input"
                />
                <button
                    className="search-button"
                    onClick={() => question.trim() && getAnswer(question)}
                    disabled={isLoading || !question.trim()}
                >
                    {isLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                        <i className="fas fa-paper-plane"></i>
                    )}
                </button>
            </div>

            {showResults && answer && (
                <div className="search-results">
                    {answer.error ? (
                        <div className="error-result">
                            <i className="fas fa-exclamation-circle"></i>
                            <p>{answer.error}</p>
                        </div>
                    ) : (
                        <div className="answer-container">
                            <div className="answer-content">
                                <i className="fas fa-robot answer-icon"></i>
                                <div className="answer-text">
                                    {answer.answer}
                                </div>
                            </div>

                            {answer.sources && answer.sources.length > 0 && (
                                <div className="answer-sources">
                                    <h4>Kaynaklar:</h4>
                                    <ul>
                                        {answer.sources.map((source, index) => (
                                            <li
                                                key={index}
                                                onClick={() => window.location.href = source.url}
                                                className="source-item"
                                            >
                                                <span className="source-title">{source.title}</span>
                                                {source.summary && (
                                                    <span className="source-summary">{source.summary}</span>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default KnowledgeBaseSearch;