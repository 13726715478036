import React from 'react';
import Skeleton from '../../common/Skeleton/Skeleton';
import { useTopApps } from '../../../hooks/useTopApps';

const TopApps = () => {
    const { apps, isLoading, error } = useTopApps();

    if (isLoading) {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Your Top Apps</h5>
                    <Skeleton width={150} height={20} />
                    <Skeleton width={150} height={20} />
                    <Skeleton width={150} height={20} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Your Top Apps</h5>
                    <p>Error loading apps: {error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Your Top Apps</h5>
                {apps.length > 0 ? (
                    apps.map((app, index) => (
                        <a key={index} href="#" className="btn btn-light btn-block text-left mb-2">
                            <i className="fas fa-file-alt mr-2"></i> {app}
                        </a>
                    ))
                ) : (
                    <p>No apps available</p>
                )}
            </div>
        </div>
    );
};

export default TopApps;