// app/javascript/hooks/useCalendarEvents.js

import { useState, useEffect } from 'react';
import {
    fetchEvents,
    fetchLeaveEvents,
    fetchShiftEvents,
    fetchHolidayEvents,
    createEvent,
    updateEvent,
    deleteEvent
} from '../services/calendarService';

export const useCalendarEvents = () => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadAllEvents = async () => {
        setIsLoading(true);
        try {
            const allEvents = [];

            try {
                const regularEvents = await fetchEvents();
                allEvents.push(...regularEvents.map(event => formatEventForCalendar(event)));
            } catch (error) {
                console.error("Regular events could not be loaded:", error);
            }

            try {
                const leaveEvents = await fetchLeaveEvents();
                allEvents.push(...leaveEvents.map(event => formatEventForCalendar({
                    ...event,
                    classNames: 'leave-event'
                })));
            } catch (error) {
                console.error("Leave events could not be loaded:", error);
            }

            try {
                const shiftEvents = await fetchShiftEvents();
                allEvents.push(...shiftEvents.map(event => formatEventForCalendar({
                    ...event,
                    classNames: 'shift-event'
                })));
            } catch (error) {
                console.error("Shift events could not be loaded:", error);
            }

            try {
                const holidayEvents = await fetchHolidayEvents();
                allEvents.push(...holidayEvents.map(event => formatEventForCalendar({
                    ...event,
                    classNames: 'holiday-event'
                })));
            } catch (error) {
                console.error("Holiday events could not be loaded:", error);
            }

            setEvents(allEvents);
        } catch (error) {
            console.error("Error in loadAllEvents:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatEventForCalendar = (eventData) => {
        return {
            id: eventData.id || Date.now().toString(),
            title: eventData.title,
            start: eventData.start,
            end: eventData.end,
            allDay: eventData.allDay,
            backgroundColor: eventData.color,
            borderColor: eventData.color,
            classNames: eventData.classNames || 'personal-event',
            display: 'auto', // FullCalendar için gerekli
            extendedProps: {
                description: eventData.description,
                event_type: eventData.event_type || 'personal'
            }
        };
    };

    const handleEventAdd = async (eventData) => {
        try {
            const response = await createEvent(eventData);
            const formattedEvent = formatEventForCalendar(response.data);
            setEvents(prevEvents => [...prevEvents, formattedEvent]);
            return formattedEvent;
        } catch (error) {
            console.error("Error creating event:", error);
            // API hatası durumunda bile UI'da göster
            const newEvent = formatEventForCalendar({
                ...eventData,
                id: Date.now().toString()
            });
            setEvents(prevEvents => [...prevEvents, newEvent]);
            return newEvent;
        }
    };
    const handleEventUpdate = async (eventData) => {
        try {
            const response = await updateEvent(eventData);
            const formattedEvent = formatEventForCalendar(response.data);
            setEvents(prevEvents =>
                prevEvents.map(event =>
                    event.id === eventData.id ? formattedEvent : event
                )
            );
            return formattedEvent;
        } catch (error) {
            console.error("Error updating event:", error);
            const updatedEvent = formatEventForCalendar(eventData);
            setEvents(prevEvents =>
                prevEvents.map(event =>
                    event.id === eventData.id ? updatedEvent : event
                )
            );
            return updatedEvent;
        }
    };

    const handleEventDelete = async (eventId) => {
        try {
            await deleteEvent(eventId);
            setEvents(prevEvents => prevEvents.filter(event => event.id !== eventId));
        } catch (error) {
            console.error("Error deleting event:", error);
            // Hata olsa bile UI'dan kaldır
            setEvents(prevEvents => prevEvents.filter(event => event.id !== eventId));
        }
    };

    useEffect(() => {
        loadAllEvents();
    }, []);

    return {
        events,
        isLoading,
        handleEventAdd,
        handleEventUpdate,
        handleEventDelete,
        refreshEvents: loadAllEvents
    };
};