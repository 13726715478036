import React from 'react';
import { MAX_CHARACTERS } from '../constants/config';
import './styles.css';

const MessageForm = ({ input, setInput, loading, currentConversation, onSubmit }) => {
    const handleInputChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= MAX_CHARACTERS) {
            setInput(newValue);
        }
    };

    const isDisabled = loading || !currentConversation?.id;
    const isSubmitDisabled = isDisabled || !input.trim();

    return (
        <form className="message-form" onSubmit={onSubmit}>
            <div className="input-wrapper">
                <span className="character-counter">
                    {input.length}/{MAX_CHARACTERS}
                </span>
                <input
                    type="text"
                    value={input}
                    onChange={handleInputChange}
                    placeholder="Mesajınızı yazın..."
                    disabled={isDisabled}
                    className={isDisabled ? 'disabled' : ''}
                    maxLength={MAX_CHARACTERS}
                />
            </div>
            <button
                type="submit"
                disabled={isSubmitDisabled}
                className={isSubmitDisabled ? 'disabled' : ''}
            >
                Gönder
            </button>
        </form>
    );
};

export default MessageForm;