// hooks/useKnowledgeBase.js
import { useState, useEffect, useCallback } from 'react';
import * as knowledgeBaseService from '../services/knowledgeBaseService';



export const useKnowledgeBase = (id) => {
    const [knowledgeBase, setKnowledgeBase] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchKnowledgeBase = async () => {
            if (!id) return;

            try {
                setLoading(true);
                const data = await knowledgeBaseService.getKnowledgeBaseDetails(id);
                console.log('Knowledge Base Data in Hook:', data); // Veriyi kontrol edelim
                setKnowledgeBase(data);
                setError(null);
            } catch (err) {
                console.error("Knowledge base detayı yüklenirken hata:", err);
                setError('Knowledge base detayları yüklenemedi. Lütfen tekrar deneyin.');
                setKnowledgeBase(null);
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeBase();
    }, [id]);

    return { knowledgeBase, loading, error };
};

// Knowledge Base'leri getiren hook
export const useKnowledgeBases = () => {
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            try {
                setLoading(true);
                const data = await knowledgeBaseService.getKnowledgeBases();
                console.log('Knowledge Bases Data:', data); // Debug için
                setKnowledgeBases(data);
                setError(null);
            } catch (err) {
                console.error("Knowledge bases yüklenirken hata:", err);
                setError('Knowledge bases yüklenemedi. Lütfen tekrar deneyin.');
                setKnowledgeBases([]);
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeBases();
    }, []);

    return { knowledgeBases, loading, error };
};

// Kategorileri getiren hook
export const useCategories = (knowledgeBaseId) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            if (!knowledgeBaseId) {
                setCategories([]);
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const data = await knowledgeBaseService.getCategories(knowledgeBaseId);
                setCategories(data);
                setError(null);
            } catch (err) {
                console.error("Kategori yüklenirken hata:", err);
                setError('Kategoriler yüklenemedi. Lütfen tekrar deneyin.');
                setCategories([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, [knowledgeBaseId]);

    return { categories, loading, error };
};

// Kategori detayını getiren hook
export const useCategory = (knowledgeBaseId, categoryId) => {
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategory = async () => {
            if (!knowledgeBaseId || !categoryId) return;

            try {
                setLoading(true);
                const data = await knowledgeBaseService.getCategory(knowledgeBaseId, categoryId);
                setCategory(data);
                setError(null);
            } catch (err) {
                console.error("Kategori detayı yüklenirken hata:", err);
                setError('Kategori detayı yüklenemedi. Lütfen tekrar deneyin.');
                setCategory(null);
            } finally {
                setLoading(false);
            }
        };

        fetchCategory();
    }, [knowledgeBaseId, categoryId]);

    return { category, loading, error };
};

// Makaleyi getiren hook
export const useArticle = (knowledgeBaseId, categoryId, articleId) => {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            if (!knowledgeBaseId || !categoryId || !articleId) return;

            try {
                setLoading(true);
                const data = await knowledgeBaseService.getArticle(knowledgeBaseId, categoryId, articleId);
                setArticle(data);
                setError(null);
            } catch (err) {
                console.error("Makale yüklenirken hata:", err);
                setError('Makale yüklenemedi. Lütfen tekrar deneyin.');
                setArticle(null);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, [knowledgeBaseId, categoryId, articleId]);

    return { article, loading, error };
};

// Makale arama işlemi için hook
export const useArticleSearch = () => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const searchArticles = useCallback(async (query, knowledgeBaseId = null) => {
        if (!query || query.length < 2) {
            setResults([]);
            return;
        }

        try {
            setLoading(true);
            setError(null);
            const data = await knowledgeBaseService.searchArticles(query, knowledgeBaseId);
            setResults(data);
        } catch (err) {
            console.error("Arama yapılırken hata:", err);
            setError('Arama işlemi başarısız oldu. Lütfen tekrar deneyin.');
            setResults([]);
        } finally {
            setLoading(false);
        }
    }, []);

    return { results, loading, error, searchArticles };
};

// Geri bildirim göndermek için hook
export const useFeedback = () => {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const submitFeedback = useCallback(async (knowledgeBaseId, categoryId, articleId, feedback) => {
        try {
            setSubmitting(true);
            setError(null);
            await knowledgeBaseService.submitFeedback(knowledgeBaseId, categoryId, articleId, feedback);
        } catch (err) {
            console.error("Geri bildirim gönderilirken hata:", err);
            setError('Geri bildirim gönderilemedi. Lütfen tekrar deneyin.');
            throw err;
        } finally {
            setSubmitting(false);
        }
    }, []);

    return { submitting, error, submitFeedback };
};

// Analytics verilerini getiren hook
export const useAnalytics = (knowledgeBaseId) => {
    const [analytics, setAnalytics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAnalytics = async () => {
            if (!knowledgeBaseId) return;

            try {
                setLoading(true);
                const data = await knowledgeBaseService.getAnalytics(knowledgeBaseId);
                setAnalytics(data);
                setError(null);
            } catch (err) {
                console.error("Analytics verileri yüklenirken hata:", err);
                setError('Analytics verileri yüklenemedi. Lütfen tekrar deneyin.');
                setAnalytics(null);
            } finally {
                setLoading(false);
            }
        };

        fetchAnalytics();
    }, [knowledgeBaseId]);

    return { analytics, loading, error };
};

// Makale analytics verilerini getiren hook
export const useArticleAnalytics = (knowledgeBaseId, categoryId, articleId) => {
    const [analytics, setAnalytics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticleAnalytics = async () => {
            if (!knowledgeBaseId || !categoryId || !articleId) return;

            try {
                setLoading(true);
                const data = await knowledgeBaseService.getArticleAnalytics(knowledgeBaseId, categoryId, articleId);
                setAnalytics(data);
                setError(null);
            } catch (err) {
                console.error("Makale analytics verileri yüklenirken hata:", err);
                setError('Makale analytics verileri yüklenemedi. Lütfen tekrar deneyin.');
                setAnalytics(null);
            } finally {
                setLoading(false);
            }
        };

        fetchArticleAnalytics();
    }, [knowledgeBaseId, categoryId, articleId]);

    return { analytics, loading, error };
};



export const usePopularArticles = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPopularArticles = async () => {
            try {
                setLoading(true);
                const data = await knowledgeBaseService.getPopularArticles();
                setArticles(data);
                setError(null);
            } catch (err) {
                console.error("Popular articles loading error:", err);
                setError('Could not load popular articles. Please try again.');
                setArticles([]);
            } finally {
                setLoading(false);
            }
        };

        fetchPopularArticles();
    }, []);

    return { articles, loading, error };
};

export const useNewArticles = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNewArticles = async () => {
            try {
                setLoading(true);
                const data = await knowledgeBaseService.getNewArticles();
                setArticles(data);
                setError(null);
            } catch (err) {
                console.error("New articles loading error:", err);
                setError('Could not load new articles. Please try again.');
                setArticles([]);
            } finally {
                setLoading(false);
            }
        };

        fetchNewArticles();
    }, []);

    return { articles, loading, error };
};

export const useUpdatedArticles = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUpdatedArticles = async () => {
            try {
                setLoading(true);
                const data = await knowledgeBaseService.getUpdatedArticles();
                setArticles(data);
                setError(null);
            } catch (err) {
                console.error("Updated articles loading error:", err);
                setError('Could not load updated articles. Please try again.');
                setArticles([]);
            } finally {
                setLoading(false);
            }
        };

        fetchUpdatedArticles();
    }, []);

    return { articles, loading, error };
};
