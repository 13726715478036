// app/javascript/hooks/useChat.js
import { useState, useEffect } from 'react';
import * as chatService from '../../services/chatService';

export const useChat = () => {
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchConversations();
    }, []);

    useEffect(() => {
        if (currentConversation) {
            fetchMessages(currentConversation.id);
        }
    }, [currentConversation]);

    const fetchConversations = async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await chatService.fetchConversations();
            setConversations(data);
            setCurrentConversation((prev) => prev || data[0] || null);
        } catch (err) {
            const errorMessage = {
                id: `error-${Date.now()}`,
                content: `⚠️ Sohbet listesi yüklenirken bir hata oluştu: ${err.message}`,
                role: 'assistant',
                created_at: new Date().toISOString(),
                isError: true
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setLoading(false);
        }
    };

    const fetchMessages = async (conversationId, page = 1) => {
        try {
            setLoading(true);
            setError(null);
            const { messages: newMessages, meta } = await chatService.fetchMessages(conversationId, page);

            // Yeni mesajları doğru sırayla ekle
            setMessages(prev => {
                if (page === 1) {
                    // İlk sayfa için sadece yeni mesajları göster
                    return [...newMessages];
                } else {
                    // Eski mesajları başa ekle
                    return [...newMessages, ...prev];
                }
            });

            setHasMore(page < meta.total_pages);
            setPage(page);
        } catch (err) {
            const errorMessage = {
                id: `error-${Date.now()}`,
                content: `⚠️ Mesajlar yüklenirken bir hata oluştu: ${err.message}`,
                role: 'assistant',
                created_at: new Date().toISOString(),
                isError: true
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setLoading(false);
        }
    };

    const handleSendMessage = async (content) => {
        try {
            setLoading(true);

            // Kullanıcı mesajını oluştur
            const userMessage = {
                id: `temp-${Date.now()}`,
                content,
                role: 'user',
                created_at: new Date().toISOString()
            };

            // Kullanıcı mesajını hemen göster
            setMessages(prev => [...prev, userMessage]);

            let response;
            if (currentConversation) {
                response = await chatService.sendMessage(currentConversation.id, content);
            } else {
                const newConversation = await chatService.createConversation();
                setCurrentConversation(newConversation);
                setConversations(prev => [newConversation, ...prev]);
                response = await chatService.sendMessage(newConversation.id, content);
            }

            // Geçici kullanıcı mesajını kaldır ve gerçek mesajları ekle
            setMessages(prev => {
                const withoutTemp = prev.filter(msg => msg.id !== userMessage.id);
                // Backend'den gelen assistant mesajını ekle
                return [...withoutTemp, {
                    ...userMessage,
                    id: response.meta?.user_message_id || userMessage.id // Eğer backend user mesaj ID'sini dönüyorsa
                }, response.message];
            });

            // Konuşma listesini güncelle
            if (currentConversation) {
                setConversations(prev =>
                    prev.map(conv =>
                        conv.id === currentConversation.id
                            ? { ...conv, last_message: response.message }
                            : conv
                    )
                );
            }

            return response;
        } catch (err) {
            const errorMessage = {
                id: `error-${Date.now()}`,
                content: `⚠️ ${err.message}`,
                role: 'assistant',
                created_at: new Date().toISOString(),
                isError: true
            };
            setMessages(prev => [...prev, errorMessage]);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createNewConversation = async () => {
        try {
            setLoading(true);
            setError(null);
            console.log('Creating new conversation...'); // Debug için

            const newConversation = await chatService.createConversation();
            console.log('New conversation created:', newConversation); // Debug için

            // State güncellemelerini sıralı yapalım
            setConversations(prev => [newConversation, ...prev]);
            setCurrentConversation(newConversation);
            setMessages([]);

            return newConversation; // Sonucu döndürelim

        } catch (err) {
            console.error('Error in createNewConversation:', err);
            const errorMessage = {
                id: `error-${Date.now()}`,
                content: `⚠️ Yeni sohbet oluşturulurken bir hata oluştu: ${err.message}`,
                role: 'assistant',
                created_at: new Date().toISOString(),
                isError: true
            };
            setMessages(prev => [...prev, errorMessage]);
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const loadMoreMessages = () => {
        if (!hasMore || loading || !currentConversation) return;
        fetchMessages(currentConversation.id, page + 1);
    };

    const selectConversation = (id) => {
        const conversation = conversations.find(c => c.id === id);
        if (conversation) {
            setError(null);
            setCurrentConversation(conversation);
            setPage(1);
            setHasMore(true);
            setMessages([]); // Yeni konuşma seçildiğinde mesajları temizle
        }
    };

    return {
        conversations,
        currentConversation,
        messages,
        loading,
        error,
        hasMore,
        sendMessage: handleSendMessage,
        createNewConversation,
        selectConversation,
        loadMoreMessages
    };
};