import api from './api';

export const fetchNewEmployees = async () => {
    const cachedData = localStorage.getItem('newEmployees');
    const cacheExpiry = localStorage.getItem('newEmployeesExpiry');

    // Eğer cache varsa ve cache süresi geçmemişse, cache'teki veriyi döndür
    if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
        return JSON.parse(cachedData);
    }

    try {
        const response = await api.get('/users/new_employees_today');
        const data = response.data;

        // Veriyi localStorage'a kaydet, cache süresini 1 saat olarak belirle
        localStorage.setItem('newEmployees', JSON.stringify(data));
        localStorage.setItem('newEmployeesExpiry', new Date().getTime() + 60 * 60 * 1000); // 1 saat

        return data;
    } catch (error) {
        console.error("Yeni çalışan verileri alınırken hata oluştu:", error);
        throw error;
    }
};
