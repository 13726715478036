import { useState, useEffect } from 'react';
import { fetchInternalServices } from '../services/internalService';

export const useInternalServicesData = () => {
    const [internalServices, setInternalServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadInternalServicesData = async () => {
            try {
                setLoading(true); // Loading state set to true
                const data = await fetchInternalServices(); // Fetch data from API
                setInternalServices(data); // Set the fetched data
                setError(null); // Reset error if successful
            } catch (err) {
                setError('Internal servis verileri yüklenirken bir hata oluştu');
                setInternalServices([]); // Clear data in case of error
            } finally {
                setLoading(false); // Set loading state to false
            }
        };

        loadInternalServicesData();
    }, []);

    return { internalServices, loading, error };
};
