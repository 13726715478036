import React from 'react';
import './SurveyPreviewModal.css';

const SurveyPreviewModal = ({show, handleClose, survey, handleSave, questionTypes}) => {
    if (!show) {
        return null;
    }

    // Soru tipini Türkçe'ye çeviren yardımcı fonksiyon
    const getQuestionTypeInTurkish = (type) => {
        const questionType = questionTypes.find(qt => qt.value === type);
        return questionType ? questionType.title : type;
    };

    return (
        <div className="survey-modal-overlay">
            <div className="survey-modal-content">
                <div className="survey-modal-header">
                    <h2>Anket Önizleme</h2>
                    <p>{survey.description}</p>
                </div>
                <div className="survey-modal-body">
                    <div className="survey-preview-header">
                        <div>
                            <h3>{survey.name}</h3>
                            <p>{survey.description}</p>
                        </div>
                        <button className="survey-preview-button" onClick={handleSave}>Taslak</button>
                    </div>

                    {survey.questions.map((question, index) => (
                        <div key={question.temp_id} className="survey-question-preview">
                            <div className="survey-question-header">
                                <h4>
                                    Soru {index + 1}: {question.text}
                                    {question.is_required && (
                                        <span className="survey-required-label"> Zorunlu</span>
                                    )}
                                </h4>
                                <div className="survey-question-type">
                                    <span>Anket Tipi</span>
                                    <select disabled>
                                        <option>{getQuestionTypeInTurkish(question.type)}</option>
                                    </select>
                                </div>
                            </div>
                            {question.type === 'free_text' && (
                                <textarea className="survey-free-text-input" placeholder="Cevabınızı Yazın" disabled/>
                            )}
                            {question.type === 'multiple_choice' && (
                                <div className="survey-answer-options">
                                    {question.answers.map((answer) => (
                                        <div key={answer.temp_id} className="survey-answer-option">
                                            <input
                                                type="radio"
                                                id={`answer-${answer.temp_id}`}
                                                name={`question-${question.temp_id}`}
                                                disabled
                                                checked={answer.is_correct}
                                            />
                                            <label htmlFor={`answer-${answer.temp_id}`}>
                                                {answer.title}
                                                {answer.is_correct &&
                                                    <span className="survey-correct-answer"> (Doğru Cevap)</span>}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {question.type === 'true_or_false' && (
                                <div className="survey-answer-options">
                                    {question.answers.map((answer) => (
                                        <div key={answer.temp_id} className="survey-answer-option">
                                            <input
                                                type="radio"
                                                id={`answer-${answer.temp_id}`}
                                                name={`question-${question.temp_id}`}
                                                disabled
                                                checked={answer.is_correct}
                                            />
                                            <label htmlFor={`answer-${answer.temp_id}`}>
                                                {answer.title}
                                                {answer.is_correct &&
                                                    <span className="survey-correct-answer"> (Doğru Cevap)</span>}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="survey-modal-footer">
                    <button onClick={handleSave} className="survey-save-button">Kaydet</button>
                    <button onClick={handleClose} className="survey-close-button">Kapat</button>
                </div>
            </div>
        </div>
    );
};

export default SurveyPreviewModal;