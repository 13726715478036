// useSocialFeedData.js
import { useState, useEffect } from 'react';
import { fetchSocialFeed } from '../services/socialFeedService';

export const useSocialFeedData = (page) => {
    const [socialPosts, setSocialPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        const loadSocialFeedData = async () => {
            try {
                setLoading(true);
                const data = await fetchSocialFeed(page);
                setSocialPosts(prevPosts => [...prevPosts, ...data.posts]);
                setHasMore(data.has_more);
                setError(null);
            } catch (err) {
                setError('Sosyal medya gönderileri yüklenirken bir hata oluştu');
            } finally {
                setLoading(false);
            }
        };

        loadSocialFeedData();
    }, [page]);

    return { socialPosts, setSocialPosts, loading, error, hasMore };
};