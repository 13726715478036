import { useState, useEffect } from 'react';
import { fetchFeedbackCategories, createFeedback } from '../services/feedbackService';

export const useFeedbackData = () => {
    const [feedbackCategories, setFeedbackCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadFeedbackCategories = async () => {
            try {
                setLoading(true);
                const categories = await fetchFeedbackCategories();
                setFeedbackCategories(categories);
            } catch (err) {
                setError('Kategoriler yüklenirken bir hata oluştu');
            } finally {
                setLoading(false);
            }
        };

        loadFeedbackCategories();
    }, []);

    const submitFeedback = async (feedbackData) => {
        // feedbackData şimdi { title, content, feedback_category_id } içerebilir
        try {
            setLoading(true);
            await createFeedback(feedbackData);
        } catch (err) {
            throw new Error('Geri bildirim gönderilirken bir hata oluştu');
        } finally {
            setLoading(false);
        }
    };

    return { feedbackCategories, loading, error, submitFeedback };
};