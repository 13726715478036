// app/javascript/components/Calendar/Calendar.jsx
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import EventModal from './EventModal';
import CalendarConnections from './CalendarConnections';
import { useCalendarEvents } from '../../hooks/useCalendarEvents';
import './Calendar.css';

const Calendar = () => {
    const {
        events,
        isLoading,
        error,
        handleEventAdd,
        handleEventUpdate,
        handleEventDelete
    } = useCalendarEvents();

    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [activeTab, setActiveTab] = useState('calendar');
    const [connectedCalendars, setConnectedCalendars] = useState(null);


    useEffect(() => {
        checkCalendarConnections();
    }, []);

    const checkCalendarConnections = async () => {
        try {
            const response = await fetch('/calendar_connections/status');
            if (!response.ok) {
                throw new Error('Bağlantı durumu alınamadı');
            }
            const data = await response.json();
            setConnectedCalendars({
                google: data.google.connected,
                outlook: data.outlook.connected
            });
        } catch (error) {
            console.error('Takvim bağlantıları kontrol edilirken hata:', error);
            setConnectedCalendars({ google: false, outlook: false });
        }
    };


    const handleExportICS = () => {
        window.location.href = '/calendar_events/export_ics';
    };

    const handleDateSelect = (selectInfo) => {
        setSelectedDate(selectInfo);
        setShowModal(true);
    };

    const handleEventClick = (clickInfo) => {
        setSelectedEvent(clickInfo.event);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedEvent(null);
        setSelectedDate(null);
    };

    const handleAdd = async (eventData) => {
        try {
            await handleEventAdd(eventData);
            handleModalClose();
        } catch (error) {
            console.error('Event eklenirken hata:', error);
            handleModalClose();
        }
    };

    const handleUpdate = async (eventData) => {
        try {
            await handleEventUpdate(eventData);
            handleModalClose();
        } catch (error) {
            console.error('Event güncellenirken hata:', error);
            handleModalClose();
        }
    };

    const handleDelete = async (eventId) => {
        try {
            await handleEventDelete(eventId);
            handleModalClose();
        } catch (error) {
            console.error('Event silinirken hata:', error);
            handleModalClose();
        }
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div className="fc-event-content">
                <div className="fc-event-title">{eventInfo.event.title}</div>
                {eventInfo.event.extendedProps?.description && (
                    <div className="fc-event-desc">{eventInfo.event.extendedProps.description}</div>
                )}
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="calendar-loading">
                <div className="loading-spinner"></div>
                <p>Takvim yükleniyor...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="calendar-error">
                <p>Takvim olayları yüklenirken bir hata oluştu.</p>
                <button onClick={() => window.location.reload()}>Yenile</button>
            </div>
        );
    }

    return (
        <div className="calendar-container">
            <div className="calendar-tabs">
                <button
                    className={`tab-button ${activeTab === 'calendar' ? 'active' : ''}`}
                    onClick={() => setActiveTab('calendar')}
                >
                    Takvim
                </button>
                <button
                    className={`tab-button ${activeTab === 'connections' ? 'active' : ''}`}
                    onClick={() => setActiveTab('connections')}
                >
                    Takvim Bağlantıları
                </button>
            </div>

            {activeTab === 'calendar' ? (
                <>
                    <div className="calendar-header">
                        <div className="calendar-actions">
                            <button
                                className="export-ics-button"
                                onClick={handleExportICS}
                            >
                                Takvimi Dışa Aktar (ICS)
                            </button>

                            {/* Bağlı takvim göstergeleri */}
                            <div className="connected-calendars-status">
                                {connectedCalendars && (
                                    <>
                                        {connectedCalendars.google && (
                                            <span className="calendar-badge google">
                        Google Calendar Bağlı
                    </span>
                                        )}
                                        {connectedCalendars.outlook && (
                                            <span className="calendar-badge outlook">
                        Outlook Calendar Bağlı
                    </span>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                        }}
                        initialView="dayGridMonth"
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={true}
                        events={events}
                        select={handleDateSelect}
                        eventDisplay="auto"
                        eventClick={handleEventClick}
                        eventContent={renderEventContent}
                        eventInteractive={true}
                        displayEventTime={true}
                        displayEventEnd={true}
                        forceEventDuration={true}
                        locale="tr"
                        buttonText={{
                            today: 'Bugün',
                            month: 'Ay',
                            week: 'Hafta',
                            day: 'Gün',
                            list: 'Liste'
                        }}
                        views={{
                            dayGridMonth: {
                                titleFormat: { year: 'numeric', month: 'long' }
                            },
                            timeGridWeek: {
                                titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
                            },
                            timeGridDay: {
                                titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
                            },
                            listWeek: {
                                titleFormat: { year: 'numeric', month: 'long' }
                            }
                        }}
                        allDayText="Tüm gün"
                        moreLinkText="daha fazla"
                        noEventsText="Gösterilecek etkinlik yok"
                        slotMinTime="07:00:00"
                        slotMaxTime="21:00:00"
                        scrollTime="08:00:00"
                        firstDay={1}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: false,
                            hour12: false
                        }}
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: false,
                            hour12: false
                        }}
                    />

                    {showModal && (
                        <EventModal
                            isOpen={showModal}
                            onClose={handleModalClose}
                            event={selectedEvent}
                            selectedDate={selectedDate}
                            onAdd={handleAdd}
                            onUpdate={handleUpdate}
                            onDelete={handleDelete}
                        />
                    )}

                    <div className="calendar-legend">
                        <div className="legend-item">
                            <span className="legend-color personal-event"></span>
                            <span className="legend-label">Kişisel</span>
                        </div>
                        <div className="legend-item">
                            <span className="legend-color leave-event"></span>
                            <span className="legend-label">İzin</span>
                        </div>
                        <div className="legend-item">
                            <span className="legend-color shift-event"></span>
                            <span className="legend-label">Vardiya</span>
                        </div>
                        <div className="legend-item">
                            <span className="legend-color holiday-event"></span>
                            <span className="legend-label">Tatil</span>
                        </div>
                    </div>
                </>
            ) : (
                <CalendarConnections
                    connectedCalendars={connectedCalendars}
                    onConnectionUpdate={checkCalendarConnections}
                />
            )}
        </div>
    );
};

export default Calendar;