import React, { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import BranchSelector from './BranchSelector';
import OverlapCalendar from './OverlapCalendar';
import { useLeaveOverlapData } from '../../hooks/useLeaveOverlapData';
import { useBranchData } from '../../hooks/useBranchData';
import "./LeaveOverlapCalendar.css";

const LeaveOverlapCalendar = () => {
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [viewMode, setViewMode] = useState('week');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [dateRange, setDateRange] = useState({ start: null, end: null });

    const { branches, isLoading: branchesLoading, error: branchesError } = useBranchData();
    const { overlapData, isLoading, error } = useLeaveOverlapData(selectedBranch, dateRange.start, dateRange.end, viewMode);

    useEffect(() => {
        if (selectedBranch) {
            updateDateRange(currentDate, viewMode);
        }
    }, [selectedBranch, currentDate, viewMode]);

    const updateDateRange = (date, mode) => {
        let start, end;
        switch (mode) {
            case 'week':
                start = startOfWeek(date, { weekStartsOn: 1 });
                end = endOfWeek(date, { weekStartsOn: 1 });
                break;
            case 'month':
                start = new Date(date.getFullYear(), date.getMonth(), 1);
                end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
            case 'day':
                start = date;
                end = date;
                break;
            default:
                start = date;
                end = date;
        }
        setDateRange({ start: format(start, 'yyyy-MM-dd'), end: format(end, 'yyyy-MM-dd') });
    };

    const handleBranchChange = (branchId) => {
        setSelectedBranch(branchId);
        if (branchId) {
            updateDateRange(currentDate, viewMode);
        }
    };
    const handleDateChange = (date, view) => {
        setCurrentDate(date);
        if (view) setViewMode(view);
        if (selectedBranch) {
            updateDateRange(date, view || viewMode);
        }
    };

    return (

        <div className="leave-overlap-container">
            <header className="leave-overlap-header">
                <h1 className="page-title">Kesişen İzinler</h1>
            </header>
                <div className="filter-section">
                    <BranchSelector
                        branches={branches}
                        selectedBranch={selectedBranch}
                        onBranchChange={handleBranchChange}
                        isLoading={branchesLoading}
                        error={branchesError}
                    />
                </div>

                {!selectedBranch ? (
                    <div className="no-branch-selected">
                        <p>Lütfen izin çakışmalarını görmek için bir şube seçin.</p>
                    </div>
                ) : (
                    <div className="calendar-section">
                        {isLoading ? (
                            <div>Veriler yükleniyor...</div>
                        ) : error ? (
                            <div>Hata: {error}</div>
                        ) : (
                            <OverlapCalendar
                                overlapData={overlapData}
                                viewMode={viewMode}
                                currentDate={currentDate}
                                onDateChange={handleDateChange}
                                views={['day', 'week', 'month']}
                            />
                        )}
                    </div>
                )}
        </div>
);
};

export default LeaveOverlapCalendar;