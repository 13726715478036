import React, { useEffect, useRef, useState } from 'react';
import { useGameData } from '../../../hooks/useGameData';
import { useScoreData } from '../../../hooks/useScoreData';
import ScoreBoard from '../../common/ScoreBoard/ScoreBoard';

const GameBoard = ({ userId, gameId, companyId  }) => {
    const { waldoPosition, time, gameOver, resetGame, updateGame, endGame } = useGameData();
    const { score, incrementScore, resetScore, saveCurrentScore } = useScoreData();
    const canvasRef = useRef(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [waldoImage, setWaldoImage] = useState(null);

    useEffect(() => {
        const background = new Image();
        const waldo = new Image();

        background.src = '/assets/games/background.jpg';
        waldo.src = '/assets/games/transparent_waldo.png';

        background.onload = () => setBackgroundImage(background);
        waldo.onload = () => setWaldoImage(waldo);
    }, []);

    useEffect(() => {
        if (backgroundImage) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
            drawWaldo();
        }
    }, [backgroundImage]);

    useEffect(() => {
        drawWaldo();
    }, [waldoPosition]);

    const drawWaldo = () => {
        if (waldoImage) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(waldoImage, waldoPosition.x, waldoPosition.y, waldoPosition.width, waldoPosition.height);
        }
    };

    const handleClick = (event) => {
        if (gameOver) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (x >= waldoPosition.x && x <= waldoPosition.x + waldoPosition.width &&
            y >= waldoPosition.y && y <= waldoPosition.y + waldoPosition.height) {
            alert('You found Waldo!');
            updateGame();
        } else {
            alert('Try again!');
        }
    };

    const handleUpdateGame = () => {
        incrementScore();
        updateGame();
    };

    const handleResetGame = () => {
        resetGame();
        resetScore();
    };

    const handleEndGame = async () => {
        endGame();
        await saveCurrentScore(userId, gameId, companyId);
    };


    return (
        <div className="game-container">

            <canvas
                ref={canvasRef}
                onClick={handleClick}
                width="800"
                height="600"
            />
            <ScoreBoard
                userId={userId}
                gameId={gameId}
                companyId={companyId}
                score={score}
                time={time}
            />
            <button onClick={handleResetGame}>Reset Game</button>
            {gameOver && <button onClick={handleEndGame}>End Game</button>}
        </div>
    );
};

export default GameBoard;
