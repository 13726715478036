import api from './api';

export const fetchUserData = async () => {
    try {
        const response = await api.get('/me');
        return response.data;
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const searchUsers = async (query, active = false) => {
    try {
        const response = await api.get(`/users/key_contacts_search`, {
            params: { query, active }
        });
        return response.data;
    } catch (error) {
        console.error("Error searching users:", error);
        throw error;
    }
};


export const fetchOrganizationData = async () => {
    try {
        const response = await api.get('/users/organization_chart');
        return response.data;
    } catch (error) {
        console.error("Error fetching organization data:", error);
        throw error;
    }
};


export const fetchOrganizationFilters = async () => {
    try {
        const response = await api.get('/users/organization_filters');
        return response.data;
    } catch (error) {
        console.error("Error fetching organization filters:", error);
        throw error;
    }
};