import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["scrollWrapper", "stickyColumn"]

    connect() {
        this.setupTable()
        this.setupDragToScroll()
        this.setupCopyCell()
        this.handleWindowResize = this.handleWindowResize.bind(this)
        window.addEventListener('resize', this.handleWindowResize)
    }

    disconnect() {
        window.removeEventListener('resize', this.handleWindowResize)
        this.removeDragToScroll()
    }

    setupDragToScroll() {
        const wrapper = this.scrollWrapperTarget

        let isMouseDown = false
        let startX
        let scrollLeft
        let isDragging = false
        let moveCount = 0

        wrapper.addEventListener('mousedown', (e) => {
            if (e.target.closest('.cell-content')) {
                return
            }

            isMouseDown = true
            moveCount = 0
            wrapper.style.cursor = 'grabbing'

            startX = e.pageX - wrapper.offsetLeft
            scrollLeft = wrapper.scrollLeft
        })
        wrapper.addEventListener('mousedown', (e) => {
            // Eğer text seçiliyse drag'i engelle
            if (window.getSelection().toString()) {
                return
            }

            isMouseDown = true
            moveCount = 0
            wrapper.style.cursor = 'grabbing'

            startX = e.pageX - wrapper.offsetLeft
            scrollLeft = wrapper.scrollLeft
        })

        wrapper.addEventListener('mousemove', (e) => {
            if (!isMouseDown) return

            moveCount++
            if (moveCount > 3) { // Minimum hareket eşiği
                isDragging = true
                wrapper.classList.add('dragging')
            }

            e.preventDefault()
            const x = e.pageX - wrapper.offsetLeft
            const walk = (x - startX) * 2
            wrapper.scrollLeft = scrollLeft - walk
        })

        const endDrag = () => {
            isMouseDown = false
            isDragging = false
            wrapper.classList.remove('dragging')
            wrapper.style.cursor = 'grab'

            // Çok az hareket olduysa text seçimine izin ver
            if (moveCount <= 3) {
                wrapper.style.userSelect = 'text'
            }
        }

        wrapper.addEventListener('mouseup', endDrag)
        wrapper.addEventListener('mouseleave', endDrag)

        // Çift tıklama ile satır seçimi
        wrapper.addEventListener('dblclick', (e) => {
            const row = e.target.closest('tr')
            if (row) {
                const selection = window.getSelection()
                const range = document.createRange()
                range.selectNodeContents(row)
                selection.removeAllRanges()
                selection.addRange(range)
            }
        })
    }

    setupCopyCell() {
        this.element.addEventListener('click', (e) => {
            const cellContent = e.target.closest('.cell-content')
            if (cellContent) {
                this.selectAndCopyText(cellContent)
            }
        })
    }

    selectAndCopyText(element) {
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(element)
        selection.removeAllRanges()
        selection.addRange(range)

        try {
            document.execCommand('copy')
            this.showCopyFeedback(element)
        } catch (err) {
            console.error('Kopyalama başarısız:', err)
        }

        selection.removeAllRanges()
    }

    showCopyFeedback(element) {
        element.classList.add('copied')
        setTimeout(() => {
            element.classList.remove('copied')
        }, 500)
    }


    removeDragToScroll() {
        const wrapper = this.scrollWrapperTarget
        wrapper.style.cursor = 'default'
        // Event listener'ları temizle
    }

    setupTable() {
        this.stickyColumnTargets.forEach(column => {
            const originalBg = window.getComputedStyle(column).backgroundColor
            column.style.backgroundColor = originalBg
        })
        this.handleWindowResize()
    }

    handleWindowResize() {
        const wrapper = this.scrollWrapperTarget
        const table = wrapper.querySelector('table')
        if (table) {
            const minWidth = this.calculateMinWidth()
            table.style.minWidth = `${minWidth}px`
        }
    }

    calculateMinWidth() {
        return 500 + 800
    }
}