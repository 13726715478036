// app/javascript/services/calendarService.js

import api from './api';

export const fetchEvents = async (start, end) => {
    try {
        const response = await api.get('/calendar_events', {
            params: { start, end }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching calendar events:", error);
        throw error;
    }
};

export const createEvent = async (eventData) => {
    try {
        const response = await api.post('/calendar_events', {
            calendar_event: {
                title: eventData.title,
                description: eventData.description,
                start: eventData.start,
                end: eventData.end,
                all_day: eventData.allDay,
                color: eventData.color,
                event_type: eventData.event_type || 'personal'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error creating calendar event:", error);
        throw error;
    }
};

export const updateEvent = async (eventData) => {
    try {
        const response = await api.put(`/calendar_events/${eventData.id}`, {
            calendar_event: {
                title: eventData.title,
                description: eventData.description,
                start: eventData.start,
                end: eventData.end,
                all_day: eventData.allDay,
                color: eventData.color,
                event_type: eventData.event_type
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error updating calendar event:", error);
        throw error;
    }
};

export const deleteEvent = async (eventId) => {
    try {
        await api.delete(`/calendar_events/${eventId}`);
    } catch (error) {
        console.error("Error deleting calendar event:", error);
        throw error;
    }
};

// İzinleri getir
export const fetchLeaveEvents = async (start, end) => {
    try {
        const response = await api.get('/calendar_events/leaves', {
            params: { start, end }
        });
        return response.data.map(event => ({
            ...event,
            classNames: 'leave-event'
        }));
    } catch (error) {
        console.error("Error fetching leave events:", error);
        throw error;
    }
};

// Vardiyaları getir
export const fetchShiftEvents = async (start, end) => {
    try {
        const response = await api.get('/calendar_events/shifts', {
            params: { start, end }
        });
        return response.data.map(event => ({
            ...event,
            classNames: 'shift-event'
        }));
    } catch (error) {
        console.error("Error fetching shift events:", error);
        throw error;
    }
};

// Tatil günlerini getir
export const fetchHolidayEvents = async (start, end) => {
    try {
        const response = await api.get('/calendar_events/holidays', {
            params: { start, end }
        });
        return response.data.map(event => ({
            ...event,
            classNames: 'holiday-event'
        }));
    } catch (error) {
        console.error("Error fetching holiday events:", error);
        throw error;
    }
};