// src/components/QuickActions/QuickActions.jsx
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './QuickActions.module.css';
import { useUserPreferences } from '../../../hooks/useUserPreferences';

const QuickActions = () => {
    const { preferences, loading, error, updatePreferences } = useUserPreferences();
    const [activeApps, setActiveApps] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const defaultApplications = [
        { id: 'dashboard', icon: '🏠', name: 'Dashboard', link: '/dashboard', position: 1 },
        { id: 'myLeaves', icon: '✈️', name: 'İzinlerim', link: '/my-leaves', position: 2 },
        { id: 'timesheet', icon: '⏱️', name: 'Puantaj', link: '/timesheet', position: 3 },
        { id: 'myShifts', icon: '🗓️', name: 'Vardiyalarım', link: '/my-shifts', position: 4 },
        { id: 'myExpenses', icon: '💰', name: 'Harcamalarım', link: '/my-expenses', position: 5 },
        { id: 'documents', icon: '📄', name: 'Dökümanlar', link: '/documents', position: 6 },
        { id: 'approvals', icon: '👍', name: 'Onaylar', link: '/approvals', position: 7 }
    ];

    const allApplications = [
        ...defaultApplications,
        { id: 'timesheet', icon: '⏱️', name: 'Puantaj', link: '/timesheet' },
        { id: 'assetManagement', icon: '🔧', name: 'Varlık Yönetimi', link: '/asset-management' },
        { id: 'liveMonitoring', icon: '👀', name: 'Canlı İzleme', link: '/live-monitoring' },
        { id: 'ethicsLine', icon: '🚨', name: 'Etik Hat', link: '/ethics-line' },
        { id: 'disciplineManagement', icon: '⚖️', name: 'Disiplin Yönetimi', link: '/discipline-management' },
        { id: 'careerOpportunities', icon: '🎯', name: 'Kariyer Fırsatları', link: '/career-opportunities' },
        { id: 'rotation', icon: '🔄', name: 'Rotasyon', link: '/rotation' },
        { id: 'dormitory', icon: '🏠', name: 'Yurt', link: '/dormitory' },
        { id: 'gymManagement', icon: '💪', name: 'Spor Salonu Yönetimi', link: '/gym-management' },
        { id: 'socialMedia', icon: '📱', name: 'Sosyal Medya', link: '/social-media' },
        { id: 'menuOfTheDay', icon: '🍽️', name: 'Günün Menüsü', link: '/menu-of-the-day' },
        { id: 'announcements', icon: '📢', name: 'Duyurular', link: '/announcements' },
        { id: 'auditManagement', icon: '🔍', name: 'Denetim Yönetimi', link: '/audit-management' },
        { id: 'vibeCheck', icon: '😊', name: 'Ruh Hali Kontrolü', link: '/vibe-check' },
        { id: 'todoList', icon: '✅', name: 'Yapılacaklar Listesi', link: '/todo-list' },
        { id: 'targetAssignment', icon: '🎯', name: 'Hedef Atama', link: '/target-assignment' },
        { id: 'opportunities', icon: '🎁', name: 'Fırsatlar', link: '/opportunities' },
        { id: 'qrManagement', icon: '📷', name: 'QR Yönetimi', link: '/qr-management' },
        { id: 'surveys', icon: '📊', name: 'Anketler', link: '/surveys' },
        { id: 'feedbacks', icon: '💬', name: 'Geri Bildirimler', link: '/feedbacks' },
        { id: 'gameManagement', icon: '🎮', name: 'Oyun Yönetimi', link: '/game-management' },
        { id: 'allStaffStatus', icon: '📊', name: 'Tüm Personel Durumu', link: '/all-staff-status' },
        { id: 'payrollBalance', icon: '💼', name: 'Denkleştirme', link: '/payroll-balance' },
        { id: 'inAndOutMovements', icon: '🚶', name: 'Giriş Çıkış Hareketleri', link: '/in-and-out-movements' },
        { id: 'absentees', icon: '🏖️', name: 'Devamsızlar', link: '/absentees' },
        { id: 'incalculables', icon: '❓', name: 'Hesaplanamayanlar', link: '/incalculables' },
        { id: 'shiftIncompatibility', icon: '⚠️', name: 'Zaman Uyumsuzluğu', link: '/shift-incompatibility' },
        { id: 'holidayIncompatibility', icon: '🏖️', name: 'İzin Uyumsuzluğu', link: '/holiday-incompatibility' },
        { id: 'earlyArrivals', icon: '🏃', name: 'Erken Gelenler', link: '/early-arrivals' },
        { id: 'lateArrivals', icon: '🐢', name: 'Geç Gelenler', link: '/late-arrivals' },
        { id: 'earlyLeavers', icon: '🚶', name: 'Erken Ayrılanlar', link: '/early-leavers' },
        { id: 'lateLeavers', icon: '🦥', name: 'Geç Ayrılanlar', link: '/late-leavers' },
        { id: 'overtime', icon: '⏰', name: 'Fazla Mesailer', link: '/overtime' },
        { id: 'leaveReports', icon: '📅', name: 'İzin Raporları', link: '/leave-reports' },
        { id: 'totalTransitions', icon: '🔄', name: 'Toplam Geçişler', link: '/total-transitions' },
        { id: 'shiftStatus', icon: '📊', name: 'Vardiya Durumu', link: '/shift-status' },
        { id: 'payrollSheet', icon: '💰', name: 'Puantaj Cetveli', link: '/payroll-sheet' },
        { id: 'monthlyPayrolls', icon: '📅', name: 'Aylık Puantaj', link: '/monthly-payrolls' },
        { id: 'dailyPayrolls', icon: '📆', name: 'Günlük Puantaj', link: '/daily-payrolls' },
        { id: 'entrances', icon: '🚪', name: 'Girişler', link: '/entrances' },
        { id: 'quickShiftPlanning', icon: '⚡', name: 'Hızlı Vardiya Planlama', link: '/quick-shift-planning' },
        { id: 'webshifts', icon: '🗓️', name: 'Vardiya Planlama', link: '/webshifts' },
        { id: 'shifts', icon: '🔄', name: 'Vardiyalar', link: '/shifts' },
        { id: 'leaves', icon: '🌴', name: 'İzinler', link: '/leaves' }
    ];


    useEffect(() => {
        if (preferences && preferences.my_applications) {
            setActiveApps(preferences.my_applications);
        } else {
            setActiveApps(allApplications.slice(0, 7));
        }
    }, [preferences]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newApps = Array.from(activeApps);
        const [reorderedItem] = newApps.splice(result.source.index, 1);
        newApps.splice(result.destination.index, 0, reorderedItem);

        setActiveApps(newApps);
    };

    const removeApp = (appId) => {
        setActiveApps(activeApps.filter(app => app.id !== appId));
    };

    const addApp = (app) => {
        if (activeApps.length < 7 && !activeApps.find(a => a.id === app.id)) {
            setActiveApps([...activeApps, app]);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleEditMode = () => {
        if (editMode) {
            updatePreferences({ ...preferences, my_applications: activeApps });
        }
        setEditMode(!editMode);
        setIsMenuOpen(false);
    };

    const renderApp = (app, index) => {
        if (editMode) {
            return (
                <Draggable key={app.id} draggableId={app.id} index={index}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${styles.quickActionsAppLink} ${snapshot.isDragging ? styles.quickActionsDragging : ''}`}
                        >
                            <div className={styles.quickActionsAppItem}>
                                <div className={styles.quickActionsAppIcon}>{app.icon}</div>
                                <div className={styles.quickActionsAppName}>{app.name}</div>
                                <button onClick={() => removeApp(app.id)} className={styles.quickActionsRemoveBtn}>X</button>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        } else {
            return (
                <a href={app.link} key={app.id} className={styles.quickActionsAppLink}>
                    <div className={styles.quickActionsAppItem}>
                        <div className={styles.quickActionsAppIcon}>{app.icon}</div>
                        <div className={styles.quickActionsAppName}>{app.name}</div>
                    </div>
                </a>
            );
        }
    };

    if (loading) return <div className={styles.quickActionsLoading}>Loading...</div>;
    if (error) return <div className={styles.quickActionsError}>Error: {error}</div>;

    return (
        <div className={styles.quickActionsContainer}>
            <div className={styles.quickActionsHeader}>
                <h2 className={styles.quickActionsTitle}>Uygulamalarım</h2>
                {editMode ? (
                    <button onClick={toggleEditMode} className={styles.quickActionsEditButton}>
                        Kaydet
                    </button>
                ) : (
                    <div className={styles.quickActionsMenuContainer}>
                        <button onClick={toggleMenu} className={styles.quickActionsMenuButton}>
                            •••
                        </button>
                        {isMenuOpen && (
                            <div className={styles.quickActionsMenu}>
                                <button onClick={toggleEditMode} className={styles.quickActionsMenuItem}>
                                    Düzenle
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="apps" direction="horizontal">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.quickActionsAppGrid}>
                            {activeApps.map((app, index) => renderApp(app, index))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {editMode && (
                <div className={styles.quickActionsAppSelector}>
                    <h3>Uygulama listesi</h3>
                    <div className={styles.quickActionsAppList}>
                        {allApplications.map(app => (
                            <button
                                key={app.id}
                                onClick={() => addApp(app)}
                                disabled={activeApps.length >= 7 || activeApps.find(a => a.id === app.id)}
                                className={styles.quickActionsAddBtn}
                            >
                                {app.name}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuickActions;