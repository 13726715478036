// ScoreBoard.jsx
import React, { useEffect } from 'react';
import { useScoreData } from '../../../hooks/useScoreData';

const ScoreBoard = ({ userId, gameId, companyId, score, time }) => {
    const { highScore, fetchHighScore } = useScoreData();

    useEffect(() => {
        fetchHighScore(userId, gameId, companyId);
    }, [userId, gameId, companyId]);

    return (
        <div>
            <h2>Current Score: {score}</h2>
            <h3>Time: {time} seconds</h3>
            <h3>High Score: {highScore || 'N/A'}</h3>
        </div>
    );
};

export default ScoreBoard;