import api from './api';

export const fetchWeatherData = async (location) => {
    const cachedData = localStorage.getItem(`weatherData_${location}`);
    const cacheExpiry = localStorage.getItem(`weatherDataExpiry_${location}`);

    // Cache varsa ve süresi dolmamışsa cache'teki veriyi döndür
    if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
        return JSON.parse(cachedData);
    }

    try {
        const response = await api.get('/weather', {
            params: {
                location,
                aqi: 'yes'
            }
        });

        const data = response.data;

        // Veriyi localStorage'a kaydet, cache süresini 1 saat olarak belirle
        localStorage.setItem(`weatherData_${location}`, JSON.stringify(data));
        localStorage.setItem(`weatherDataExpiry_${location}`, new Date().getTime() + 60 * 60 * 1000); // 1 saat

        return data;
    } catch (error) {
        console.error("Error fetching weather data:", error);
        throw error;
    }
};
