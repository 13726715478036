// hooks/useWeatherData.js
import { useState, useEffect, useRef } from 'react';
import { fetchWeatherData } from '../services/weatherService';
import { isSignificantChange } from '../utilities/isSignificantChange';

const DEFAULT_LOCATION = { latitude: 41.0068099, longitude: 29.0343543 };  // İstanbul'un enlem ve boylamı
const DEBOUNCE_DELAY = 500;  // 500 ms debounce süresi

export const useWeatherData = () => {
    const [weatherData, setWeatherData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [location, setLocation] = useState(DEFAULT_LOCATION); // Varsayılan konum İstanbul
    const prevLocation = useRef(DEFAULT_LOCATION);  // Önceki konum bilgisi
    const debounceTimeout = useRef(null);

    // Kullanıcının konum iznini al
    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;

                        // Konum önemli ölçüde değiştiyse API çağrısı yap
                        if (isSignificantChange(prevLocation.current, { latitude, longitude })) {
                            setLocation({ latitude, longitude });
                            prevLocation.current = { latitude, longitude };  // Yeni konumu kaydet
                        }
                    },
                    (error) => {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                console.error("Kullanıcı konum iznini reddetti.");
                                setError("Kullanıcı konum iznini reddetti.");
                                break;
                            case error.POSITION_UNAVAILABLE:
                                console.error("Konum bilgisi kullanılamıyor.");
                                setError("Konum bilgisi kullanılamıyor.");
                                break;
                            case error.TIMEOUT:
                                console.error("Konum isteği zaman aşımına uğradı.");
                                setError("Konum isteği zaman aşımına uğradı.");
                                break;
                            default:
                                console.error("Bilinmeyen bir hata oluştu.");
                                setError("Bilinmeyen bir hata oluştu.");
                                break;
                        }
                    },
                    {
                        enableHighAccuracy: true,  // Daha hassas konum bilgisi alınmaya çalışılır
                        timeout: 10000,            // 10 saniye içinde yanıt beklenir
                        maximumAge: 0              // Cache'teki konum bilgisi kullanılmaz
                    }
                );
            } else {
                console.error('Geolocation tarayıcı tarafından desteklenmiyor.');
                setError('Tarayıcı konum servisini desteklemiyor.');
            }
        };

        getLocation();
    }, []);

    // Hava durumu verisini yükle
    useEffect(() => {
        const loadWeatherData = () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }

            debounceTimeout.current = setTimeout(async () => {
                try {
                    setIsLoading(true);
                    const data = await fetchWeatherData(`${location.latitude},${location.longitude}`);
                    setWeatherData(data);
                    setError(null);
                } catch (err) {
                    console.error('Error loading weather data:', err);
                    setError('Failed to load weather data');
                    setWeatherData(null);
                } finally {
                    setIsLoading(false);
                }
            }, DEBOUNCE_DELAY);
        };

        loadWeatherData();
    }, [location]);

    return { weatherData, isLoading, error };
};
