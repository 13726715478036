import React, { useState } from 'react';
import { Combobox } from "@headlessui/react";

const BranchSelector = ({ branches, selectedBranch, onBranchChange, isLoading, error }) => {
    const [query, setQuery] = useState("");

    if (isLoading) return <div>Şubeler yükleniyor...</div>;
    if (error) return <div>Hata: {error}</div>;

    const filteredBranches = query === ""
        ? branches
        : branches.filter((branch) =>
            branch.title.toLowerCase().includes(query.toLowerCase())
        );

    return (

        <div className='toolbar'>
            <div className="branch-selector">
                <Combobox value={selectedBranch} onChange={onBranchChange}>
                    <div className="relative">
                        <Combobox.Input
                            className="select-selected"
                            displayValue={(branchId) => branches.find(b => b.id === branchId)?.title || ''}
                            onChange={(event) => setQuery(event.target.value)}
                            placeholder="Şube Seçin"
                        />
                        <Combobox.Options className="select-items">
                            {filteredBranches.length === 0 && query !== "" ? (
                                <div className="select-option">Şube bulunamadı</div>
                            ) : (
                                filteredBranches.map((branch) => (
                                    <Combobox.Option
                                        key={branch.id}
                                        value={branch.id}
                                        className={({active, selected}) =>
                                            `select-option ${active ? 'bg-blue-500 text-white' : 'text-gray-900'}
                                        ${selected ? 'font-medium' : 'font-normal'}`
                                        }
                                    >
                                        {branch.title}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </div>
                </Combobox>
            </div>
        </div>
            );
            };

            export default BranchSelector;