// KnowledgeBaseList.jsx
import React from 'react';
import Skeleton from '../common/Skeleton/Skeleton';

import { useKnowledgeBases } from '../../hooks/useKnowledgeBase';

const KnowledgeBaseList = ({ onSelect, selectedId }) => {
    const { knowledgeBases, loading, error } = useKnowledgeBases();

    if (loading) {
        return (
            <div className="knowledge-base-grid">
                {[...Array(6)].map((_, i) => (
                    <div key={i} className="knowledge-base-card">
                        <div className="kb-content">
                            <Skeleton width="60%" height="24px" />
                            <Skeleton width="100%" height="40px" />
                            <Skeleton width="30%" height="20px" />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    if (error) return <div className="error">Error: {error}</div>;

    return (
        <div className="knowledge-base-grid">
            {knowledgeBases.map(kb => (
                <div
                    key={kb.id}
                    className={`knowledge-base-card ${selectedId === kb.id ? 'selected' : ''}`}
                    onClick={() => onSelect(kb)}
                >

                    <div className="kb-content">
                        <h4>{kb.name}</h4>
                        <p>{kb.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default KnowledgeBaseList;