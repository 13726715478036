import React, { useState, useCallback, useMemo } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import LazyLoad from 'react-lazyload';
import { useOrganizationData } from '../../hooks/useOrganizationData';
import debounce from '../../utilities/debounce';
import styles from './OrganizationChart.module.css';

// Çalışan kartı bileşeni - React.memo ile gereksiz render'ları önlüyoruz
const TreeNode = React.memo(({ employee }) => (
    <div className={styles.orgChartNode}>
        <div className={styles.orgChartCard}>
            <div className={styles.orgChartAvatarWrap}>
                <LazyLoad height={60} once>
                    <img
                        src={employee.avatar_url}
                        alt={employee.full_name}
                        className={styles.orgChartAvatar}
                        loading="lazy" // Native lazy loading
                    />
                </LazyLoad>
                {employee.badge && (
                    <span className={styles.orgChartBadge}>{employee.badge}</span>
                )}
            </div>
            <div className={styles.orgChartUserInfo}>
                <h3 className={styles.orgChartUserName}>{employee.full_name}</h3>
                <span className={styles.orgChartUserTitle}>
                    {employee.title || 'No Title'}
                </span>
                <span className={styles.orgChartUserDept}>
                    {employee.department || 'No Department'}
                </span>
                <span className={styles.orgChartUserBranch}>
                    {employee.branch || 'No Branch'}
                </span>
            </div>
        </div>
        {employee.children && employee.children.length > 0 && (
            <ul className={styles.orgChartChildList}>
                {employee.children.map(child => (
                    <li key={child.id} className={styles.orgChartChildItem}>
                        <TreeNode employee={child} />
                    </li>
                ))}
            </ul>
        )}
    </div>
));

// Kontroller bileşeni - React.memo ile gereksiz render'ları önlüyoruz
const Controls = React.memo(({ zoomIn, zoomOut, resetTransform }) => (
    <div className={styles.orgChartZoomControls}>
        <button className={styles.orgChartZoomBtn} onClick={() => zoomIn()}>
            <i className="fas fa-plus"></i>
        </button>
        <button className={styles.orgChartZoomBtn} onClick={() => zoomOut()}>
            <i className="fas fa-minus"></i>
        </button>
        <button className={styles.orgChartZoomBtn} onClick={() => resetTransform()}>
            <i className="fas fa-redo"></i>
        </button>
    </div>
));

const OrganizationChart = () => {
    const [filters, setFilters] = useState({
        'q[first_name_cont]': '',
        'q[last_name_cont]': '',
        'q[job_position_id_eq]': '',
        'q[department_id_eq]': '',
        'q[branch_id_eq]': ''
    });
    const [showFullOrg, setShowFullOrg] = useState(false);
    const { organizationData, filtersData, loading, error } = useOrganizationData(filters, showFullOrg);

    // Debounced filtre değişikliği
    const debouncedSetFilter = useCallback(
        debounce((key, value) => {
            setShowFullOrg(false);
            setFilters(prev => ({ ...prev, [key]: value }));
        }, 300),
        []
    );



    const clearFilter = useCallback((key) => {
        setShowFullOrg(false);
        setFilters(prev => ({ ...prev, [key]: '' }));
    }, []);

    const handleShowFullOrg = useCallback(() => {
        setShowFullOrg(true);
        setFilters({
            'q[first_name_cont]': '',
            'q[last_name_cont]': '',
            'q[job_position_id_eq]': '',
            'q[department_id_eq]': '',
            'q[branch_id_eq]': ''
        });
    }, []);

    const clearAllFilters = useCallback(() => {
        setShowFullOrg(false);
        setFilters({
            'q[first_name_cont]': '',
            'q[last_name_cont]': '',
            'q[job_position_id_eq]': '',
            'q[department_id_eq]': '',
            'q[branch_id_eq]': ''
        });
    }, []);

    if (loading && !filtersData.job_positions?.length) {
        return <div>Loading Filters...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className={styles.orgChartContainer}>
            {/* Filtreler */}
            <div className={styles.orgChartSearch}>
                <div className={styles.orgChartFilterGroup}>
                    <button
                        onClick={handleShowFullOrg}
                        className={`${styles.orgChartFullOrgBtn} ${showFullOrg ? styles.active : ''}`}
                        disabled={loading}
                    >
                        Show Full Organization
                    </button>
                </div>
                <div className={styles.orgChartFilterGroup}>
                    <input
                        type="text"
                        placeholder="First Name"
                        className={styles.orgChartInput}
                        value={filters['q[first_name_cont]']}
                        onChange={(e) => debouncedSetFilter('q[first_name_cont]', e.target.value)}
                        disabled={showFullOrg}
                    />
                    {filters['q[first_name_cont]'] && (
                        <button
                            onClick={() => clearFilter('q[first_name_cont]')}
                            className={styles.orgChartClearBtn}
                            title="Clear filter"
                        >×</button>
                    )}
                </div>

                <div className={styles.orgChartFilterGroup}>
                    <input
                        type="text"
                        placeholder="Last Name"
                        className={styles.orgChartInput}
                        value={filters['q[last_name_cont]']}
                        onChange={(e) => debouncedSetFilter('q[last_name_cont]', e.target.value)}
                        disabled={showFullOrg}
                    />
                    {filters['q[last_name_cont]'] && (
                        <button
                            onClick={() => clearFilter('q[last_name_cont]')}
                            className={styles.orgChartClearBtn}
                            title="Clear filter"
                        >×</button>
                    )}
                </div>

                <div className={styles.orgChartFilterGroup}>
                    <select
                        className={styles.orgChartSelect}
                        value={filters['q[job_position_id_eq]']}
                        onChange={(e) => debouncedSetFilter('q[job_position_id_eq]', e.target.value)}
                        disabled={showFullOrg}
                    >
                        <option value="">All Job Positions</option>
                        {filtersData.job_positions?.map(([name, id]) => (
                            <option key={id} value={id}>{name}</option>
                        ))}
                    </select>
                    {filters['q[job_position_id_eq]'] && (
                        <button
                            onClick={() => clearFilter('q[job_position_id_eq]')}
                            className={styles.orgChartClearBtn}
                            title="Clear filter"
                        >×</button>
                    )}
                </div>

                <div className={styles.orgChartFilterGroup}>
                    <select
                        className={styles.orgChartSelect}
                        value={filters['q[department_id_eq]']}
                        onChange={(e) => debouncedSetFilter('q[department_id_eq]', e.target.value)}
                        disabled={showFullOrg}
                    >
                        <option value="">All Departments</option>
                        {filtersData.departments?.map(([name, id]) => (
                            <option key={id} value={id}>{name}</option>
                        ))}
                    </select>
                    {filters['q[department_id_eq]'] && (
                        <button
                            onClick={() => clearFilter('q[department_id_eq]')}
                            className={styles.orgChartClearBtn}
                            title="Clear filter"
                        >×</button>
                    )}
                </div>

                <div className={styles.orgChartFilterGroup}>
                    <select
                        className={styles.orgChartSelect}
                        value={filters['q[branch_id_eq]']}
                        onChange={(e) => debouncedSetFilter('q[branch_id_eq]', e.target.value)}
                        disabled={showFullOrg}
                    >
                        <option value="">All Branches</option>
                        {filtersData.branches?.map(([title, id]) => (
                            <option key={id} value={id}>{title}</option>
                        ))}
                    </select>
                    {filters['q[branch_id_eq]'] && (
                        <button
                            onClick={() => clearFilter('q[branch_id_eq]')}
                            className={styles.orgChartClearBtn}
                            title="Clear filter"
                        >×</button>
                    )}
                </div>

                {(Object.values(filters).some(value => value !== '') || showFullOrg) && (
                    <button
                        onClick={clearAllFilters}
                        className={styles.orgChartClearAllBtn}
                    >
                        Clear All
                    </button>
                )}
            </div>

            <div className={styles.orgChartViewport}>
                {!showFullOrg && Object.values(filters).every(value => value === '') ? (
                    <div className={styles.orgChartMessage}>
                        Please apply filters or click "Show Full Organization" to see the organization chart.
                    </div>
                ) : loading ? (
                    <div className={styles.orgChartLoading}>Loading organization data...</div>
                ) : organizationData && organizationData.length > 0 ? (
                    <TransformWrapper
                        initialScale={1}
                        minScale={0.01}
                        maxScale={2}
                        centerOnInit={true}
                        wheel={{ wheelEnabled: true, step: 0.05 }}
                        pinch={{ pinchEnabled: true, step: 5 }}
                        doubleClick={{ disabled: false, step: 0.7 }}
                        limitToBounds={false}
                        alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                        zoomAnimation={{
                            animationType: "easeOut",
                            animationTime: 200
                        }}
                    >
                        {(utils) => (
                            <React.Fragment>
                                <Controls {...utils} />
                                <TransformComponent
                                    wrapperStyle={{
                                        width: "100%",
                                        height: "100%",
                                        overflow: "hidden"
                                    }}
                                >
                                    <div className={styles.orgChartContent}>
                                        <ul className={styles.orgChartRootList}>
                                            {organizationData.map(rootEmployee => (
                                                <li key={rootEmployee.id} className={styles.orgChartRootItem}>
                                                    <TreeNode employee={rootEmployee} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                ) : (
                    <div className={styles.orgChartNoData}>
                        No results found for the applied filters.
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(OrganizationChart);