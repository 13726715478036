// src/services/userPreferencesService.js
import api from './api';

export const getUserPreferences = async () => {
    try {
        const response = await api.get('/user_preferences');
        return response.data;
    } catch (error) {
        console.error('Error fetching user preferences:', error);
        throw error;
    }
};

export const updateUserPreferences = async (preferences) => {
    try {
        const response = await api.patch('/user_preferences', { user_preference: { preferences } });
        return response.data;
    } catch (error) {
        console.error('Error updating user preferences:', error);
        throw error;
    }
};