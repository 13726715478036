// useScoreData.js
import { useState } from 'react';
import { saveScore, getHighScore } from '../services/scoreService';

export const useScoreData = () => {
    const [score, setScore] = useState(0);
    const [highScore, setHighScore] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const incrementScore = (value = 1) => {
        setScore(prevScore => prevScore + value);
    };

    const resetScore = () => {
        setScore(0);
    };

    const saveCurrentScore = async (userId, gameId, companyId) => {
        setLoading(true);
        try {
            await saveScore({ score, userId, gameId, companyId });
            setLoading(false);
        } catch (err) {
            setError('Failed to save the score.');
            setLoading(false);
        }
    };

    const fetchHighScore = async (userId, gameId, companyId) => {
        setLoading(true);
        try {
            const response = await getHighScore(userId, gameId, companyId);
            setHighScore(response.high_score);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch high score.');
            setLoading(false);
        }
    };

    return {
        score,
        highScore,
        loading,
        error,
        incrementScore,
        resetScore,
        saveCurrentScore,
        fetchHighScore
    };
};