import { useCallback } from 'react';

const createMessage = (type, data) => {
    const baseMessage = {
        id: `${type}-${Date.now()}`,
        created_at: new Date().toISOString(),
        role: 'assistant',
    };

    switch (type) {
        case 'submenu':
            return {
                ...baseMessage,
                content: data.message,
                metadata: {
                    type: 'submenu',
                    suggested_actions: data.actions
                }
            };

        case 'error':
            return {
                ...baseMessage,
                content: data.message,
                is_error: true,
                metadata: {
                    type: 'error',
                    error_code: data.code
                }
            };

        default:
            return {
                ...baseMessage,
                content: data,
            };
    }
};

export const useMessageActions = (onSendMessage) => {
    const handleAction = useCallback((actionData) => {
        try {
            if (!actionData?.type) {
                throw new Error('Invalid action data: type is required');
            }

            switch (actionData.type) {
                case 'SHOW_SUBMENU':
                    if (!actionData.message || !Array.isArray(actionData.actions)) {
                        throw new Error('Invalid submenu data: message and actions are required');
                    }
                    const submenuMessage = createMessage('submenu', {
                        message: actionData.message,
                        actions: actionData.actions
                    });
                    onSendMessage(submenuMessage);
                    break;

                case 'SEND_MESSAGE':
                    if (!actionData.content || typeof actionData.content !== 'string') {
                        throw new Error('Invalid message content');
                    }
                    onSendMessage({
                        type: 'user_message',
                        content: actionData.content.trim()
                    });
                    break;

                case 'RETRY_LAST':
                    onSendMessage({
                        type: 'retry',
                        messageId: actionData.messageId
                    });
                    break;

                case 'SHOW_ERROR':
                    const errorMessage = createMessage('error', {
                        message: actionData.message || 'Bir hata oluştu',
                        code: actionData.code || 'UNKNOWN_ERROR'
                    });
                    onSendMessage(errorMessage);
                    break;

                default:
                    if (actionData.customHandler && typeof actionData.customHandler === 'function') {
                        actionData.customHandler();
                    } else {
                        console.warn('Unhandled action type:', actionData.type);
                    }
            }
        } catch (error) {
            console.error('Action handling error:', error);
            const errorMessage = createMessage('error', {
                message: 'İşlem gerçekleştirilemedi: ' + error.message,
                code: 'ACTION_ERROR'
            });
            onSendMessage(errorMessage);
        }
    }, [onSendMessage]);

    return { handleAction };
};