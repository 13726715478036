// app/javascript/components/Calendar/EventModal.jsx

import React, { useState, useEffect } from 'react';
import './EventModal.css';

const EventModal = ({ isOpen, onClose, event, selectedDate, onAdd, onUpdate, onDelete }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        start: '',
        end: '',
        allDay: false,
        color: '#FD7014'
    });

    useEffect(() => {
        if (event) {
            // Mevcut event düzenleme durumu
            setFormData({
                title: event.title,
                description: event.extendedProps?.description || '',
                start: event.startStr,
                end: event.endStr,
                allDay: event.allDay,
                color: event.backgroundColor
            });
        } else if (selectedDate) {
            // Yeni event oluşturma durumu - seçilen tarih bilgilerini kullan
            const startDate = new Date(selectedDate.start);
            const endDate = new Date(selectedDate.end);

            // Tarihleri YYYY-MM-DDThh:mm formatına dönüştür (datetime-local input için)
            const formatDate = (date) => {
                return date.toISOString().slice(0, 16);
            };

            setFormData({
                ...formData,
                start: formatDate(startDate),
                end: formatDate(endDate),
                allDay: selectedDate.allDay
            });
        }
    }, [event, selectedDate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (event) {
            onUpdate({ ...formData, id: event.id });
        } else {
            onAdd(formData);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{event ? 'Edit Event' : 'New Event'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Title</label>
                        <input
                            type="text"
                            value={formData.title}
                            onChange={(e) => setFormData({...formData, title: e.target.value})}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            value={formData.description}
                            onChange={(e) => setFormData({...formData, description: e.target.value})}
                        />
                    </div>

                    <div className="form-group">
                        <label>Start</label>
                        <input
                            type="datetime-local"
                            value={formData.start}
                            onChange={(e) => setFormData({...formData, start: e.target.value})}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>End</label>
                        <input
                            type="datetime-local"
                            value={formData.end}
                            onChange={(e) => setFormData({...formData, end: e.target.value})}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={formData.allDay}
                                onChange={(e) => setFormData({...formData, allDay: e.target.checked})}
                            />
                            All Day
                        </label>
                    </div>

                    <div className="form-group">
                        <label>Color</label>
                        <input
                            type="color"
                            value={formData.color}
                            onChange={(e) => setFormData({...formData, color: e.target.value})}
                        />
                    </div>

                    <div className="button-group">
                        <button type="submit" className="save-button">
                            {event ? 'Update' : 'Create'}
                        </button>
                        {event && (
                            <button
                                type="button"
                                className="delete-button"
                                onClick={() => onDelete(event.id)}
                            >
                                Delete
                            </button>
                        )}
                        <button type="button" className="cancel-button" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EventModal;