import api from './api';
export const fetchCompanyLeaveTypes = async (page = 1, perPage = 25) => {
    const response = await api.get('/shift_planning/company_leave_types', {
        params: { page, per_page: perPage }
    });
    return response.data;
};

export const createLeaves = async (leaves) => {
    const response = await api.post('/shift_planning/create_leaves', { leaves });
    console.log("Leave params", leaves)
    console.log("leaves data:", response.data);
    return response.data;
};

export const fetchLeaves = async (userId, leaveTypeId, finYear) => {
    try {
        const response = await api.get(`/users/${userId}/leaves`, {
            params: { leave_type_id: leaveTypeId, fin_year: finYear }
        });
        console.log("API response:", response.data); // Bu satırı ekleyin
        return response.data.leaves || []; // 'leaves' anahtarını kullanın
    } catch (error) {
        console.error("Error fetching leaves:", error);
        throw error;
    }
};

export const fetchLeaveSummary = async (userId, leaveTypeId, finYear) => {
    try {
        const response = await api.get(`/users/${userId}/leave_summary`, {
            params: { leave_type_id: leaveTypeId, fin_year: finYear }
        });
        console.log("Leave summary API response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching leave summary:", error);
        throw error;
    }
};

export const fetchLeaveTypes = async (isSummaryTab = false) => {
    try {
        const response = await api.get('/users/leave_types', {
            params: { is_summary_tab: isSummaryTab.toString() }
        });
        return response.data.leave_types;
    } catch (error) {
        console.error("Error fetching leave types:", error);
        throw error;
    }
};

// leaveService.js
export const fetchLeaveOverlaps = async (branchId, start, end, viewMode) => {
    try {
        const response = await api.get('/leaves/leave_overlaps', {
            params: { branch_id: branchId, start_date: start, end_date: end, view_mode: viewMode }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching leave overlaps:", error);
        throw error;
    }
};