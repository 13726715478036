import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './CommentModal.module.css';

const CommentModal = ({ isOpen, onClose, post, comments, onAddComment }) => {
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newComment.trim()) {
            onAddComment(post.id, newComment);
            setNewComment('');
        }
    };

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={onClose}>&times;</button>
                <div className={styles.modalInner}>
                    <div className={styles.imageSection}>
                        {post.image_urls && post.image_urls.length > 0 && (
                            <Slider {...sliderSettings}>
                                {post.image_urls.map((url, index) => (
                                    <div key={index}>
                                        <img src={url} alt={`Post image ${index + 1}`} className={styles.postImage} />
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </div>
                    <div className={styles.commentsSection}>
                        <div className={styles.postInfo}>
                            <img src={post.user.avatar} alt={post.user.username} className={styles.avatar} />
                            <span className={styles.username}>{post.user.username}</span>
                        </div>
                        <div className={styles.postContent}>
                            <p>{post.content}</p>
                        </div>
                        <div className={styles.commentList}>
                            {comments && comments.length > 0 ? (
                                comments.map(comment => (
                                    <div key={comment.id} className={styles.comment}>
                                        <img src={comment.user.avatar} alt={comment.user.username} className={styles.avatar} />
                                        <div className={styles.commentContent}>
                                            <span className={styles.username}>{comment.user.username}</span>
                                            <p>{comment.content}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className={styles.noComments}>
                                    <p>No comments available</p>
                                </div>
                            )}
                        </div>
                        <form onSubmit={handleSubmit} className={styles.commentForm}>
                            <input
                                type="text"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Add a comment..."
                                className={styles.commentInput}
                            />
                            <button type="submit" className={styles.submitButton}>Post</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentModal;
