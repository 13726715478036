import api from './api';

export const fetchAnnouncements = async () => {
    const cachedData = localStorage.getItem('announcements');
    const cacheExpiry = localStorage.getItem('announcementsExpiry');

    // Eğer cache varsa ve cache süresi geçmemişse, cache'teki veriyi döndür
    if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
        return JSON.parse(cachedData);
    }

    try {
        const response = await api.get('/announcements/api_index');
        const data = response.data;

        // Veriyi localStorage'a kaydet, cache süresini 1 saat olarak belirle
        localStorage.setItem('announcements', JSON.stringify(data));
        localStorage.setItem('announcementsExpiry', new Date().getTime() + 60 * 60 * 1000); // 1 saat

        return data;
    } catch (error) {
        console.error("Error fetching announcements:", error);
        throw error;
    }
};
