import api from './api';

export const fetchInternalServices = async () => {
    try {
        // API request to fetch internal services
        const response = await api.get('/internal_services/api_internal_services');
        return response.data;
    } catch (error) {
        console.error("Internal servis verileri alınırken hata oluştu:", error);
        throw error;
    }
};
