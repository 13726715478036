import {Controller} from "stimulus"
import Pusher from 'pusher-js';
import {createElement} from "dropzone";

export default class extends Controller {
    connect() {
        const APP_KEY = document.head.querySelector("meta[name=pusher_app_id]").content;
        const APP_CLUSTER = document.head.querySelector("meta[name=pusher_cluster]").content;
        const COMPANY_ID = document.head.querySelector("meta[name=company_id]").content;

        const pusher = new Pusher(APP_KEY, {
            cluster: APP_CLUSTER,
        });
        const channel = pusher.subscribe(`entrances-${COMPANY_ID}`);

        const getTypeBadge = function (entrance_type) {
            const div = document.createElement('div');
            div.classList.add('badge');
            div.classList.add(entrance_type == 'check_in' ? 'badge-success' : 'badge-danger');
            div.innerHTML = entrance_type == 'check_in' ? 'Giriş' : 'Çıkış';

            return div;
        }

        const getDeviceId = function () {
            const device_ids = document.getElementById('pusher_device_id');
            return device_ids ? device_ids.value.split('|').filter((value) => value.trim() !== "") : [];
        }

        // const getBranchId = function () {
        //   const branch_ids = document.getElementById('pusher_branch_id');
        //   return branch_ids ? branch_ids.value.split('|').filter((value) => value.trim() !== "") : [];
        // }

        const getZoneId = function () {
            const zone_ids = document.getElementById('pusher_zone_id');
            return zone_ids ? zone_ids.value.split('|').filter((value) => value.trim() !== "") : [];
        }

        const getEntranceType = function () {
            const entrance_type = document.getElementById('pusher_entrance_type').value;
            if (!entrance_type) return null;

            return entrance_type == "1" ? "check_out" : "check_in";
        }

        channel.bind('new-entrance', function (data) {
            if (!getDeviceId().includes(data.device_id)) return;
            // if (data.branch_id) {
            //   if (getBranchId().length > 0 && !getBranchId().includes(data.branch_id)) return;
            // }
            if (getZoneId().length > 0 && !getZoneId().some(element => data.zone_ids.includes(element))) return;
            if (getEntranceType() && getEntranceType() != data.entrance_type) return;

            const table = document.getElementById('entrances');

            // Do not show if exists
            if (table.querySelectorAll('tr[data-entrance-id="' + data.id + '"]').length > 0) return;

            if (table.querySelectorAll('tr').length > 10) {
                table.deleteRow(-1);
            }
            if (document.querySelector('#entrances tr#loading')) {
                document.querySelector('#entrances tr#loading').style.display = "none";
            }

            const avatar = data.avatar ? `<img src="${data.avatar}" width="50" />` : '<i class="fas fa-user" style="font-size: 50px;"></i>';

            const row = table.insertRow(1);
            row.dataset.entranceId = data.id;
            const cell1 = row.insertCell(0);
            const cell2 = row.insertCell(1);
            const cell3 = row.insertCell(2);
            const cell4 = row.insertCell(3);
            const cell5 = row.insertCell(4);
            cell1.innerHTML = avatar;
            cell2.innerHTML = data.user_name;
            cell3.innerHTML = data.device_name;
            cell4.innerHTML = getTypeBadge(data.entrance_type).outerHTML;
            cell5.innerHTML = data.created_at;
            row.addEventListener("animationend", e => {
                row.className = "";
            });

            row.className = "flash";
        });
    }
}
