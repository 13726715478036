import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "addLink", "template", "container" ]
    static values = { defaultNfc26bit: Boolean }

    connect() {
        console.log("CardFields controller connected")
    }

    addCard(event) {
        event.preventDefault()
        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.containerTarget.insertAdjacentHTML('beforeend', content)

        // Yeni eklenen checkbox'ı bul ve varsayılan değeri ayarla
        const newCard = this.containerTarget.lastElementChild
        const checkbox = newCard.querySelector('.nfc-26bit-checkbox')
        if (checkbox) {
            checkbox.checked = this.defaultNfc26bitValue
        }
    }
}