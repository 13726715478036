// services/organizationService.js
import api from './api';

export const fetchOrganizationData = async (filters, showFullOrg = false) => {
    try {
        const params = showFullOrg ? { show_full_org: true } : filters;
        const response = await api.get('/organization_chart/org_chart_data', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching organization data:', error);
        throw error;
    }
};

export const fetchOrganizationFilters = async () => {
    try {
        const response = await api.get('/organization_chart/organization_filters');
        return response.data;
    } catch (error) {
        console.error('Error fetching organization filters:', error);
        throw error;
    }
};
