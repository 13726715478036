import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSocialFeedData } from '../../../hooks/useSocialFeedData';
import { likePost, unlikePost, fetchComments, addComment } from '../../../services/socialFeedService';
import styles from './SocialFeed.module.css';
import Skeleton from '../../common/Skeleton/Skeleton';
import CommentModal from './CommentModal';

const SocialFeed = () => {
    const [page, setPage] = useState(1);
    const { socialPosts, setSocialPosts, loading, error, hasMore } = useSocialFeedData(page);
    const [newPostText, setNewPostText] = useState('');
    const [expandedPosts, setExpandedPosts] = useState({});
    const [activeCommentPostId, setActiveCommentPostId] = useState(null);
    const [comments, setComments] = useState([]);
    const observer = useRef();
    const lastPostElementRef = useRef();

    useEffect(() => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (lastPostElementRef.current) {
            observer.current.observe(lastPostElementRef.current);
        }
    }, [loading, hasMore]);

    const handlePostSubmit = (e) => {
        e.preventDefault();
        console.log("New post:", newPostText);
        setNewPostText('');
    };

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true
    };

    const renderSkeleton = () => (
        <div className={styles.postItem}>
            <div className={styles.postHeader}>
                <Skeleton width={40} height={40} circle />
                <Skeleton width={100} height={20} />
            </div>
            <Skeleton width="100%" height={20} />
            <Skeleton width="100%" height={200} />
            <div className={styles.postStats}>
                <Skeleton width={50} height={16} />
                <Skeleton width={50} height={16} />
                <Skeleton width={50} height={16} />
            </div>
        </div>
    );

    const truncateContent = (content, maxLength = 100) => {
        if (content.length <= maxLength) return content;
        return content.slice(0, maxLength) + '...';
    };

    const toggleExpandPost = (postId) => {
        setExpandedPosts(prev => ({
            ...prev,
            [postId]: !prev[postId]
        }));
    };

    const handleLike = async (postId) => {
        try {
            const response = await likePost(postId);
            setSocialPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === postId
                        ? { ...post, likes_count: response.likes_count, liked_by_current_user: true }
                        : post
                )
            );
        } catch (error) {
            console.error("Error liking post:", error);
        }
    };

    const handleUnlike = async (postId) => {
        try {
            const response = await unlikePost(postId);
            setSocialPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === postId
                        ? { ...post, likes_count: response.likes_count, liked_by_current_user: false }
                        : post
                )
            );
        } catch (error) {
            console.error("Error unliking post:", error);
        }
    };

    const handleCommentClick = async (postId) => {
        setActiveCommentPostId(postId);
        try {
            const fetchedComments = await fetchComments(postId);
            setComments(fetchedComments);
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    };

    const handleAddComment = async (postId, content) => {
        try {
            const newComment = await addComment(postId, content);
            setComments(prevComments => [...prevComments, newComment]);
            setSocialPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === postId
                        ? { ...post, comments_count: post.comments_count + 1 }
                        : post
                )
            );
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };

    return (
        <div className={styles.socialFeedCard}>
            <h2 className={styles.title}>PG Sosyal</h2>
            <div className={styles.header}>
                <input type="text" placeholder="Search" className={styles.searchInput} />
            </div>
            <div className={styles.postsContainer}>
                <div className={styles.posts}>
                    {loading && [...Array(3)].map((_, index) => (
                        <div key={index}>{renderSkeleton()}</div>
                    ))}
                    {socialPosts.map((post, index) => (
                        <div
                            key={post.id}
                            className={styles.postItem}
                            ref={socialPosts.length === index + 1 ? lastPostElementRef : null}
                        >
                            <div className={styles.postHeader}>
                                {post.user && post.user.avatar && (
                                    <img src={post.user.avatar} alt={post.user.username || 'User'}
                                         className={styles.authorAvatar}/>
                                )}
                                <p className={styles.authorName}>{post.user?.username}</p>
                            </div>
                            <p className={styles.postContent}>
                                {expandedPosts[post.id] ? post.content : truncateContent(post.content)}
                                {post.content.length > 100 && (
                                    <span
                                        className={styles.moreLink}
                                        onClick={() => toggleExpandPost(post.id)}
                                    >
                                    {expandedPosts[post.id] ? 'daha az' : 'daha fazla'}
                                </span>
                                )}
                            </p>
                            {post.image_urls && post.image_urls.length > 0 && (
                                <div className={styles.mediaGallery}>
                                    <Slider {...sliderSettings}>
                                        {post.image_urls.map((url, index) => (
                                            <div key={index}>
                                                <img src={url} alt={`post visual ${index + 1}`}
                                                     className={styles.postImage}/>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            )}
                            <div className={styles.postStats}>
                                <span>
                                    {post.liked_by_current_user ? (
                                        <i
                                            className="fas fa-thumbs-up"
                                            style={{ color: '#FD7014', cursor: 'pointer' }}
                                            onClick={() => handleUnlike(post.id)}
                                        ></i>
                                    ) : (
                                        <i
                                            className="far fa-thumbs-up"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleLike(post.id)}
                                        ></i>
                                    )}
                                    {' '}{post.likes_count}
                                </span>
                                <span onClick={() => handleCommentClick(post.id)}>
                                    <i className="fas fa-comment"></i> {post.comments_count || 0}
                                </span>
                            </div>
                        </div>
                    ))}
                    {loading && <div>Loading...</div>}
                    {error && <div>Error: {error}</div>}
                </div>
            </div>
            <CommentModal
                isOpen={activeCommentPostId !== null}
                onClose={() => setActiveCommentPostId(null)}
                post={socialPosts.find(post => post.id === activeCommentPostId)}
                comments={comments}
                onAddComment={handleAddComment}
            />
        </div>
    );
};

export default SocialFeed;