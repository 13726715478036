// app/javascript/services/chatService.js
import api from './api';

export const fetchConversations = async () => {
    try {
        const response = await api.get('/chat_conversations');
        return response.data.conversations;
    } catch (error) {
        const errorMessage = error.response?.data?.error || 'Sohbetler yüklenirken bir hata oluştu';
        throw new Error(errorMessage);
    }
};

export const fetchMessages = async (conversationId, page = 1) => {
    try {
        const response = await api.get(`/chat_conversations/${conversationId}/messages`, {
            params: { page }
        });
        return {
            messages: response.data.messages,
            meta: response.data.meta
        };
    } catch (error) {
        const errorMessage = error.response?.data?.error || 'Mesajlar yüklenirken bir hata oluştu';
        throw new Error(errorMessage);
    }
};

export const sendMessage = async (conversationId, content) => {
    try {
        const response = await api.post(`/chat_conversations/${conversationId}/chat_messages`, {
            message: { content }
        });

        return {
            message: response.data.message,
            conversation: response.data.conversation,
            meta: response.data.meta
        };
    } catch (error) {
        if (error.response?.status === 404) {
            throw new Error('Bu sohbete erişim yetkiniz yok veya sohbet bulunamadı');
        }
        throw new Error(error.response?.data?.error || 'Mesaj gönderilemedi');
    }
};
// chatService.js
export const createConversation = async () => {
    try {
        const response = await api.post('/chat_conversations');
        console.log('Create conversation response:', response.data); // Debug için
        if (!response.data.conversation) {
            throw new Error('Invalid response format');
        }
        return response.data.conversation;
    } catch (error) {
        console.error('Create conversation error:', error);
        const errorMessage = error.response?.data?.error || 'Yeni sohbet oluşturulamadı';
        throw new Error(errorMessage);
    }
};