import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';  // Stil dosyasını unutmayın

const localizer = momentLocalizer(moment);

const OverlapCalendar = ({ overlapData, viewMode, currentDate, onDateChange, views }) => {
    const events = overlapData.flatMap(employee =>
        employee.leaves.map(leave => ({
            title: `${employee.user_name} - ${leave.leave_type}`,
            start: new Date(leave.start_date),
            end: new Date(leave.end_date),
            resource: employee.user_id,
        }))
    );

    return (
        <div className="overlap-calendar-container">
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                views={views}
                view={viewMode}
                onView={(newView) => onDateChange(currentDate, newView)}
                date={currentDate}
                onNavigate={(date) => onDateChange(date)}
                messages={{
                    week: 'Hafta',
                    day: 'Gün',
                    month: 'Ay',
                    previous: 'Geri',
                    next: 'İleri',
                    today: 'Bugün',
                }}
            />
        </div>
    );
};

export default OverlapCalendar;
