// KnowledgeBase.jsx
import React, { useState } from 'react';
import { KnowledgeBaseSearch } from './KnowledgeBaseSearch';
import KnowledgeBaseList from './KnowledgeBaseList';
import { KnowledgeBaseTopics } from './KnowledgeBaseTopics';
import KnowledgeBaseCategoryView from './KnowledgeBaseCategoryView';
import './KnowledgeBase.css';

const KnowledgeBase = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null);

    const handleBack = () => {
        setSelectedKnowledgeBase(null);
    };

    return (
        <div className="knowledge-base-container">
            <div className="knowledge-base-hero">
                <h1>Ne hakkında bilgi almak istiyorsunuz?</h1>
                <div className="search-wrapper">
                    <KnowledgeBaseSearch
                        value={searchQuery}
                        onChange={setSearchQuery}
                    />
                </div>
            </div>

            <div className="knowledge-base-content">
                {selectedKnowledgeBase ? (
                    <>
                        <div className="knowledge-base-header">
                            <button className="back-button" onClick={handleBack}>
                                Main
                            </button> >
                            <h2>{selectedKnowledgeBase.name}</h2>
                        </div>
                        <KnowledgeBaseCategoryView
                            knowledgeBase={selectedKnowledgeBase}
                        />
                    </>
                ) : (
                    <>
                        <section className="help-types-section">
                            <h2>İhtiyacınıza Uygun Yardımı Bulun</h2>
                            <KnowledgeBaseList
                                onSelect={setSelectedKnowledgeBase}
                                selectedId={selectedKnowledgeBase?.id}
                            />
                        </section>

                        <section className="all-topics-section">
                            <h2>Bilgi Merkezi Konuları</h2>
                            <KnowledgeBaseTopics />
                        </section>
                    </>
                )}
            </div>
        </div>
    );
};

export default KnowledgeBase;