// app/javascript/controllers/log_detail_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["content"]
    static values = {
        url: String,
        loaded: Boolean
    }

    connect() {
        if (this.hasContentTarget && !this.loadedValue) {
            this.loadContent()
        }
    }

    loadContent() {
        if (this.loadedValue) return

        fetch(this.urlValue, {
            headers: {
                'Accept': 'text/html',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => response.text())
            .then(html => {
                this.contentTarget.innerHTML = html
                this.loadedValue = true
            })
            .catch(error => {
                console.error("Error loading log detail:", error)
                this.contentTarget.innerHTML = `
        <div class="alert alert-danger">
          Detaylar yüklenirken bir hata oluştu. Lütfen sayfayı yenileyip tekrar deneyin.
        </div>
      `
            })
    }

    toggle(event) {
        if (!this.loadedValue) {
            this.loadContent()
        }
    }
}