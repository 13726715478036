// KnowledgeBaseCategoryView.jsx
import React from 'react';

// KnowledgeBaseCategoryView.jsx

const KnowledgeBaseCategoryView = ({ knowledgeBase }) => {
    if (!knowledgeBase || !knowledgeBase.knowledge_categories) {
        return null;
    }

    return (
        <div className="category-view">
            <h3 className="browse-title">Browse by categories</h3>

            <div className="kb-categories-container">
                {knowledgeBase.knowledge_categories.map(category => {
                    const articles = category.knowledge_articles || [];
                    const totalArticles = category.total_articles_count || articles.length;
                    const displayedArticles = articles.slice(0, 5); // Display the first 5 articles
                    const hasMoreArticles = articles.length > 5;

                    return (
                        <div key={category.id} className="kb-category-card">
                            <div className="kb-category-header">
                                <h3>{category.name}</h3>
                                <span className="kb-article-count">
                                    {totalArticles} articles
                                </span>
                            </div>

                            <div className="kb-article-list">
                                {displayedArticles.map(article => (
                                    <a
                                        key={article.id}
                                        href={`/knowledge_bases/${knowledgeBase.id}/knowledge_categories/${category.id}/knowledge_articles/${article.id}/show_react`}
                                        className="kb-article-link"
                                        onClick={() => {
                                            sessionStorage.setItem('breadcrumb', JSON.stringify({
                                                knowledgeBaseName: knowledgeBase.name,
                                                categoryName: category.name,
                                                path: [knowledgeBase.name, category.name]
                                            }));
                                        }}
                                    >
                                        <span>{article.title}</span>
                                        <i className="fas fa-chevron-right"></i>
                                    </a>
                                ))}
                            </div>

                            {hasMoreArticles && (
                                <a
                                    href={`/knowledge_bases/${knowledgeBase.id}/knowledge_categories/${category.id}`}
                                    className="kb-show-all"
                                >
                                    Show all {totalArticles} articles
                                    <i className="fas fa-arrow-right"></i>
                                </a>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default KnowledgeBaseCategoryView;
