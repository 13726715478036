import React from 'react';

const WaldoPosition = ({ waldoPosition }) => {
    return (
        <img
            src="/assets/transparent_waldo.png"
            alt="Waldo"
            style={{
                position: 'absolute',
                top: waldoPosition.y,
                left: waldoPosition.x,
                width: waldoPosition.width,
                height: waldoPosition.height
            }}
        />
    );
};

export default WaldoPosition;
