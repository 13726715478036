import { useState, useEffect } from 'react';
import { fetchAnnouncements } from '../services/announcementService';

export const useAnnouncements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadAnnouncements = async () => {
            try {
                setIsLoading(true);
                const data = await fetchAnnouncements();
                setAnnouncements(data);
                setError(null);
            } catch (err) {
                console.error('Error loading announcements:', err);
                setError('Failed to load announcements. Please try again later.');
                setAnnouncements([]);
            } finally {
                setIsLoading(false);
            }
        };

        loadAnnouncements();
    }, []);

    return { announcements, isLoading, error };
};