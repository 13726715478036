import { useState, useEffect } from 'react';
import { fetchNewEmployees } from '../services/newEmployeeService';

export const useNewEmployeesData = () => {
    const [newEmployees, setNewEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadNewEmployeesData = async () => {
            try {
                setLoading(true);
                const data = await fetchNewEmployees();
                setNewEmployees(data);
                setError(null);
            } catch (err) {
                setError('Yeni çalışan verileri yüklenirken bir hata oluştu');
                setNewEmployees([]);
            } finally {
                setLoading(false);
            }
        };

        loadNewEmployeesData();
    }, []);

    return { newEmployees, loading, error };
};
